const schema = {
    additionalProperties: {
        type: 'object',
    }
}
function areDefined(arr) {
    for (let item of arr)
        if (item === undefined)
            return false
    return true
}
function areNumbers(arr) {
    for (let item of arr)
        if (!Number.isFinite(item))
            return false
    return true
}

function ticks(spec) {
    console.log('building ticks from', spec)
    let { from, to, step, n, mid } = spec
    if (areDefined([from, to, n]) && !areDefined([step])) {
        step = (to - from) / (n - 1)
    }
    if (areNumbers([from, to, step])) {
        let result = [], current = from
        while (current <= to) {
            result.push(current)
            current += step
        }
        return result
    } else {
        throw `unable to create ticks from ${JSON.stringify(spec)}`
    }
}

function getIndices(index, dimensions) {
    let indices = []
    for (let dim = 0; dim < dimensions.length; dim++) {
        const dimension = dimensions[dim]
        const n = dimension.ticks.length
        const currentIndex = index % n
        indices.push(currentIndex)
        index = Math.floor(index / n)
    }
    return indices
}
const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
function getSampleAtIndex(index, dimensions) {
    const indices = getIndices(index, dimensions)
    let sample = {}
    for (let dim = 0; dim < indices.length; dim++) {
        const { name, ticks, alpha } = dimensions[dim]
        const i = indices[dim]
        sample[name] = ticks[i]
        if (alpha)
            sample[alpha] = ALPHABET.charAt(i % ALPHABET.length)
    }
    return sample
}


export default (spec, dataset) => {

    const { grid } = spec
    const dimensions = Object.keys(grid).map((name) => ({
        ...grid[name],
        name, ticks: ticks(grid[name])
    }))


    let s = dimensions.reduce((prev, curr) => prev * curr.ticks.length, 1)

    return {
        async getChunkAt(offset, size) {
            let samples = []
            for (let index = offset; index < offset + size && index < s; index++) {
                const sample = getSampleAtIndex(index, dimensions)
                samples.push(sample)
            }
            return { samples: () => samples, offset: offset, size: samples.length, nrows: samples.length }
        },
        getSize: () => s

    }
}