import { COORDINATE_SYSTEM } from "deck.gl"

export function getCoordinates(config) {
    const { gpsOrigin } = config
    const { latitude, longitude, altitude } = gpsOrigin
    const origin = [longitude, latitude, altitude]
    return {
        coordinateSystem: COORDINATE_SYSTEM.METER_OFFSETS,
        coordinateOrigin: origin,
    }
}