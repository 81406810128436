import { render, html } from "lit";
import Descartes from "..";
import { mulMV } from "../utils/algebra/mat";
import renderHoveredState from "./hovered-state";

function getPixelCoordinates(pixel, viewport, targetZ = 10) {
  const commonPosition = viewport.projectPosition(viewport.unproject(pixel, { targetZ }))
  for (let i = 0; i < 3; i++) {
    commonPosition[i] = commonPosition[i] - viewport.center[i]
    commonPosition[i] *= viewport.distanceScales.metersPerUnit[i]
  }
  return commonPosition
}

export function renderViewDetails(context, descartes: Descartes) {

  const camera = descartes.getViewport()
  const { hover, viewState, cameras, currentCamera } = context
  const { projectionMatrix, viewMatrix } = camera

  const pixel = (hover || {}).pixelCoordinates || [0, 0]
  return html`<h2>View</h2>
<section>
  <h3>Camera</h3>
  <pre>
  ${JSON.stringify(cameras.find(c => c.id === currentCamera), null, 2)}
  </pre>
</section>
<section>
  <h3>State</h3>
  <pre>
  ${JSON.stringify(viewState, null, 2)}
  </pre>
</section>

${hover ? html` <section>
  <h3>Hovered</h3>
  ${renderHoveredState(hover, descartes)}
  <pre>
${camera.unproject(pixel).join("\n")}

${getPixelCoordinates(pixel, camera).join("\n")}

${mulMV(viewMatrix, [...camera.projectPosition(camera.unproject(hover.pixelCoordinates)), 1]).join("\n")}
</pre>
</section>` : ''}

<section>
  <h3>Debug</h3>
  <pre>
M x V = 
${mulMV(viewMatrix, [0, 0, 0, 1]).join("\n")}

${JSON.stringify(camera, null, 2)}</pre>
</section>
`



}