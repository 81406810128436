import './style.css'
import { Action } from '../manipulator/action';
import { render, html } from 'lit'


const MENU_OFFSET = 20;




function renderTitle(ctx) {
    const { title, path } = ctx
    if (title)
        return html`<pre class="menu-title">${Array.isArray(title) ? title.join("\n") : title}</pre>`
}
function renderContext(ctx, update) {
    const { path } = ctx
    function handleClick(part) {
        const idx = path.indexOf(part)
        const newPath = path.slice(0, idx)
        update({
            ...ctx,
            path: newPath
        })
    }
    return html`<div class="menu-context">${path.map((p) => html`<span class="clickable" @click=${() => handleClick(p)} ><span> >
        </span><span>${p}</span></span>`)} </div>`
}

function renderActions(ctx, update, close = () => true) {
    const { title,
        actions,
        data,
        path } = ctx
    let currentActions = actions
    for (let p of path) {
        const actionGroup = currentActions.filter(a => a.name === p)[0]
        if (actionGroup && actionGroup.group)
            currentActions = actionGroup.group || []
        else
            throw `unable to find action group ${p}`
    }

    return html`<div class="action-list">${currentActions.map(action => {
        let cls = "", handleClick = (event) => null
        if (action.perform) {
            handleClick = (event) => {
                const p = action.perform(data,event)

                if (p && p.then)
                    p.then((result) => {
                        console.log('action succeeded ', result || '')
                    }).catch(err => {
                        console.log('action failed')
                        console.log(err.toString())
                    });
                else {
                    console.log('action succeeded synchronously', p || '')
                }
                close()

            }
        } else if (action.group) {
            cls += 'group '
            handleClick = (event) => {
                const newContext = {
                    ...ctx,
                    // event,
                    path: [...ctx.path, action.name]
                }
                update(newContext)
            }
        }
        return html`<div class="${cls}" @click="${handleClick}">${action.text}</div>`
    })}</div>`
}




function renderContextMenu(ctx, menu) {
    const { event } = ctx


    // if (title) {
    //     let titleElt = document.createElement('pre')
    //     titleElt.innerHTML = 
    //     titleElt.classList.add('menu-title')
    //     menu.appendChild(titleElt)
    // }


    // let actionList = document.createElement('div')
    // actionList.classList.add('action-list')
    // for (let action of actions) {
    //     addAction(actionList, action, actionData)
    // }
    // menu.appendChild(actionList)
    function update(newContext) {
        const { clientWidth, clientHeight } = menu
        // set width,height to enable transition : NOT WORKING
        menu.style['min-width'] = '' + clientWidth + 'px'
        menu.style['min-height'] = '' + clientHeight + 'px'
        requestAnimationFrame(() => {
            renderContextMenu(newContext, menu)
        })
    }
    menu.style.width = ''
    menu.style.height = ''
    const close = () => {
        console.log('closing menu');
        menu.remove()
    }
    render(html`
    ${renderContext(ctx, update)}
    ${renderTitle(ctx)}
    ${renderActions(ctx, update, close)}
    `, menu)
}

function groupActions(actions) {
    let output = [...actions].filter(a => !(a.path || []).length)
    for (let action of actions.filter(a => (a.path || []).length)) {
        let current = output
        for (let part of action.path) {
            let parent = current.filter(a => a.name === part)[0]
            if (!parent) {
                parent = {
                    name: part,
                    text: part,
                    group: []
                }
                current.push(parent)
            }
            if (!parent.group)
                parent.group = []
            current = parent.group
        }
        current.push(action)
    }
    console.log('grouped actions', output)
    return output
}


export default function createContextMenu(event, data, actions, title = "") {
    actions = groupActions(actions)

    // TODO : validate data : uniq name per level 
    const ctx = {
        title,
        actions,
        event,
        data,
        path: []
    }

    const parent = document.body
    let menu = document.createElement('div')
    menu.classList.add('context-menu')


    let h = window.innerHeight;
    let w = window.innerWidth;
    let y = event.clientY
    let x = event.clientX
    if (x > w / 2) {
        menu.style.right = (w - event.clientX) - MENU_OFFSET + 'px';
    } else {
        menu.style.left = event.clientX - MENU_OFFSET + 'px';
    }
    if (y > h / 2) {
        menu.style.bottom = (h - event.clientY) - MENU_OFFSET + 'px';
    } else {
        menu.style.top = event.clientY - MENU_OFFSET + 'px';
    }


    renderContextMenu(ctx, menu)
    parent.appendChild(menu);
    let willClose = null
    menu.addEventListener('mouseleave', () => {
        willClose = setTimeout(() => menu.parentNode.removeChild(menu), 300)

    })
    for (let e of [
        'mouseover',
        'mouseenter',
        'mouseleave',
        'mouseout',
        'mousemove'
    ])
        menu.addEventListener(e, () => {
            if (['mouseover', 'mouseenter', 'mousemove'].indexOf(e) >= 0 && willClose) {
                clearTimeout(willClose)
            }
            // console.log("mouse event contextmenu", e)
        })
}

export function closeMenus() {

}





