import { DataChunk, mergeChunks } from "../chunk"
import { getChunkFromUrl, getSizeFromUrl } from "./url"


async function getUrlsSize(spec) {
    const urlsInfo = await getUrlsInfos.bind(this)(spec)
    const last = urlsInfo[urlsInfo.length - 1]
    return last.offset + last.size
}
export default function urls(spec, dataset) {
    const { urls } = spec
    // const baseTables = urls.map(u => url({ ...spec, url: u }, dataset))

    return {
        async getChunkAt(offset, size): Promise<DataChunk> {
            let n = 0
            const urlsInfo = await getUrlsInfos.bind(this)(spec)
            const dataSize = await getUrlsSize.bind(this)(spec)
            if (offset >= dataSize)
                throw `unable to get chunk from urls at offset ${offset} : size is only ${dataSize}`
            if (size <= 0)
                size = dataSize - offset
            size = Math.min(size, dataSize - offset)
            let chunks: DataChunk[] = []
            for (let current of urlsInfo.filter(c => !c.error)) {
                if (n >= size || current.offset < offset)
                    continue
                const localOffset = Math.max(current.offset, offset)
                const localSize = Math.min(size - n, current.size)
                if (localSize <= 0)
                    continue
                const chunk = await getChunkFromUrl.bind(current)({ ...spec, url: current.url }, localOffset, localSize)
                chunks.push(chunk)
                n += chunk.size
            }
            return {
                offset,
                ...mergeChunks(chunks)
            }
        },
        getSize: () => getUrlsSize(spec),
    }

}


export async function getUrlsInfos(spec) {
    if (!this.urlsInfos) {
        const { urls } = spec
        const perUrl = []
        let offset = 0
        for (let partUrl of urls) {
            let size = 0, error
            let state = {}
            try {
                size = await getSizeFromUrl.bind(state)({ ...spec, url: partUrl })
            } catch (err) {
                error = err
                size = 0
            }
            perUrl.push({
                url: partUrl,
                size,
                offset,
                error,
                state
            })
            offset += size
        }
        this.urlsInfos = perUrl
        for (let { error, url } of this.urlsInfos) {
            if (error) {
                console.error(`failed to handle url ${url} : ${error.toString()}`)
                console.error(error)
            }
        }
    }

    return this.urlsInfos
}
