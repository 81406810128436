import {
    AmbientLight,
    PointLight,
    DirectionalLight,
    LightingEffect,
} from "@deck.gl/core";
import { get } from "object-path-immutable";
import Descartes from "../..";
import CarteManager from "../../carte-manager";
import { geo2Pos, pos2Geo } from "../geography";

// create ambient light source
const ambientLight = new AmbientLight({
    color: [255, 255, 255],
    intensity: 2.0,
});
// create point light source
const pointLight = new PointLight({
    color: [255, 200, 150],
    intensity: 2.0,
    // use coordinate system as the same as view state
    position: [30, 30, 30],
});
// create directional light source
const directionalLight = new DirectionalLight({
    color: [255, 255, 255],
    intensity: 1.0,
    direction: [-3, -9, -10],
});
// create lighting effect with light sources
export const lightingEffect = new LightingEffect({
    ambientLight,
    pointLight,
    directionalLight,
});

export async function getLookAtLatLon(cms: CarteManager[], ctx: any) {
    const { lookAt } = ctx
    if (!lookAt || !lookAt.length) return null;
    let latitudes = [],
        longitudes = [],
        altitudes = [];
    let target;
    for (let cm of cms) {
        if (cm.getObject) {
            for (let id of lookAt) {
                const item = await cm.getObject(id, ctx);
                if (!item)
                    continue;
                const pos: [any, any, any] = item.position;
                const { gpsOrigin } = cm.config || {};
                const geo = pos2Geo(pos, gpsOrigin)
                const [longitude, latitude, altitude] = geo;
                target = { longitude, latitude, altitude };
            }
        }
    }
    return target;
}

export function fitViewToData(view, viewState, points, width, height) {
    let valid = false
    let { zoom } = viewState
    zoom = Math.ceil(zoom)
    while (!valid && zoom > 0 && zoom < 20) {
        valid = true
        const vp = view.makeViewport({ ...view.getDimensions({ width, height }), viewState: { ...viewState, zoom } })
        for (let point of points) {
            let pixelInFrustum = true
            const pix = vp.project(point)
            if (pix[0] < 0 || pix[0] > width)
                pixelInFrustum = false
            if (pix[1] < 0 || pix[1] > height)
                pixelInFrustum = false
            if (!pixelInFrustum) {
                valid = false
            }
        }
        if (!valid) {
            zoom -= 0.1
        }
    }
    return { ...viewState, zoom }
}

// WARNING : invalid functino : x,y,z,latitude,ongitude not in the object
export function fillCoordinates(input, descartes: Descartes) {
    const n = v => Number.isFinite(v)
    const vp = descartes.getViewport()
    let { latitude, longitude, altitude, x, y, z, px, py } = input
    const origin = get(descartes.config, ['defaults', 'gpsOrigin']);

    if (!n(longitude) && n(x) && origin) {
        const geo = pos2Geo([x, y, z || 0], origin);
        longitude = geo[0]
        latitude = geo[1]
        if (n(geo[2]))
            altitude = geo[2]
    }

    if (n(px) && !n(longitude)) {
        const gps = vp.unproject([px, py], { targetZ: altitude || z || 0 })
        longitude = gps[0]
        latitude = gps[1]
        if (n(gps[2]))
            altitude = gps[2]
    }
    if (n(longitude) && !n(x) && origin) {
        const pos = geo2Pos([longitude, latitude, altitude], origin);
        x = pos[0]
        y = pos[1]
        z = pos[2]
    }

    if (n(longitude) && !n(px)) {
        const pixels = vp.project([longitude, latitude], { targetZ: altitude || z || 0 })
        px = pixels[0]
        py = pixels[1]
    }
    const output = {
        latitude, longitude, altitude, x, y, z, px, py
    }
    return output
}

export function strCoordinates(input) {
    const { latitude, longitude, altitude, x, y, z, px, py } = input
    const n = v => Number.isFinite(v)
    const lines = []
    if (n(latitude)) {
        let label = "lat,lon"
        let value = `${latitude.toFixed(5)}°,${longitude.toFixed(5)}°`
        if (n(altitude)) {
            label += ",alt"
            value += `,${altitude.toFixed(3)}m`
        }
        lines.push([label, value])
    }
    if (n(x)) {
        let label = "x,y"
        let value = `${x.toFixed(3)}m,${longitude.toFixed(3)}m`
        if (n(altitude)) {
            label += ",z"
            value += `,${z.toFixed(3)}m`
        }
        lines.push([label, value])
    }
    if (n(px)) {
        let label = "pixel"
        let value = `(${px.toFixed(0)},${py.toFixed(0)})`
        lines.push([label, value])
    }

    return lines.map(([label, value]) => `${label.padEnd(15)} ${value.padStart(30)}`).join("\n")
}
