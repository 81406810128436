import { C5M_PLUGIN_URL, loadPlugin } from "../plugins";
import { DataChunk, DEFAULT_CHUNK_SIZE, getChunkSamples } from "./chunk";
import DataTable from "./table";
import createTableStreamer from '../extensions/table-streamer'

export default class DataSet {
    private tables: { [name: string]: DataTable } = {}
    private tableNames: string[] = []
    private extensions = {}

    ready: Promise<void>
    constructor(tableSpecs: any[], plugins = [
        // C5M_PLUGIN_URL
    ]) {
        if (tableSpecs)
            this.init(tableSpecs, plugins)
    }
    async init(tableSpecs: any[], plugins = []) {
        this.ready = this._init(tableSpecs, plugins)
    }
    async _init(tableSpecs: any[], plugins) {
        console.log('initializing dataset', tableSpecs, plugins)
        for (let spec of tableSpecs) {
            this.tables[spec.name] = new DataTable(spec, this.tables)
            this.tableNames.push(spec.name)
        }
        await Promise.all(plugins.map((url) => loadPlugin(url)))
    }
    async getTableNames(): Promise<string[]> {
        return this.tableNames
    }

    async getTableSize(name) {
        const table = await this.getTable(name)
        const size = await table.getSize()
        return size
    }
    async getTableSchema(name) {
        const table = await this.getTable(name)
        return table.getSchema()
    }
    async getTableChunk(name, offset, size) {
        const table = await this.getTable(name)
        const chunk = await table.getChunkAt(offset, size)
        const result = {
            ...chunk,
            samples: chunk.samples ? chunk.samples() : undefined,
            series: chunk.series ? chunk.series() : undefined,
        }
        return result
    }
    async getTableStats(name, property) {
        const table = await this.getTable(name)
        return table.getStats(property)
    }
    async getSamples(tableName) {
        const table = await this.getTable(tableName)
        if (!table)
            throw `no table ${tableName} in dataset`
        return table.getSamples()
    }
    async previewSamples(tableName, chunkSize = DEFAULT_CHUNK_SIZE) {
        const table = await this.getTable(tableName)
        if (!table)
            throw `no table ${tableName} in dataset`
        const size = await table.getSize()
        if (3 * chunkSize > size)
            return { ratio: 1.0, samples: await this.getSamples(tableName) }
        const chunk1 = await table.getChunkAt(0, chunkSize)
        const chunk2 = await table.getChunkAt(size - chunkSize, chunkSize)
        const samples1 = chunk1.samples()
        const samples2 = chunk2.samples()
        const allSamples = [...samples1, ...samples2]
        console.log(allSamples.slice(0, 10))

        return { ratio: 2 * chunkSize / size, samples: allSamples }
    }

    async extend(name, spec) {
        const PREFIX = `${name}:`
        if (spec) {
            const funs = await createTableStreamer(this, spec)
            for (let functionName in funs) {
                this.extensions[`${PREFIX}${functionName}`] = funs[functionName]
            }
        } else {
            const cleanName = `${PREFIX}clean`
            if (this.extensions[cleanName]) {
                console.log('calling clean')
            }
            for (let functionName in this.extensions) {
                if (functionName.startsWith(PREFIX))
                    delete this.extensions[functionName]
            }
        }

    }

    async compute(name, ...args) {
        const extension = this.extensions[name]
        if (!extension) {
            console.log('available extensions ', Object.keys(this.extensions))
            throw `no extension ${name}`
        }
        const ti = Date.now()
        const result = await extension(...args)
        const tf = Date.now()
        // console.log('compute took', (tf - ti) + 'ms : ', ...arguments)
        return result
    }

    // Warning : not working from web-worker
    async getTable(table) {
        await this.ready
        return this.tables[table]
    }
}