import { LineLayer } from 'deck.gl';
import { getBaseLayerProps, getObjectsLayers } from '../utils/layers/objects';
import Carte from './index'
import { updateScale } from '../scale-manager';
import { get } from 'object-path-immutable';
import { scale } from '../scale-manager';
import * as d3 from 'd3'

function getLinkLayers(edges, nodes, ctx, config) {
    const posPerSerial = {}
    for (let { serial, x, y, z } of nodes) {
        if (posPerSerial[serial])
            console.log(`duplicate node ${serial} ignored`)
        else {
            posPerSerial[serial] = [x / 1000, y / 1000, z / 1000]
        }
    }
    let colorScale, widthScale = (d) => 0.3
    const { edgeColor, edgeWidth } = config
    if (edgeColor) {
        colorScale = (sample) => {
            const value = get(sample, edgeColor.property)
            const col = scale(edgeColor.scale, value)
            if (!col)
                return sample.color
            const rgb = d3.rgb(col)
            return [rgb.r, rgb.g, rgb.b]
        }
    }
    if (edgeWidth) {

    }


    const filteredEdges = edges.filter(e => !ctx.selection.length || ctx.selection.includes(e.serial1.toString()))
    return new LineLayer({
        id: `object-links:${config.name}`,
        data: filteredEdges,
        ...getBaseLayerProps(ctx, config),
        // pickable: true,
        getColor: colorScale || [0, 255, 0], //d => d.color,
        getWidth: widthScale,// d => d.count/200, //2,
        widthUnits: 'meters',
        getOpacity: 0.5,
    })
}

export default class GraphCarte implements Carte {
    config: any;
    nodes: any = [];
    edges = []

    getLayers(ctx: any, lastCtx: any, lastLayers: any) {
        // should increas data with color if needed

        return [
            getObjectsLayers(this.nodes, ctx, this.config),
            getLinkLayers(this.edges, this.nodes, ctx, this.config)
        ]
    }
    getIds(ctx: any) {
        return this.nodes.map(n => n.serial.toString())
    }
    getObject(id: string, ctx: any) {
        return this.nodes.filter(n => (n.id || "").toString() === id)[0]
    }
    async init(dataset) {
        const { nodes, edges, edgeColor, nodeColor } = this.config

        // const edgesColorGetter = buildGetter(edgesColor || 1.0)

        const nodeData = await dataset.getSamples(nodes)
        console.log('node data', nodeData)
        const nodePerId = {}
        for (let n of nodeData)
            nodePerId[(n.id || "").toString()] = n
        if (nodeColor) {
            const { property, scale } = edgeColor
            updateScale(scale, nodeData.map(e => get(e, property)), [this.config.name, 'nodes'].join(':'))
        }

        this.nodes = nodeData
        if (edges) {
            const edgeData = await dataset.getSamples(edges)
                .then(edges => edges
                    .map(e => { // only works with static node
                        const from = nodePerId[e.fromId]
                        const to = nodePerId[e.toId]
                        if (from && to) {
                            e.sourcePosition = from.position
                            e.targetPosition = to.position
                        }
                        return e
                    })
                )
            if (edgeColor) {
                const { property, scale } = edgeColor
                updateScale(scale, edgeData.map(e => get(e, property)), [this.config.name, 'edges'].join(':'))
            }
            this.edges = edgeData

        }


    }
} 