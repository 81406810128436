import { loadPluginBaseTable } from "./dataset/base-tables";
import { loadPluginTransforms } from "./dataset/transforms";

let pluginPaths = {}
export function setPluginPaths(paths) {
    pluginPaths = paths
}

export const C5M_PLUGIN_URL = "http://localhost/c5m/c5m.mjs";

const plugins = {}

export async function loadPlugin(pluginUrl, name = ''): Promise<any> {
    pluginUrl = pluginPaths[pluginUrl] || pluginUrl
    if (!plugins[pluginUrl]) {
        console.log('loading plugin from', pluginUrl);
        plugins[pluginUrl] = eval("import(pluginUrl)").then((plugin) => {
            if (plugin.default)
                plugin = plugin.default
            loadPluginTransforms(plugin)
            loadPluginBaseTable(plugin)
            console.log('module loaded', plugin.name);
            return plugin
        });
    }

    return plugins[pluginUrl]
}


