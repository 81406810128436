import { html } from "lit";
import { get } from "object-path-immutable";
import notify from "web-ui-blocks/notifications";
import { askUser } from "web-ui-blocks/modal/intent"
import Descartes from "..";
import { decodeClickEvent } from "../controls/mouse";



export async function renderSelectionDetails(context, descartes: Descartes) {
    const { selection } = context


    let selectionSummary: any = ''
    let lastSelectedDetails: any = ''


    if (selection.length >= 2) {
        let summary = {}
        let ok = false
        // for (let prop of ['x', 'y', 'z']) {
        //     summary[prop] = selectedObjects.filter(Boolean).map(p => get(p,prop)).reduce((prev, value) => {
        //         if (!Number.isFinite(value))
        //             return prev
        //         if (!prev)
        //             return [value, value]
        //         return [Math.min(prev[0], value), Math.max(prev[1], value)]
        //     }, null)
        //     ok = true
        // }
        if (ok) {
            selectionSummary = html`<div class="column">
    ${Object.entries(summary).map(([prop, extent]) => html`<div>${prop} : ${extent[0].toFixed(2)} ...
        ${extent[1].toFixed(2)}</div>`)}
</div>`
        }
    }
    if (selection.length === 1) {
        const selectedObjects = await Promise.all(selection.map(async id => {
            let obj = null
            for (const cm of descartes.carteManagers) {
                const local = await cm.getObject(id, context, true)
                if (local)
                    obj = { ...(obj || {}), ...local }
            }
            return obj
        }))
        const object = selectedObjects[selection.length - 1]
        if (object) {
            lastSelectedDetails = html`<div>${object.name || object.id || object.guid}</div><pre style="flex:1;">${JSON.stringify(object, null, 2)}</pre>`

        }
    }
    return html`
    ${selectionSummary}
    ${lastSelectedDetails}
    `

}