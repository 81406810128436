// import { mustHaveProps } from "../util";
import { get } from 'object-path-immutable'
import { buildFunction } from '../../../util';
import * as dsp from 'dsp-dft'
import ofdm from "./ofdm"
import ofdm2 from "./ofdm2"
import ofdm3 from "./ofdm3"
import KNOWN_SEQUENCES from './sequences'
import { dot, norm, phase, rotate } from './complex';
const FFT = require('fft.js');
const mustHaveProps = (a, b) => true


function createComplex(real, imag) {
    return {
        real: real,
        imag: imag
    };
}

function dft(samples,) {
    var len = samples.length;
    var arr = Array(len);
    var pi2 = Math.PI * (-2);
    var invlen = 1 / len;
    for (var i = 0; i < len; i++) {
        arr[i] = createComplex(0, 0);
        for (var n = 0; n < len; n++) {
            var theta = pi2 * i * n * invlen;
            var costheta = Math.cos(theta);
            var sintheta = Math.sin(theta);
            arr[i].real += samples[n].real * costheta - samples[n].imag * sintheta;
            arr[i].imag += samples[n].real * sintheta + samples[n].imag * costheta;
        }
    }
    return arr;
}
export default [
    {
        name: 'dft',
        apply(samples, tr, datasets) {
            let { size } = tr
            if (!size) {
                size = samples.length
            }
            const s1 = samples[0]
            const s2 = samples[1]
            let dt = 0, df = 1
            if (s1 && s2 && Number.isFinite(s1.t) && Number.isFinite(s2.t)) {
                dt = s2.t - s1.t
                df = 1 / dt / samples.length
            }
            const signal = samples.map(s => ({ real: s.i || 0, imag: s.q || 0 }))
            let result = dft(signal).map(({ real, imag }) => ({ i: real, q: imag, n: Math.sqrt(real * real + imag * imag) }))
            if (dt)
                result = result.map((s, i) => {
                    if (i > result.length / 2)
                        i -= result.length
                    return { ...s, f: i * df }
                })

            return result
        },
        schema: {
            type: 'object',
            description: "compute discrete fourier transform using direct algorithm. Produces i,q,n fields and f if input samples are timestamped",
            properties: {
                size: { type: 'number' }
            }
        }
    },
    {
        name: 'fft',
        apply(samples, tr, datasets) {
            let { size } = tr
            size = Number(size)
            const ALLOWED_SIZES = [64, 128, 256, 512, 1024, 2048, 4096, 8192]
            if (!ALLOWED_SIZES.includes(size)) {
                throw `invalid FFT size : ${size} (${ALLOWED_SIZES})`
            }
            if (!this.samples) {
                this.samples = []
            }
            let result = []
            const is = new Float32Array(size)
            const qs = new Float32Array(size)
            const flush = () => {
                const n = this.samples.length
                if (n < size) {
                    throw `not enough samples for fft (${n}!=${size})`
                }
                const fftSamples = this.samples.slice(0, size)
                const s1 = fftSamples[0]
                const s2 = fftSamples[1]
                let dt = 0, df = 1, t = s1.t
                if (s1 && s2 && Number.isFinite(s1.t) && Number.isFinite(s2.t)) {
                    dt = s2.t - s1.t
                    df = 1 / dt / size
                }



                for (let idx = 0; idx < size; idx++) {
                    const { i, q } = fftSamples[idx]
                    is[idx] = i
                }
                console.log('computing fft')
                const f = new FFT(size)
                const input = f.toComplexArray(is)
                const output = f.createComplexArray()
                f.transform(output, input)


                let localResult = []
                for (let idx = 0; idx < size; idx++) {
                    const i = output[idx * 2]
                    const q = output[idx * 2 + 1]
                    const sample = { t, i, q, n: Math.sqrt(i * i + q * q) }
                    localResult.push(sample)
                }
                if (dt)
                    localResult = localResult.map((s, i) => {
                        if (i > localResult.length / 2)
                            i -= localResult.length
                        return { ...s, f: i * df }
                    })
                result = [...result, ...localResult]
                this.samples = this.samples.slice(size)
            }
            this.samples = [...this.samples, ...samples]
            while (this.samples.length > size) {
                flush()
            }
            return result
        },
        schema: {
            type: 'object',
            description: "compute discrete fourier transform using FFT algorithm (pure js implementation). Produces i,q,n fields and f if input samples are timestamped",
            properties: {
                size: { type: 'number' }
            }
        }
    },
    {
        name: 'autocorrelation',
        apply(samples, tr, datasets) {
            let { size } = tr
            size = Number(size)
            if (!this.samples) {
                this.samples = []
            }
            let result = []
            const flush = () => {
                {
                    const n = this.samples.length
                    if (n < size) {
                        throw `not enough samples for autocorrelation (${n}!=${size})`
                    }
                }


                // let accu_i = 0, accu_q = 0, accu_t = 0, accu = 0
                // for (let idx = 0; idx < size; idx++) {
                //     const s1 = this.samples[idx]
                //     const s2 = this.samples[size + idx]
                //     accu_i += s2.i * s1.i + s2.q * s1.q
                //     accu_q += -s1.i * s2.q + s1.q * s2.i
                //     accu_t += s1.t / size
                //     accu += accu_i * accu_i + accu_q * accu_q
                // }
                // const sample = {
                //     t: accu_t,
                //     n: accu / size
                // }

                const s1 = this.samples[0]
                const s2 = this.samples[size]
                const i = s2.i * s1.i + s2.q * s1.q
                const q = -s1.i * s2.q + s1.q * s2.i
                const n = (i * i + q * q) / (s1.i * s1.i + s1.q * s1.q)
                const sample = {
                    t: s1.t,
                    n,
                    db: 10 * Math.log(n)
                }
                result.push(sample)
                this.samples.shift()
            }
            this.samples = [...this.samples, ...samples]
            while (this.samples.length > size * 2) {
                flush()
            }
            return result//.filter(s=>s.db > -300)
        },
        schema: {
            size: {
                type: 'number'
            }
        }
    },
    {
        name: 'sync',
        apply(samples, tr, datasets) {
            let { offset, size } = tr
            offset = Number(offset)
            size = Number(size)
            if (!this.samples) {
                this.samples = []
            }
            let result = []
            const MIN_SAMPLES_COUNT = size + offset

            const flush = () => {
                {
                    const n = this.samples.length
                    if (n < MIN_SAMPLES_COUNT) {
                        throw `not enough samples for autocorrelation (${n}<${MIN_SAMPLES_COUNT})`
                    }
                }

                const v1 = this.samples.slice(0, size)
                const v2 = this.samples.slice(offset, offset + size)
                const firstSample = this.samples.shift()
                const n1 = norm(v1)
                const n2 = norm(v2)
                const n1n2 = n1 * n2
                let { i, q } = dot(v1, v2)
                i /= n1n2
                q /= n1n2
                const n = Math.sqrt(i * i + q * q)
                const sample = {
                    t: firstSample.t,
                    i,
                    q,
                    n
                }
                result.push(sample)
            }
            this.samples = [...this.samples, ...samples]
            while (this.samples.length > MIN_SAMPLES_COUNT) {
                flush()
            }
            return result//.filter(s=>s.db > -300)
        },
        schema: {
            size: {
                type: 'number'
            },
            offset: {
                type: 'number'
            }
        }
    },
    {
        name: 'short_sync',
        apply(samples, tr, datasets) {
            let { offset, size } = tr
            offset = Number(offset)
            size = Number(size)
            if (!this.samples) {
                this.samples = []
            }
            let result = []
            console.log('computing short_sync', offset, size)
            const MIN_SAMPLES_COUNT = size + offset
            const flush = () => {
                {
                    const n = this.samples.length
                    if (n < MIN_SAMPLES_COUNT) {
                        throw `not enough samples for autocorrelation (${n}<${MIN_SAMPLES_COUNT})`
                    }
                }

                let accu_i = 0, accu_q = 0, accu_n2 = 0, accu_t = 0
                for (let idx = 0; idx < size; idx++) {
                    const s1 = this.samples[idx]
                    const s2 = this.samples[offset + idx]
                    accu_n2 += s1.i * s1.i + s1.q * s1.q
                    accu_i += s2.i * s1.i + s2.q * s1.q
                    accu_q += -s1.i * s2.q + s1.q * s2.i
                    accu_t += s1.t / size
                }
                accu_i = accu_i / accu_n2
                accu_q = accu_q / accu_n2


                const cfo = phase({ i: accu_i, q: accu_q }) / offset
                const n = Math.sqrt(accu_i * accu_i + accu_q * accu_q)
                const sample = {
                    t: accu_t,
                    i: accu_i, q: accu_q,
                    n,
                    cfo,
                }
                if (n > 0.7)
                    console.log('cfo', cfo)
                result.push(sample)
                this.samples.shift()
            }
            this.samples = [...this.samples, ...samples]
            while (this.samples.length > MIN_SAMPLES_COUNT) {
                flush()
            }
            return result//.filter(s=>s.db > -300)
        },
        schema: {
            size: {
                type: 'number'
            }
        }
    },
    {
        name: 'correlation',
        apply(samples, tr, datasets) {
            let { sequence, normalize } = tr
            if (typeof sequence === 'string') {
                sequence = KNOWN_SEQUENCES[sequence]
            }

            if (!sequence) {
                throw `invalid sequence : ${tr.sequence}`
            }
            if (!this.samples) {
                this.samples = []
                this.nextIndex = 0
            }
            let result = []
            console.log('computing correlation with sequence', sequence)
            const sequenceLength = sequence.length / 2
            const flush = () => {
                {
                    const n = this.samples.length
                    if (n < sequenceLength) {
                        throw `not enough samples for correlation (${n}<${sequenceLength})`
                    }
                }

                let accu_i = 0, accu_q = 0, accu_n2 = 0, accu_t = 0
                for (let idx = 0; idx < sequenceLength; idx++) {
                    const s1 = this.samples[idx]
                    const s2 = { i: sequence[2 * idx], q: sequence[2 * idx + 1] }
                    accu_n2 += s1.i * s1.i + s1.q * s1.q
                    accu_i += s2.i * s1.i + s2.q * s1.q
                    accu_q += -s1.i * s2.q + s1.q * s2.i
                    accu_t += s1.t / sequenceLength
                }
                accu_n2 = 1.0
                accu_i = accu_i / accu_n2
                accu_q = accu_q / accu_n2

                const sample = {
                    t: accu_t,
                    n: Math.sqrt(accu_i * accu_i + accu_q * accu_q),
                    index: this.nextIndex++
                }
                result.push(sample)
                this.samples.shift()
            }
            this.samples = [...this.samples, ...samples]
            while (this.samples.length > sequenceLength) {
                flush()
            }
            return result//.filter(s=>s.db > -300)
        },
        schema: {
            sequence: {
                oneOf: [
                    {
                        type: 'array'
                    },
                    {
                        type: 'string'
                    }
                ]
            },
            normalize: { type: 'boolean' }
        }
    },
    ofdm,
    ofdm2,
    ofdm3,
    {
        name: 'cfo',
        apply(samples, tr, datasets) {
            const { df } = tr
            return samples.map((inputSample, idx) => {
                const phaseShift = df * idx
                const outputSample = rotate(inputSample, phaseShift)
                return outputSample
            })
        },
        schema: {
            df: { type: 'number' }
        }
    }
].map(tr => ({ ...tr, name: 'dsp:' + tr.name })) as any[]
