import { BitmapLayer, COORDINATE_SYSTEM } from "deck.gl";
import Carte from ".";
import { defaultGpsOrigin } from "../defaults";
import { EARTH_RADIUS } from "../utils/geography";
import { getImage, getImageMetadata, getUrlHead } from "../utils/image";
import { SimpleMeshLayer } from '@deck.gl/mesh-layers';
import { CameraGeometry } from "../utils/3d/geometries";

const exampleConfig = {
    url: "...",
    anchor: [0, 0],
    pixelAnchor: [0, 0],
    width: 20,
    height: 10
}
// TODO : handle latitude,longitude from config and gpsOrigin
function getBounds(image, layout) {
    // console.log('bounds from', layout)
    const imageAspectRatio = image.width / image.height
    let { anchor, pixelAnchor, width, height } = layout
    pixelAnchor = pixelAnchor || [image.width / 2, image.height / 2]
    anchor = anchor || [0, 0]
    if (!width) {
        if (height) width = height * imageAspectRatio
        if (!height) width = 1
    }
    if (!height)
        height = width / imageAspectRatio

    const xyBottomLeft = [anchor[0] - pixelAnchor[0] * width / image.width, anchor[1] - pixelAnchor[1] * height / image.height]
    const xyTopRight = [anchor[0] + (image.width - pixelAnchor[0]) * width / image.width, anchor[1] + (image.height - pixelAnchor[1]) * height / image.height]
    const bounds = [...xyBottomLeft, ...xyTopRight]
    // console.log(bounds)
    return bounds
}

function localizeImageFromTags(tags) {
    const props: any = {}
    for (let key of ["FocalPlaneXResolution", "FocalPlaneYResolution", "AboveGroundAltitude", "DroneRollDegree", "CameraRollDegree", "DronePitchDegree", "CameraPitchDegree", "DroneYawDegree", "CameraYawDegree", "latitude", "longitude", "FocalLength", "WidthInPixels", "HeightInPixels"]) {
        const raw = tags[key]
        if (Number.isFinite(raw))
            props[key] = raw
        else if (typeof raw === 'string') {
            if (raw.includes('/')) {
                const [numerator, denominator] = raw.split('/').map(Number)
                props[key] = numerator / denominator
            } else {
                props[key] = Number(raw)
            }
        }
        console.log(key, tags[key], props[key])
    }
    const prefixUsed = 'Camera'
    const roll = props[prefixUsed + 'RollDegree']
    const pitch = props[prefixUsed + 'PitchDegree']
    const yaw = props[prefixUsed + 'YawDegree']
    const widthInMeters = 0.01 * props.WidthInPixels / (props.FocalPlaneXResolution) * props.AboveGroundAltitude / (props.FocalLength * 0.001)
    const heightInMeters = 0.01 * props.HeightInPixels / (props.FocalPlaneYResolution) * props.AboveGroundAltitude / (props.FocalLength * 0.001)
    console.log('width in meters', widthInMeters)
    console.log('height in meters', heightInMeters)
    const { latitude, longitude } = props
    const altitude = props.AboveGroundAltitude
    return {
        // anchor: [latitude, longitude],
        gpsOrigin: { latitude, longitude },
        latitude,
        longitude,
        altitude,
        roll,
        pitch,
        yaw,
        width: widthInMeters,
        height: heightInMeters,
        rotation: - yaw,
        time: new Date(tags.DateTimeOriginal)
    }
}

function isRectangularBounds(bounds) {
    if (Array.isArray(bounds) && Number.isFinite(bounds[0]))
        return true
    return false
}
import createMesh from "./create-bitmap-mesh";
import { DescartesContext } from "..";
function rotateMesh(mesh, rotationInDegrees) {
    let minx = mesh.positions[0]
    let maxx = minx
    let miny = mesh.positions[1]
    let maxy = miny
    for (let i = 0; i < mesh.positions.length / 3; i++) {
        minx = Math.min(minx, mesh.positions[3 * i])
        maxx = Math.max(maxx, mesh.positions[3 * i])
        miny = Math.min(miny, mesh.positions[3 * i + 1])
        maxy = Math.min(maxy, mesh.positions[3 * i + 1])
    }
    const cx = (maxx + minx) / 2
    const cy = (maxy + miny) / 2
    const theta = rotationInDegrees * Math.PI / 180
    const c = Math.cos(theta)
    const s = Math.sin(theta)
    for (let i = 0; i < mesh.positions.length / 3; i++) {
        const dx = mesh.positions[3 * i] - cx
        const dy = mesh.positions[3 * i + 1] - cy
        mesh.positions[3 * i] = cx + c * dx - s * dy
        mesh.positions[3 * i + 1] = cy + s * dx + c * dy
    }
    return mesh
}

function mulMat(m1, m2) {
    const m1Rows = 3, m1Cols = 3, m2Rows = m1Cols, m2Cols = m2.length / m2Rows
    let result = new Array(m1Rows * m2Cols).fill(0)
    for (let i = 0; i < m1Rows; i++) {
        for (let j = 0; j < m2Cols; j++) {
            for (let k = 0; k < m1Cols; k++) {
                result[i * m2Cols + j] += m1[i * m1Cols + k] * m2[k * m2Cols + j]
            }
        }
    }
    return result
}
function axisRotate(axis, deg,) {
    const c = Math.cos(deg * Math.PI / 180)
    const s = Math.sin(deg * Math.PI / 180)
    switch (axis) {
        case 0:
            return [
                1, 0, 0,
                0, c, -s,
                0, s, c,
            ]
        case 1:
            return [
                c, 0, s,
                0, 1, 0,
                -s, 0, c
            ]
        case 2:
            return [
                c, -s, 0,
                s, c, 0,
                0, 0, 1
            ]
        default:
            throw `invalid axis : ${axis}`
    }
}
const rollMat = deg => axisRotate(1, deg)
const pitchMat = deg => axisRotate(0, deg)
const yawMat = deg => axisRotate(2, deg)

function rotMat(roll, pitch, yaw) {
    let currentMat = rollMat(roll)
    currentMat = mulMat(pitchMat(pitch), currentMat)
    currentMat = mulMat(yawMat(yaw), currentMat)
    return currentMat
}



function projectPlane(mesh, { roll, pitch, yaw, cx, cy, cz }) {
    pitch += 90

    const rot = rotMat(roll || 0, pitch || 0, yaw || 0)
    for (let i = 0; i < mesh.positions.length / 3; i++) {
        const dx0 = mesh.positions[3 * i] - cx
        const dy0 = mesh.positions[3 * i + 1] - cy
        const dz0 = mesh.positions[3 * i + 2] - cz
        const [dx, dy, dz] = mulMat(rot, [dx0, dy0, dz0])
        const projZ = -cz
        let factor = projZ / dz
        // factor = 1
        mesh.positions[3 * i] = cx + dx * factor
        mesh.positions[3 * i + 1] = cy + dy * factor
        mesh.positions[3 * i + 2] = cz + dz * factor
    }
    return mesh
}

class MyBitmapLayer extends BitmapLayer {
    static layerName = 'MyBitmapLayer';
    constructor(cfg) {
        super(cfg)
    }
    protected _createMesh() {
        const { bounds, camera, rotation } = this.props;

        let normalizedBounds = bounds;
        // bounds as [minX, minY, maxX, maxY]
        if (isRectangularBounds(bounds)) {
            /*
              (minX0, maxY3) ---- (maxX2, maxY3)
                     |                  |
                     |                  |
                     |                  |
              (minX0, minY1) ---- (maxX2, minY1)
           */
            normalizedBounds = [
                [bounds[0], bounds[1]],
                [bounds[0], bounds[3]],
                [bounds[2], bounds[3]],
                [bounds[2], bounds[1]]
            ];
        }

        const mesh = createMesh(normalizedBounds, this.context.viewport.resolution);
        return projectPlane(mesh, camera)
        return rotateMesh(mesh, rotation || 0)
    }
}

const PHOTO_DURATION_S = 60

export default class ImageCarte implements Carte {
    config: any;
    width: number;
    height: number;
    bounds?: [number, number, number, number]
    tags?: any
    async init() {
        const { url, rotation } = this.config
        const img = await getImage(url)
        try {
            console.log('trying to extract exif tags from')
            const tags = await getImageMetadata(url)
            tags.WidthInPixels = img.width
            tags.HeightInPixels = img.height
            console.log('exif tags', tags)
            this.tags = tags

            const configOverrides = localizeImageFromTags(tags)
            this.config = { ...this.config, ...configOverrides }
        } catch (err) {
            console.log('failed to extract exif tags')
            console.log(err.toString())
        }
        const { width, height } = img
        this.width = width
        this.height = height
    }
    getState() {
        return {
            tags: this.tags
        }
    }
    getLayers(ctx: DescartesContext = {}) {
        const gpsOrigin = this.config.gpsOrigin || defaultGpsOrigin
        const bounds = getBounds(this, this.config)
        let { latitude, longitude, altitude, roll, pitch, yaw } = this.config
        const { droll, dpitch, dyaw } = ctx.variables || {}
        roll += droll || 0
        pitch += dpitch || 0
        yaw += dyaw || 0
        const time = ctx.time
        const photoTime = this.config.time.getTime()

        if (time < photoTime / 1000 || time > photoTime / 1000 + PHOTO_DURATION_S)
            return []

        return [
            // new SimpleMeshLayer({
            //     id: this.name + '-camera-layer',
            //     data: [{}],
            //     mesh: CameraGeometry,
            //     getPosition: [longitude, latitude, altitude],
            //     getColor: [255, 0, 0],
            //     sizeScale: 1,
            //     getOrientation: [roll || 0, (yaw || 0),  (pitch || 0)] // deck gl pitch,yaw,roll is in ENU frame (plane pointing east)
            // }),
            new MyBitmapLayer({
                id: this.config.name,
                coordinateSystem: COORDINATE_SYSTEM.METER_OFFSETS,
                coordinateOrigin: [this.config.gpsOrigin.longitude, this.config.gpsOrigin.latitude, this.config.gpsOrigin.altitude || 0],
                image: this.config.url,
                bounds,
                camera: {
                    roll, pitch, yaw, cx: 0, cy: 0, cz: altitude
                },
                rotation: this.config.rotation || 0,
                opacity: 1,
                getPolygonOffset: ({ layerIndex }) => [-3, -layerIndex * 100]
            })]
    }
    getRanges() {
        // const bounds = getBounds(this, this.config)
        const { longitude, latitude, width, height } = this.config

        const diameter = Math.max(width, height)
        // broken at north pole
        let dlat = diameter / EARTH_RADIUS, dlon = diameter / (EARTH_RADIUS * Math.cos(latitude))
        dlon = 0.0001
        dlat = 0.0001
        // console.log(longitude, latitude, width, height)
        // console.log(dlon, dlat)
        const photoTimestamp = this.config.time.getTime()
        return {
            longitude: [longitude - dlon, longitude + dlon],
            latitude: [latitude - dlat, latitude + dlat],
            t: [photoTimestamp / 1000, photoTimestamp / 1000 + PHOTO_DURATION_S]
            // x: [bounds[0], bounds[2]],
            // y: [bounds[1], bounds[3]],
            // z: [0, 0]
        }
    }
} 