export default {
    "name": "dcc-viewer",
    "canvas": ".descartes-canvas",
    "defaults": {
        "gpsOrigin": {
            "latitude": 44.68180539011111,
            "longitude": -0.7091738936666666
        }
    },
    "controls": {
        "cartes": true,
        "hover": true,
        "selection": true,
        "global": true,
        "player": true
    },
    "variables": {
        "dcc_provider_url": {
            "type": "string",
            "_default": "ws://10.0.0.100:8010?filter=front",
            "default": "ws://localhost:12010"
        }
    },
    "details": "selection",
    details: 'objects',
    "scales": [],
    "dataset": [

    ],
    "cartes": [
        {
            "type": "helpers"
        },
        {
            type: 'tiles'
        },
        {
            "name": "drones",
            "type": "live",
            "shape": "sphere",
            "solid": true,
            "size": 1.0,
            "color": [100, 0, 0],
            // "memory": 0,
            "coordinates": "gps",
            "url": "$dcc_provider_url"
        }
    ]
}