
export async function getImage(url) {
    const img = new Image();
    img.src = url;
    return new Promise((res, rej) => {
        img.onload = function () { res(img); }
    })
}

export async function getUrlHead(url) {
    const response = await fetch(url, { method: 'HEAD' })
    const headers = []
    for (let h of response.headers.values())
        headers.push(h)
    return headers
}
import * as EXIF from 'exif-parser'
import exifr from 'exifr/dist/full.esm.mjs' // to use ES Modules

export async function getImageMetadata(url) {
    const ab = await fetch(url).then(r => r.arrayBuffer())
    const parser = EXIF.create(ab)
    parser.enableBinaryFields(true)
    parser.enablePointers(true)
    const tags = parser.parse()
    console.log(tags)
    return exifr.parse(ab, true)
}

