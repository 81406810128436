import * as csv from './csv'
import * as json from './json'
import * as ndjson from './ndjson'
import * as arrow from './arrow'
import * as iq from './iq'


const getUrl = (spec) => typeof spec === 'string' ? spec : spec.csv || spec.url || spec.file


const URL_TYPES = {
    csv,
    json,
    ndjson,
    arrow,
    iq
}

async function infereType(spec) {
    const url = getUrl(spec)
    const mime = spec.mime || spec.type
    let extension
    if (mime) {
        extension = mime.split('/').pop()
    } else {
        extension = url.split('.').pop()
    }

    if (URL_TYPES[extension])
        return URL_TYPES[extension]
    throw `unable to guess type from url ${url}`
}




export async function getSizeFromUrl(spec) {
    const type = await infereType.bind(this)(spec)
    return type.getSize.bind(this)(spec)
}

export async function getSchemaFromUrl(spec) {
    const type = await infereType.bind(this)(spec)
    if (!type.getSchema)
        return false
    return type.getSchema.bind(this)(spec)
}

export async function getChunkFromUrl(spec, offset, size) {
    const type = await infereType.bind(this)(spec)
    return type.getChunkAt.bind(this)(spec, offset, size)
}



export default (spec, dataset) => ({
    getChunkAt: function (offset, size) {
        return getChunkFromUrl.bind(this)(spec, offset, size)
    },
    getSize: function () {
        return getSizeFromUrl.bind(this)(spec)
    },
    getSchema: function () {
        return getSchemaFromUrl.bind(this)(spec)

    }
})