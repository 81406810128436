// XY grid
// XYZ frame
// pivot points

import { COORDINATE_SYSTEM } from "@deck.gl/core";
import { LineLayer, PointCloudLayer } from "@deck.gl/layers";
import { SimpleMeshLayer } from '@deck.gl/mesh-layers';

import Carte from ".";
import { CameraGeometry } from "../utils/3d/geometries";
import { SphereGeometry } from '@luma.gl/core'

const pivotGeometry = new SphereGeometry({ radius: 0.1 });

const xrange = [-10, 10];
const yrange = [-10, 10];

const STEP = 1;
const Z_OFFSET = 0.005;

let lines = [];
for (let x = xrange[0]; x <= xrange[1]; x += STEP) {
  lines.push({
    from: [x, yrange[0], Z_OFFSET],
    to: [x, yrange[1], Z_OFFSET],
  });
}
for (let y = yrange[0]; y <= yrange[1]; y += STEP) {
  lines.push({
    from: [xrange[0], y, Z_OFFSET],
    to: [xrange[1], y, Z_OFFSET],
  });
}
function getHelperLayers(context, config) {

  const { gpsOrigin } = config
  if (!gpsOrigin)
    return []
  const { latitude, longitude, altitude } = gpsOrigin
  const origin = [longitude, latitude, 0];

  const commonProps = {
    coordinateSystem: COORDINATE_SYSTEM.METER_OFFSETS,
    coordinateOrigin: origin,
  }

  const gridLayer = new LineLayer({
    ...commonProps,
    id: "grid-helper",
    data: lines,
    pickable: false,
    getWidth: 1,
    getSourcePosition: (d) => d.from,
    getTargetPosition: (d) => d.to,
    getColor: (d) => [100, 100, 100],
  });
  const axisLayer = new LineLayer({
    ...commonProps,
    id: "axis-helper",
    data: [
      { from: [0, 0, Z_OFFSET], to: [1, 0, Z_OFFSET], color: [255, 0, 0] },
      { from: [0, 0, Z_OFFSET], to: [0, 1, Z_OFFSET], color: [0, 255, 0] },
      { from: [0, 0, Z_OFFSET], to: [0, 0, 1 + Z_OFFSET], color: [0, 0, 255] },
    ],
    pickable: false,
    getWidth: 1,
    getSourcePosition: (d) => d.from,
    getTargetPosition: (d) => d.to,
    getColor: (d) => d.color,
  });
  const interestPointsLayer = new PointCloudLayer({
    ...commonProps,
    id: "interest-layer",
    data: [
      // { position: [0, 0, 0], color: [100, 199, 100], label: "origin" },
      // { position: mapmap.state.pivot, color: [0, 255, 0], label: "pivot" },
    ],
    parameters: {
      depthTest: false
    },
    pickable: false,
    coordinateOrigin: origin,
    pointSize: 10,
    getPosition: (d) => d.position,
    getNormal: (d) => [0, 0, 1],
    getColor: (d) => d.color,
  });

  const camerasLayer = new SimpleMeshLayer({
    ...commonProps,
    id: 'cameras-layer',
    data: context.cameras.filter(c => c.id !== context.currentCamera),
    mesh: CameraGeometry,
    getPosition: c => c.state.position || [1, 1, 1],
    getColor: [255, 0, 0],
    getOrientation: [0, 0, 0]
  })

  // const currentCamera = context.cameras.filter(c => c.id === context.currentCamera).map(c => ({ position: [c.state.longitude, c.state.latitude, c.state.altitude || 0] }))
  const currentCamera = [context.viewState].map(c => ({ position: [c.longitude, c.latitude, c.altitude || 0] }))
  // console.log(currentCamera[0])
  const currentCameraLayer = new SimpleMeshLayer({
    // ...commonProps,
    id: 'camera-layer',
    data: currentCamera,
    mesh: pivotGeometry,
    getPosition: c => c.position,
    getColor: [255, 0, 0],
    getOrientation: [0, 0, 0]
  })
  return [
    gridLayer, axisLayer,
    interestPointsLayer,
    // currentCameraLayer
    // camerasLayer
  ];
}

export default class HelpersCarte implements Carte {
  config: any;
  getLayers(ctx: any, lastCtx: any, lastLayers: any) {
    return getHelperLayers(ctx, this.config);
  }
  getRanges() {
    return {
      x: xrange,
      y: yrange,
    }
  }
}