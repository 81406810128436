export default {
    "name": "theatre",
    "canvas": ".descartes-canvas",

    "controls": {
        "cartes": true,
        "hover": true,
        "selection": true,
        "global": true,
        "player": true
    },
    "details": "view",
    "scales": [],
    "cartes": [
        // {
        //     "type": "helpers",
        //     coordinates: 'cartesian',

        // },
        {
            "type": "gltf",
            coordinates: 'cartesian',
            "url": "http://localhost:8080/Carnival_3D.glb",
            roll: 0,
            pitch: 0,
            yaw: 0
        }
    ]
}