import { SimpleMeshLayer } from "deck.gl";
import Carte from ".";
import { Geometry, CubeGeometry } from '@luma.gl/core'
import { COORDINATE_SYSTEM } from "deck.gl";
import { schemeCategory10 } from "d3";
import { getCoordinates } from "./common/coordinates";

import GL from '@luma.gl/constants';


const cubeMesh = new CubeGeometry();
export default class DomainCarte implements Carte {
    config: any;
    boxes: any[]
    async init(dataset) {
        const { boxes } = this.config
        const samples = await dataset.getSamples(boxes)
        this.boxes = []
        const tryUsingBoxes = (input, tag = null) => {
            if (typeof input !== 'object')
                return
            if (Array.isArray(input)) {
                for (let box of input)
                    tryUsingBoxes(box)
            } else if (input.x && input.y && input.y) {
                try {
                    if (input.x.length !== 2)
                        return
                    if (input.y.length !== 2)
                        return
                    if (input.z.length !== 2)
                        input = { ...input, z: [0, 0] }
                    this.boxes.push({
                        ...input,
                        tag
                    })
                } catch (err) { }
            } else {
                for (let prop in input) {
                    tryUsingBoxes(input[prop], [tag, prop].filter(Boolean).join('.'))
                }
            }
        }
        samples.forEach((box) => tryUsingBoxes(box))
        tryUsingBoxes(samples)


    }
    getLayers(ctx: any, lastCtx: any, lastLayers: any): any[] {
        const getColor = this.config.color || [0, 255, 0]
        return [
            new SimpleMeshLayer({
                ...getCoordinates(this.config),

                id: `domain:${this.config.name}`,
                data: this.boxes,
                mesh: cubeMesh,
                getPosition: c => [(c.x[0] + c.x[1]) / 2, (c.y[0] + c.y[1]) / 2, (c.z[0] + c.z[1]) / 2],
                getScale: c => [(c.x[0] - c.x[1]) / 2, (c.y[0] - c.y[1]) / 2, (c.z[0] - c.z[1]) / 2],
                getColor,
                opacity: 0.01,
                getOrientation: c => c.rotation ? [0, c.rotationtoposet
                    , 0] : [0, 0, 0],
                parameters: {
                    blend: true,
                    blendFunc: [GL.ONE, GL.ONE, GL.ONE, GL.ONE],
                    depthMask: false,

                }
            })
        ]
    }
    getRanges() {
        return this.boxes.reduce((prev, curr) => {
            let result = prev
            for (let prop in result) {
                const [c0, c1] = curr[prop]
                if (!result[prop])
                    result[prop] = [c0, c1]
                result[prop] = [
                    Math.min(result[prop][0], curr[prop][0]),
                    Math.max(result[prop][1], curr[prop][1]),
                ]
            }
            return result
        }, {
            x: null,
            y: null,
            z: null,
        })
    }
}