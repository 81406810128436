import { get, set } from "object-path-immutable";
import Descartes from "..";

function rotateView(descartes, direction, delta) {
    const { context } = descartes
    const value = Number(get(context, ['viewState', direction], 0)) + delta
    descartes.update({ viewState: set(context.viewState, [direction], value) })
}

export default function handleKeyboardEvent(e, descartes: Descartes) {
    if (e.target.nodeName === 'INPUT')
        return
    return
    // ctrl-a, escape (no interaction, no selection),
    const { container, context } = descartes;
    const w = container.clientWidth;
    const h = container.clientHeight;
    let pitch, bearing;
    let deltaAngle = 2
    switch (e.key) {
        case 'ArrowUp':
        case 'ArrowDown':
            break;
        case 'z':
            rotateView(descartes, 'pitch', -deltaAngle)
            break;
        case 's':
            rotateView(descartes, 'pitch', deltaAngle)
            break;
        case 'q':
            rotateView(descartes, 'bearing', -deltaAngle)
            break;
        case 'd':
            rotateView(descartes, 'bearing', deltaAngle)
            break;
        case 'c':
            descartes.switchCamera()
            break;
        case "Escape":
            if (descartes.interaction) descartes.setInteraction(null);
            else descartes.select([]);
            break;
        case "Enter":
            // confirm interaction
            break;
        case "f": //
            const lookAt = descartes.context.lookAt ? null : descartes.context.selection;
            console.log('focus on ', descartes.context.selection)
            descartes.update({ lookAt });
            break;
        case "a": //
            if (e.ctrlKey) {
                // list object in layers with selectable in config
                let list = [];
                for (let carteManager of descartes.carteManagers)
                    if (carteManager.config.selectable) {
                        // const objects = carteManager.data;
                        // list = [...list, ...objects.map((o) => o.id)];
                    }
                descartes.select(list);
            }
            break;
        // case "f":
        // const viewport = new WebMercatorViewport({
        //   ...descartes.context.viewState,
        //   width: w,
        //   height: h,
        // });
        // const bbox = choreoVizu.getBbox();
        // const { longitude, latitude, zoom } = viewport.fitBounds(bbox, {
        //   padding: 150,
        // });
        // deck.setProps({
        //   viewState: { ...initialViewState, longitude, latitude, zoom },
        // });
        // break;
        case "p":
            const newZoom = get(descartes.context as any, ["viewState", "zoom"], 1) + 1;
            descartes.update(set(descartes.context, ["viewState", "zoom"], newZoom));
            break;
        default:
            break;
    }
}