import { defaultGpsOrigin, HOVERED_COLOR, SELECTED_COLOR } from '../../defaults';
import { PointCloudLayer, COORDINATE_SYSTEM, TextLayer, SimpleMeshLayer, ScenegraphLayer } from 'deck.gl';
import duckGltfUrl from 'url:../../../test-data/duck.gltf'
import { CubeGeometry, SphereGeometry } from '@luma.gl/core'
import { scale } from '../../scale-manager';
import { get } from 'object-path-immutable';
import * as d3 from 'd3'
import { getObjectId } from '../data';
const DEFAULT_RADIUS = 0.5
const DEFAULT_SIZE = 0.5
const DEFAULT_COLOR = [100, 0, 0]

const cubeGeometry = new CubeGeometry()
const sphereGeometry = new SphereGeometry({
    radius: DEFAULT_RADIUS,
    nlat: 50,
    nlong: 50
});


export function getBaseLayerProps(ctx, config) {
    const gpsOrigin = config.gpsOrigin || defaultGpsOrigin
    let commonProps: any = {
        getPosition: d => [d.x, d.y, d.z],//.map(c => c * (positionScale || 1.0)),
        // getOrientation: d => [0, 0, 0]
    }

    const coordinates = config.coordinates || "cartesian"
    switch (coordinates) {
        case 'gps':
            break;
        case 'cartesian':
            commonProps = {
                ...commonProps,
                coordinateSystem: COORDINATE_SYSTEM.METER_OFFSETS,
                coordinateOrigin: [gpsOrigin.longitude, gpsOrigin.latitude, 0],
            }
        default:
            break;
    }

    return commonProps
}

export function getObjectsLayers(data, ctx, config) {

    let commonProps = getBaseLayerProps(ctx, config)

    const layers = []
    let mainLayer



    const { color, persistance } = config
    let colorScale
    if (color && !Array.isArray(color)) {
        colorScale = (sample) => {
            const value = get(sample, color.property)
            const col = scale(color.scale, value)
            if (!col)
                return sample.color
            const rgb = d3.rgb(col)
            return [rgb.r, rgb.g, rgb.b]
        }
    }

    const id = `objects:${config.name}`;
    const COLOR_FACTOR = 2
    commonProps = {
        data,
        ...commonProps,
        id,
        getColor: d => (ctx.selection.indexOf(getObjectId(d)) >= 0) ? SELECTED_COLOR
            : (persistance && (d._dt > persistance)) ? [50, 50, 50]
                : colorScale ? colorScale(d)
                    : (Number.isFinite(d.r) ? [d.r * COLOR_FACTOR, d.g * COLOR_FACTOR, d.b * COLOR_FACTOR] : d.color) || config.color || DEFAULT_COLOR,
        getScale: d => {
            let { size } = d
            if (!size) {
                size = config.size || DEFAULT_SIZE
            }
            if (Number.isFinite(size))
                size = [size, size, size]
            if (ctx.selection.indexOf(getObjectId(d)) >= 0)
                size = size.map(n => 5 * n)
            return size

        },
        pickable: true,
        autoHighlight: true,
        highlightColor: HOVERED_COLOR,
    }
    switch (config.shape) {
        case 'sphere':
            mainLayer = new SimpleMeshLayer({
                ...commonProps,
                id,
                mesh: sphereGeometry,
                _lighting: 'pbr'
            })
            break;
        case 'box':
        case 'cube':
            mainLayer = new SimpleMeshLayer({
                ...commonProps,
                id,
                mesh: cubeGeometry,
                // getScale: [DEFAULT_RADIUS, DEFAULT_RADIUS, DEFAULT_RADIUS],
                _lighting: 'pbr'
            })
            break;
        case 'duck':
            mainLayer = new ScenegraphLayer({
                ...commonProps,
                id,
                scenegraph: duckGltfUrl,
                getOrientation: [0, 180, 90 + Math.sin(ctx.time * 7) * 15],
                sizeScale: DEFAULT_RADIUS,
                _lighting: 'pbr'
            })
            break;
        default:
            // if url : mesh ou scenegraph

            mainLayer = new PointCloudLayer({
                ...commonProps,
                // coordinateOrigin: [-122.4, 37.74],
                // radiusPixels: 4,
                // getNormal: d => d.normal,

                sizeUnits: 'meters',
                pointSize: DEFAULT_RADIUS,
                // sizeUnits: 'pixels',
                // pointSize: 3,

                onerror: (err) => {
                }
            })
            break;
    }

    layers.push(mainLayer)
    if (config.label) {
        const LABEL_ZOFFSET = 0.6
        layers.push(new TextLayer({
            ...commonProps,
            id: `object-labels:${config.name}`,
            pickable: false,
            getPosition: d => [d.x, d.y, d.z].map((v, i) => i === 2 ? v + LABEL_ZOFFSET : v),
            getText: d => typeof d.label === 'string' ? d.label : getObjectId(d),
            getSize: 16,
            getAngle: 0,
            getColor: d => ctx.selection.indexOf(getObjectId(d)) >= 0 ? SELECTED_COLOR : [0, 0, 0],
            getTextAnchor: 'middle',
            getAlignmentBaseline: 'center'
        }))
    }
    return layers
}

export function getTableLayers(columns, ctx, config) {
    const { x, y, z, r, g, b, roll, pitch, yaw, size, shape, index, length, id, dt } = columns
    // console.log(x[0], y[0], z[0])
    // TODO : isSelected ? isHovered ?
    // TODO : provide attribute buffers
    let commonProps = getBaseLayerProps(ctx, config)

    const layers = []
    let mainLayer



    const { color, persistance } = config
    let colorScale
    if (color && !Array.isArray(color)) {
        colorScale = (sample) => {
            const value = get(sample, color.property)
            const col = scale(color.scale, value)
            if (!col)
                return sample.color
            const rgb = d3.rgb(col)
            return [rgb.r, rgb.g, rgb.b]
        }
    }

    const layerId = `objects:${config.name}`;
    const COLOR_FACTOR = 2
    commonProps = {
        data: id.map((id) => ({ id })),
        ...commonProps,
        getPosition: (object, { index, data, target }) => {
            target[0] = columns.x[index];
            target[1] = columns.y[index];
            target[2] = columns.z[index];
            return target;
        },
        getColor: (object, { index, data, target }) => {
            let red, green, blue
            if (ctx.isSelected[id[index]]) {
                [red, green, blue] = SELECTED_COLOR
            } else if (dt && dt[index] > persistance) {
                red = green = blue = 50
            } else {
                red = r[index]
                green = g[index]
                blue = b[index]
            }
            target[0] = red;
            target[1] = green;
            target[2] = blue;
            return target;
        },
        id: layerId,
        getScale: (object, { index, data, target }) => {
            let size = columns.size[index]
            if (ctx.isSelected[id[index]])
                size = size * 5
            target[0] = size;
            target[1] = size;
            target[2] = size;
            return target;
        },
        pickable: true,
        autoHighlight: true,
        highlightColor: HOVERED_COLOR,
    }
    switch (config.shape) {
        case 'sphere':
            mainLayer = new SimpleMeshLayer({
                ...commonProps,
                mesh: sphereGeometry,
                _lighting: 'pbr'
            })
            break;
        case 'box':
        case 'cube':
            mainLayer = new SimpleMeshLayer({
                ...commonProps,
                mesh: cubeGeometry,
                // getScale: [DEFAULT_RADIUS, DEFAULT_RADIUS, DEFAULT_RADIUS],
                _lighting: 'pbr'
            })
            break;
        case 'duck':
            mainLayer = new ScenegraphLayer({
                ...commonProps,
                scenegraph: duckGltfUrl,
                getOrientation: [0, 180, 90 + Math.sin(ctx.time * 7) * 15],
                sizeScale: DEFAULT_RADIUS,
                _lighting: 'pbr'
            })
            break;
        default:
            // if url : mesh ou scenegraph

            mainLayer = new PointCloudLayer({
                ...commonProps,
                // coordinateOrigin: [-122.4, 37.74],
                // radiusPixels: 4,
                // getNormal: d => d.normal,

                sizeUnits: 'meters',
                pointSize: DEFAULT_RADIUS,
                // sizeUnits: 'pixels',
                // pointSize: 3,

                onerror: (err) => {
                }
            })
            break;
    }

    layers.push(mainLayer)
    if (config.label) {
        const LABEL_ZOFFSET = 0.6
        layers.push(new TextLayer({
            ...commonProps,
            id: `object-labels:${config.name}`,
            pickable: false,
            getPosition: d => [d.x, d.y, d.z].map((v, i) => i === 2 ? v + LABEL_ZOFFSET : v),
            getText: d => typeof d.label === 'string' ? d.label : getObjectId(d),
            getSize: 16,
            getAngle: 0,
            getColor: d => ctx.isSelected[getObjectId(d)] ? SELECTED_COLOR : [0, 0, 0],
            getTextAnchor: 'middle',
            getAlignmentBaseline: 'center'
        }))
    }
    return layers
}
