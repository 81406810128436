import { html } from "lit";
import Descartes from "..";
import { handleClickEvent } from "../controls/mouse";
import notify from "web-ui-blocks/notifications";
import { askUser } from "web-ui-blocks/modal/intent"
import { decodeClickEvent } from "../controls/mouse";
import { resolveKnownSelection } from "../selection";

// name
// description
// selection : list || url || obj
//   obj : table/path/filter, prop.comp.value 
let searchTerm = ''
async function saveCurrentSelection(descartes: Descartes) {
    const { selection, knownSelections } = descartes.context

    const { name } = await askUser({ name: 'test' })
    descartes.update({
        knownSelections: [...knownSelections, {
            name,
            description: 'blablabla',
            selection
        }]
    })
}

async function copyCurrentSelection(descartes: Descartes) {
    const { selection } = descartes.context
    const json = JSON.stringify({ name: "custom-selection", description: "A custom selection built from interface", selection }, null, 2)
    await navigator.clipboard.writeText(json)
    const n = "selection copied to clipboard"
    console.log(n)
    notify(n)
}

async function copyCurrentSelectionObjects(descartes: Descartes) {
    const { context } = descartes
    const { selection } = context
    const objects = []
    for (let id of selection)
        for (let cm of descartes.carteManagers) {
            const obj = await cm.getObject(id, context, true)
            if (obj) {
                objects.push(obj)
                break
            }
        }
    const json = JSON.stringify(objects, null, 2)
    await navigator.clipboard.writeText(json)
    const n = "selection copied to clipboard"
    notify(n)
}

async function handleClickOnKnownSelection(known, descartes: Descartes, evt) {
    const modifiers = decodeClickEvent(evt)
    if (!modifiers)
        return
    const selection = await resolveKnownSelection(known, descartes)
    descartes.select(selection, modifiers)
}



export function renderSummary(context, descartes: Descartes) {

    const { selection, knownSelections } = context
    return html`
    <div>
    
        ${selection.length ? html`<div class="row">
            <div>${selection.length} selected</div>
            <button @click="${e => saveCurrentSelection(descartes)}">save</button>
            <button @click="${e => copyCurrentSelection(descartes)}">copy</button>
            <button @click="${e => copyCurrentSelectionObjects(descartes)}">objs</button>
        </div>` : ''}
    
        <div class="column">
            ${knownSelections.map((known) => html`<div class="row known-selection"
                @click="${e => handleClickOnKnownSelection(known, descartes, e)}">
                <div class="btn">${known.name}</div>
                <div class="btn" @click="${e => {
                descartes.update({ knownSelections: knownSelections.filter(s => s !== known) })
            }}">x</div>
            </div>`)}
        </div>
    </div>`
}
export async function renderObjectsDetails(context, descartes: Descartes) {
    const allObjects = {}
    for (let cm of descartes.carteManagers) {
        const ids = await cm.getIds(context) || []
        for (let id of ids) {
            if (!allObjects[id]) {
                const obj = (searchTerm && false) ? await cm.getObject(id, context, true) : {}
                if (obj)
                    allObjects[id] = { ...(obj || {}), id }
            }
        }
        // const objs = await Promise.all(ids.filter(id=>!allObjects[id]).map(id => cm.getObject(id, context, true)))
        // for (let index = 0; index < ids.length; index++) {
        //     const id = ids[index]
        //     const obj = objs[index]
        //     if (!allObjects[id] && obj) {
        //         allObjects[id] = { ...(obj || {}), id }
        //     }
        // }
    }
    const sortObjects = (o1, o2) => o1.name < o2.name ? -1 : 1
    const objects = Object.values(allObjects)
    const filteredObjects = objects.filter(o => o.id.startsWith(searchTerm))
    const renderedObjects = filteredObjects //.slice(0, 10)

    // objects.sort(sortObjects)
    const computeClasses = (o) => ["row", "object", context.isSelected[o.id] ? 'selected' : null].filter(Boolean).join(" ")
    const handleSearch = (e) => {
        // console.log(e.target.value)
        searchTerm = e.target.value
    }
    return html`<div class="column" style="overflow-y:hidden;max-height: 100%;">
    <input @input="${e => handleSearch(e)}" type="text" style="justify-self: stretch" placeholder="search by id ...">
    <div>${filteredObjects.length}${searchTerm.trim() ? `/${objects.length}` : ''} objects</div>
    <div class="column objects" style="overflow-y:auto">${renderedObjects.map((o) =>
        html`<div @click="${(e) => handleClickEvent({ object: o }, e, descartes)}" class="${computeClasses(o)}">${o.id}
        </div>`)}
        ${renderedObjects.length !== filteredObjects.length ? html`<div>...</div>` : ''}
    </div>
</div>`
}