
import { getMetadata, loadRange, localFetch } from "../../http-utils"
import { DataChunk } from "../chunk"

const getUrl = (spec) => spec.iq || spec.url || spec.file




export async function getChunkAt(spec, offset, size): Promise<DataChunk> {
    const samplingRate = spec.rate || 20e6
    const dt = 1 / samplingRate

    const fullSize = await getSize.bind(this)(spec)
    if (offset + size > fullSize)
        size = fullSize - offset
    const response = await loadRange(getUrl(spec), offset, size)
    const tFirstByte = Date.now()
    const ab = await response.arrayBuffer()
    console.log("got array buffer", ab)
    const tLoaded = Date.now()
    const samples: any = []
    const sizePerSample = 4 * 2
    const t0 = offset / sizePerSample * dt
    const floats = new Float32Array(ab)

    const n = (ab.byteLength) / sizePerSample
    for (let i = 0; i < n; i++) {
        const sample = { i: floats[2 * i], q: floats[2 * i + 1], t: t0 + i * dt, index: (offset / sizePerSample) + i }
        samples.push(sample)
    }
    console.log('iq chunk', samples)
    return { samples: () => samples, offset, size: ab.byteLength, tFirstByte, tLoaded, nrows: samples.length }
}

export async function getSize(spec) {
    if (!this.metadata) {
        this.metadata = await getMetadata(getUrl(spec))
        console.log('got metadata for iq file', this.metadata)
    }
    if (this.metadata.size)
        return this.metadata.size
}