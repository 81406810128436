

export default {
    // "variables": {
    //     "color_prop": {
    //         "default": "wifiSignal"
    //     },
    //     "report_url": {
    //         "default": "./_flight.json"
    //     },
    //     "aps_url": {
    //         "default": [
    //             "./data/ap-telemetry-0.json"
    //         ]
    //     },
    //     "telemetry_url": {
    //         "default": "http://localhost/cestas-1000/telemetry.ndjson"
    //     }
    // },
    "variables": {
        "color_prop": {
            "default": "wifiSignal"
        },
        "report_url": {
            "default": "_flight.json"
        },
        "aps_url": {
            "default": [
                "./data/ap-telemetry-0.json"
            ]
        },
        "telemetry_url": {
            "default": "drone-telemetry.ndjson"
        }
    },
    name: "outdoor-show",
    canvas: '.descartes-canvas',
    defaults: {
    },
    controls: {
        cartes: true,
        hover: true,
        selection: true,
        global: true,
        player: true
    },
    details: 'view',
    details: 'objects',
    "actions": [
        "add_camera",
        "copy"
    ],
    scales: [
        {
            name: 'wifiAP',
            format: 'simple',
            // domain: [-2.0, 0.0]
        },
        {
            name: 'battery',
            domain: [0, 100],
            range: ["red", "green"]
        },
        {
            name: 'wifiSignal',
            format: 'simple',
            range: ["red", "green"],
            domain: [-90.0, -60.0]
        },
    ],
    selections: [
        {
            "name": "spares",
            "description": "Spares",
            "table": "spares",
            "path": ["id"]
            // "selection": [
            //     "ac3abf1e35b1eda8",
            //     "c3e25614a6ce7abe",
            //     "5f1131080644b6d3",
            //     "b1c64722aaeb27fa",
            //     "210a5355ac994231",
            //     "895a1a0ad162019",
            //     "d4d7ab758a9e8055",
            //     "411538b63e183db4",
            //     "71be91f0df41348",
            //     "51545e8b5e239e83",
            //     "43f0817aa55dc640",
            //     "5c397728362056b1",
            //     "1c5ef81ed092ce7a",
            //     "de6fbdb9ca0ed666",
            //     "afb71cdbc913682b",
            //     "b504c796948df247",
            //     "43e7b11435c2b09b",
            //     "c8c2fd05d0379fd1"
            // ]
        },
        {
            "name": "losses",
            "description": "Losses",
            "table": "losses",
            "path": ["id"],
            // table actors
            // filter d=>d.events.find(e=>e.severity >=400)
            // path: ['id']
            // "selection": [
            //     "4b0051ab4281c8be", // telemetry lost , interrupted
            //     "1647af8da26dcab4", // telemetry lost
            //     "33165aa5ffd0197c", // emergency
            //     "a5c5a98f57793103", // interrupted, telemetry lost
            //     "11aa785f228d3314", // telemetry lost
            //     "8bedd1c21beb73c", // telemetry lost, emergency
            //     "df325dc2410d8158", // telemetry lost
            //     "16c7120719e8e93e", // emergency
            //     "35b6eb68bb7ace9c", // mobile


            // ]
        },
    ],
    "identities": {
        "url": "http://localhost:8080/report-0.json"
        ,
        "transforms": [
            {
                type: "map",
                expr: "f=>f.drones"
            },
            {
                type: 'only',
                "properties": ['id', 'serial', 'name', 'loss']
            }
        ]
    },
    "dataset": [
        {
            "name": "actors",
            "url": "$report_url",
            "transforms": [
                {
                    "type": "extract",
                    "path": [
                        "drones"
                    ],
                    "keep": [
                        "start"
                    ]
                },
                {
                    "type": "ignore",
                    "fields": [
                        "alarms",
                        "states",
                        "extents",
                        "telemetryLinks",
                        "choreoStates"
                    ]
                },
                {
                    "type": "compute",
                    "_expr": "d=>Math.abs(new Date(d.bindHistory[0].start).getTime() - new Date(d.start).getTime()) > 6000",
                    "expr": "d=>d.events.filter(e=>e.severity >=200)",
                    "as": "events"
                },
                {
                    "type": "compute",
                    "expr": "d=>d.name === 'SPARE'",
                    "as": "isSpare"
                },
                {
                    "type": "compute",
                    "comment": "spares are interrupted : severity 310",
                    "expr": "d=>(d.flyingPeriods.length && !d.isSpare) ? (d.events.find(e=>e.severity >300) || {}).name : undefined",
                    "as": "loss"
                }
            ]
        },
        {
            "name": "spares",
            "from": "actors",
            "transforms": [
                {
                    "type": "filter",
                    "expr": "d=>d.bindHistory.length >1"
                }
            ]
        },
        {
            "name": "replaced",
            "from": "actors",
            "transforms": [
                {
                    "type": "filter",
                    "expr": "d=>d.bindHistory[0].end && d.bindHistory.length === 1"
                }
            ]
        },
        {
            "name": "losses",
            "from": "actors",
            "transforms": [
                {
                    "type": "filter",
                    "expr": "d=>d.loss"
                }
            ]
        },
        {
            "name": "telemetry",
            "url": {
                "__var": "telemetry_url"
            },
            "transforms": [
                {
                    "type": "filter",
                    "expr": "s=>Number.isFinite(s.latitude)"
                },
                {
                    "type": "compute",
                    "expr": "s=>s.guid",
                    "as": "id"
                },
                {
                    "type": "compute",
                    "expr": "s=>s.longitude",
                    "as": "x"
                },
                {
                    "type": "compute",
                    "expr": "s=>s.latitude",
                    "as": "y"
                },
                {
                    "type": "normalize",
                    "from": 1.5,
                    "field": "altitude",
                    "as": "z"
                },
                {
                    "type": "compute",
                    "expr": "d=>d.timestamp/1000",
                    "as": "t"
                },
                {
                    "type": "normalize",
                    "field": "t"
                },
                {
                    "type": "join",
                    "id": "guid",
                    "externalTable": "actors",
                    "externalId": "id",
                    "externalProps": [
                        "name",
                        "serial",
                        "isSpare",
                        "loss"
                    ]
                }
            ]
        },
        {
            "name": "access_points",
            "urls": {
                "__var": "aps_url"
            },
            "transforms": [
                {
                    "type": "filter",
                    "expr": "datum=> datum.status && (datum.radio_table || []).length && (datum.vap_table || []).length"
                },
                {
                    "type": "extract",
                    "path": [
                        "radio_table"
                    ],
                    "keep": true,
                    "as": [
                        "radio"
                    ]
                },
                {
                    "type": "extract",
                    "path": [
                        "vap_table"
                    ],
                    "keep": true,
                    "as": [
                        "vap"
                    ]
                },
                {
                    "type": "filter",
                    "expr": "datum=>datum.vap && (datum.vap.radio_name == datum.radio.name)"
                },
                {
                    "type": "compute",
                    "expr": "datum=>datum.vap.bssid",
                    "as": "bssid"
                },
                {
                    "type": "compute",
                    "expr": "datum=>datum.vap.essid",
                    "as": "essid"
                },
                {
                    "type": "compute",
                    "expr": "datum=>(datum.name || '')+'('+datum.vap.essid+')'+', channel '+datum.vap.channel+', power '+datum.vap.tx_power",
                    "as": "apLabel"
                },
                {
                    "type": "project",
                    "fields": [
                        "apLabel",
                        "bssid",
                        "essid"
                    ]
                }
            ]
        }
    ],
    cartes: [

        // {
        //     type: 'tiles'
        // },
        {
            type: 'stream',
            coordinates: 'gps',
            table: 'telemetry',
            identifier: 'guid',
            time: 't',
            segmentSize: 10 * 1024 * 1024,
            color: '$color_prop',
            colorScale: "$color_prop",
            shape: 'sphere',
            persistance: 2000000.0,
            memory: 0.0
        }
    ]
}