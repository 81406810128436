import { defaultGpsOrigin } from "../../defaults";

const DEG_TO_RAD = Math.PI / 180;
const RAD_TO_DEG = 180 / Math.PI;
export const EARTH_RADIUS = 6378137.0;

export interface GpsPoint {
  latitude: number;
  longitude: number;
  altitude?: number
}

export function pos2Geo([x, y, z], { latitude, longitude, altitude }: GpsPoint = defaultGpsOrigin) {
  const cos = Math.cos(latitude * DEG_TO_RAD);
  return [
    longitude + ((x / EARTH_RADIUS) * RAD_TO_DEG) / cos,
    latitude + (y / EARTH_RADIUS) * RAD_TO_DEG,
    (altitude || 0) + z,
  ];
}


export function geo2Pos([longitude, latitude, altitude], o) {
  const lat0 = o.latitude;
  const lon0 = o.longitude;
  const alt0 = o.altitude || 0;
  const cos = Math.cos(lat0 * DEG_TO_RAD);
  return [
    (longitude - lon0) * cos * EARTH_RADIUS * DEG_TO_RAD,
    (latitude - lat0) * EARTH_RADIUS * DEG_TO_RAD,
    altitude - alt0,
  ];
}

export function bbox(points = []) {
  if (!points.length) return;
  let s0 = points[0];
  let xs: [number, number] = [s0[0], s0[0]],
    ys: [number, number] = [s0[1], s0[1]],
    zs: [number, number] = [s0[2] || 0, s0[2] || 0];
  for (let s of points) {
    xs[0] = Math.min(xs[0], s[0]);
    xs[1] = Math.max(xs[1], s[0]);

    ys[0] = Math.min(ys[0], s[1]);
    ys[1] = Math.max(ys[1], s[1]);

    zs[0] = Math.min(zs[0], s[2]);
    zs[1] = Math.max(zs[1], s[2]);
  }

  return [xs, ys, zs];
}

const n = v => Number.isFinite(v)
const clone = v => JSON.parse(JSON.stringify(v))
export function alt(object) {
  const { altitude, z, position } = object
  if (n(altitude)) return altitude
  if (n(z)) return z
  if (n((position || [])[2])) return position[2]
  return 0;
}

export function mergeBboxes(bboxes: any[]) {
  const coordinates: any[] = []
  for (let bbox of bboxes) {
    coordinates.push(bbox[0])
    coordinates.push(bbox[1])
  }
  const xs = coordinates.map(c => c[0])
  const ys = coordinates.map(c => c[1])
  const merged = [
    [Math.min(...xs), Math.min(...ys)],
    [Math.max(...xs), Math.max(...ys)],
  ]
  return merged
}
