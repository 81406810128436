import { html, render } from "lit";
import { upload } from "web-ui-blocks/io";
import Descartes from "..";
import importFiles from "../controls/file-import";

let pinned = false;

function togglePin() {
  console.log("togglePin");
}

function computeCssTransform(viewState) {
  const { bearing, pitch } = viewState;
  return `transform: rotateX(${-(pitch || 0).toFixed(
    0
  )}deg) rotateZ(${-(bearing || 0).toFixed(0)}deg)`;
}
import { DrawInteraction, MeasureInteraction } from "../controls/interactions";
export default function renderGlobalControls(container, context, descartes: Descartes) {
  const { viewState, cameras } = context;
  const arrowStyle = computeCssTransform(viewState);
  const { maxPitch } = viewState;
  container.classList.add("descartes-controls");

  const interactionIsActive = (cls) => {
    if (descartes.interaction instanceof cls) return true;
  };
  const toggleInteraction = (cls, args) => {
    console.log("toggle", cls);
    if (interactionIsActive(cls)) descartes.setInteraction(null);
    else descartes.setInteraction(new [cls][0](args));
  };

  const renderControlForInteraction = (icon, cls, args = {}) => {
    return html`
      <span class="btn control ${interactionIsActive(cls) ? " active" : "" }" @click="${() => toggleInteraction(cls, args)}">
        <i class="fas ${icon}"></i>
      </span>
    `;
  };
  const renderingHelpers = true;
  render(
    html`
    ${(descartes.config.controls.additionals || []).map(
    (control) =>
    html`<span class="btn control" @click="${() => control.action(descartes)}"><i class="fas fa-cogs"></i></span>`
    )}
    
    
    <button class=" control" @click=${e=> descartes.reset(null)}><i class="fas fa-solid fa-reply"></i></button>
    
    ${false ? html`<span class="btn control ${renderingHelpers ? " active" : "" }" @click="${() =>
            descartes.update({
              helpers: !renderingHelpers,
            })}"><i class="fas fa-border-all"></i></span>` : ''}
    ${false ? renderControlForInteraction("fa-ruler-horizontal", MeasureInteraction) : ''}
    ${false ? renderControlForInteraction("fa-draw-polygon", DrawInteraction) : ''}
    <button @click="${() =>
            descartes.updateView({ pitch: 0, bearing: 0 })}">
      <i style="${arrowStyle}" class="fas fa-long-arrow-alt-up"></i>
    </button>
    <button @click="${() => {
            descartes.fit()
          }}"><i class="far fa-square"></i></button>
    <button @click="${() =>
            descartes.updateView({
              pitch: 0,
              bearing: 0,
              maxPitch: maxPitch === 0 ? 90 : 0,
            })}">${maxPitch === 0 ? "2D" : "3D"}</i></button>
    <!-- <span class="btn" @click="${() =>
            togglePin()}"><i class="fas fa-thumbtack"></i></span> -->
    <button class=" control" @click=${e=> descartes.container.requestFullscreen()}><i class="fas fa-tv"></i></button>
    ${cameras.length > 1 ? html`<select @input="${e=>{
      const id = e.target.value
      descartes.update({currentCamera: id})
      }}">
      ${cameras.map(({ id }) => html`<option value="${id}">${id}</option>`)}
    </select>`: ''}
    
    `,
    container
  );
}
