import { BitmapLayer } from '@deck.gl/layers';
import { TileLayer } from "@deck.gl/geo-layers";
import Carte from '.';
import { map } from 'lodash';

export default class TilesCarte implements Carte {
  config: any;
  getLayers() {
    return [
      // "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",
      // 'https://api.maptiler.com/tiles/satellite-mediumres/{z}/{x}/{y}.jpg?key=iS9gs1LsLOJfuF3dQvLd',
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      // 'https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=eIgS48TpQ70m77qKYrsx',
    ].map((data, index) => new TileLayer({
      id: this.config.name + `-${index}-tiles`,
      // https://wiki.openstreetmap.org/wiki/Slippy_map_tilenames#Tile_servers

      data,
      minZoom: 0,
      maxZoom: 19, //19 ,
      tileSize: 256,

      renderSubLayers: (props) => {
        const {
          bbox: { west, south, east, north },
        } = props.tile;

        return new BitmapLayer(props, {
          data: null,
          opacity: 1,
          image: props.data,
          bounds: [west, south, east, north],
          // parameters: { 
          //   depthMask: true, 
          //   depthTest: true, 
          //   blend: true, 
          //   blendFunc: [GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA, GL.ONE, GL.ONE_MINUS_SRC_ALPHA], 
          //   polygonOffsetFill: true, 
          //   depthFunc: GL.LEQUAL, 
          //   blendEquation: GL.FUNC_ADD 
          // }, 
        });
      },
    }))
  }
}
