// TODO : get header line
// TODO : get stream from response
// TODO : handle contiguous chunks (don't throw first line) : lineStarts dict
import * as Arrow from 'apache-arrow'


import { getMetadata, loadRange, localFetch } from "../../http-utils"
import { DataChunk } from "../chunk"
const getUrl = (spec) => spec.csv || spec.url || spec.file



const HEADER_LINE = true
const SEPARATOR = ","

async function getTable(spec) {
    const { url } = spec
    const size = await getSize.bind(this)(spec)
    const response = await localFetch(url)
    const ab = await response.arrayBuffer()
    let table = await Arrow.tableFromIPC(ab)
    return table
}

export async function getChunkAt(spec, offset, size): Promise<DataChunk> {
    {
        const { url } = spec
        size = await getSize.bind(this)(spec)
        const response = await localFetch(url)
        const tFirstByte = Date.now()
        const ab = await response.arrayBuffer()
        let table = await Arrow.tableFromIPC(ab)
        console.log('arrow response', ab)
        console.log('arrow table', table)
        this.schema = table.schema
        const json = table.toArray()//.map(s => s ? s.toJSON() : null) // should avoid it if possible
        return { samples: () => json, offset: 0, size, tFirstByte, tLoaded: Date.now(), nrows: table.numRows }
    }
}

export async function getSchema(spec) {
    if (!this.table)
        this.table = getTable(spec)
    const table = await this.table
    console.log('getting schema from arrow')
    console.log(table.schema)
    const { fields, dictionaries, metadata } = table.schema
    let schema = {}
    for (let { name, type, nullable, metadata } of fields) {
        schema[name] = { type: 'DOUBLE', optional: nullable }
        if (type.dictionary)
            schema[name].type = 'UTF8'
    }
    return schema
}

export async function getSize(spec) {
    if (!this.metadata) {
        this.metadata = await getMetadata(spec)
    }
    if (this.metadata.size)
        return this.metadata.size
    if (!this.metadata.size) {
        this.ab = await localFetch(getUrl(spec)).then(r => r.arrayBuffer())
    }
    if (this.ab)
        return this.ab.byteLength
}