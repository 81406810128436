import MapVizu from ".";
import { ScenegraphLayer } from "@deck.gl/mesh-layers/src/index";
import { COORDINATE_SYSTEM } from "@deck.gl/core";

import { getRegisteredLoaders } from '@loaders.gl/core/src/lib/api/register-loaders'
import { registerLoaders } from "@loaders.gl/core";
import { GLTFLoader } from "@loaders.gl/gltf";
import { OBJLoader } from '@loaders.gl/obj';

import { load } from "@loaders.gl/core";
// Register the proper loader for scenegraph.gltf
registerLoaders([GLTFLoader, OBJLoader]);

// import sampleSceneBin from "../../assets/models/UFC_FIGHT_ISLAND_STAGE_VENUE_GEO_11_01_2021.bin";
import { timeThursday } from "d3";
import Carte from ".";
import { SimpleMeshLayer } from "deck.gl";
import { getCoordinates } from "./common/coordinates";
import { resolveUrl } from "web-ui-blocks/simple-tool/src";

const data = [{}];
export default class EnvironmentCarte implements Carte {
  config: any;
  layer: any
  mesh: any

  async init() {
    const url = this.config.url
    this.mesh = await load(url)
  }


  getLayers() {
    if (!this.config.url) return;
    // if (this.layer) return [this.layer];

    let getColor = this.config.color || [0, 255, 0, 20]
    if (getColor.length < 4)
      getColor.push(20)
    const l = new SimpleMeshLayer({
      id: this.config.name,
      ...getCoordinates(this.config),

      data,
      mesh: this.mesh,
      //"https://raw.githubusercontent.com/KhronosGroup/glTF-Sample-Models/master/2.0/BoxAnimated/glTF-Binary/BoxAnimated.glb",
      //   loadOptions: GLTFLoader,
      getPosition: [0.0, 0.0, 0.0],
      getOrientation: [0, 0, 0],
      getColor,

      pickable: false,
      parameters: {
        depthMask: false
      },
      _lighting: "pbr",
    });
    this.layer = l;
    return [l];
  }

  getBbox() {
  }
}
