// import 'babel-polyfill';
console.log("global")
import Descartes from ".."


// ID (uwb-choreo) : 10KwE9e2xdw8EDy4bITL0mf47U7MrDoWH
// http://localhost:1234/?state=%7B%22ids%22:%5B%2210KwE9e2xdw8EDy4bITL0mf47U7MrDoWH%22%5D,%22action%22:%22open%22,%22userId%22:%22111416900778206777946%22,%22resourceKeys%22:%7B%7D%7D
import "web-ui-blocks/overlay/lib";
import "web-ui-blocks/drop-zone/index";
import { init } from 'web-ui-blocks/notifications'

import "web-ui-blocks/data-pipe/runtime/front"
init(document.body, {
    duration: 1000000 // debug 
}, { minLevel: 'debug' })


import choreoConfig from './configs/choreo-config'
import theatreConfig from './configs/theatre-config'
// import geophotosConfig from './configs/geophotos-config'

import uwbFlightConfig from './configs/uwb-flight'
import uwbBlackboxConfig from './configs/uwb-blackbox-flight'
// import gpsFlightConfig from './configs/gps-telemetry-config'
import gpsFlightConfig from './configs/gps-flight'

import createSimpleTool from 'web-ui-blocks/simple-tool/src'
let w = window;

import schema from "../descartes.schema.json";
import { specialize } from "web-ui-blocks/simple-tool/src/variables";
import dccConfig from "./configs/dcc-config";
(async function launch() {
    const updateConfig = await createSimpleTool({
        schema,
        sources: ['url'],
        async setup(newConfig, source) {
            const config = specialize(newConfig, newConfig.variables)
            console.log('setup ', config)
            if (w.descartes)
                await w.descartes.reset(config);
            else
                w.descartes = new Descartes(document.body, config);
            await w.descartes.ready
        },
        builtins: [
            gpsFlightConfig,
            choreoConfig,
            uwbFlightConfig,
            dccConfig,
            // await geophotosConfig,
            uwbBlackboxConfig,
            // theatreConfig,
            // gpsTelemetryConfig,
        ]
    }).catch((err) => {
        console.log('failed to create simple tool : ' + err.toString())
        throw err
    })
})()
