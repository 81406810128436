import { checkResponse, getMetadata, localFetch } from '../../http-utils'
import * as json from './json'
const getUrl = (spec) => spec.api || spec.url || spec.file

async function loadJson(spec, init?) {
    const url = getUrl(spec)
    const response = await localFetch(url, init)
    let tFirstByte = Date.now()
    checkResponse(response)
    const samples = await response.json()
    let tLoaded = Date.now()
    return { samples, tFirstByte, tLoaded }
}

export default function (spec, dataset) {

    async function pull() {
        return loadJson(spec, spec.init)
    }

    let pullPromise = null

    // infere response format
    async function getChunkAt(offset, size) {
        console.log('getting api chunk', ...arguments)
        if (!pullPromise) {
            pullPromise = pull()
        }
        const chunkPart = await pullPromise
        const { samples } = chunkPart
        const nrows = samples.length
        return { ...chunkPart, samples: () => samples, offset: 0, size: nrows, nrows }
    }
    async function getSize() {
        if (!pullPromise) {
            pullPromise = pull()
        }
        const { samples } = await pullPromise
        return samples.length
    }
    return {
        getChunkAt,
        getSize,
    }

}
