import {
  LineLayer,
  TextLayer,
  PolygonLayer,
  PointCloudLayer,
} from "@deck.gl/layers";
import { COORDINATE_SYSTEM } from "@deck.gl/core";
import { geo2Pos } from "../../utils/geography";

// config : ground distance,
// should measure spherical distance
class MeasureInteraction {
  constructor(args) {
    this.origin = null;
    this.points = [];
  }

  handleEvent(e, descartes) {
    if (!this.origin) this.origin = e.coordinates;
    const gpsOrigin = {
      longitude: this.origin[0],
      latitude: this.origin[1],
      altitude: this.origin[2],
    };
    const point = geo2Pos(e.coordinates, gpsOrigin);
    this.points = [...this.points, point];
  }

  getLayers() {
    if (!this.origin) return [];
    const lineLayer = new LineLayer({
      id: "measure-line",
      coordinateSystem: COORDINATE_SYSTEM.METER_OFFSETS,
      coordinateOrigin: this.origin, // anchor point in longitude/latitude/altitude
      data: this.points
        .map((c, i) => ({
          to: c,
          index: i,
          from: this.points[i - 1],
        }))
        .filter((line) => line.from && line.to),
      pickable: false,
      getWidth: 3,
      getSourcePosition: (d) => d.from,
      getTargetPosition: (d) => d.to,
      getColor: (d) => [100, 50, 0],
    });

    let cumulatedDistance = 0;
    const textLayer = new TextLayer({
      id: "measure-text",
      coordinateSystem: COORDINATE_SYSTEM.METER_OFFSETS,
      coordinateOrigin: this.origin, // anchor point in longitude/latitude/altitude
      data: this.points.map((c, index) => {
        if (index > 0) {
          let d2 = 0;
          for (let i = 0; i < 3; i++) {
            const dx = c[i] - this.points[index - 1][i];
            d2 += dx * dx;
          }
          cumulatedDistance += Math.sqrt(d2);
        }
        return { text: `${cumulatedDistance.toFixed(1)}m`, position: c };
      }),
      getPosition: (d) => d.position,
      getText: (d) => d.text,
      getSize: 32,
      getAngle: 0,
      getTextAnchor: "middle",
      getAlignmentBaseline: "center",
      getColor: (d) => [200, 100, 0],
    });
    return [lineLayer, textLayer];
  }
}

// config : shape to draw ? color, stroke color ?
class DrawInteraction {
  constructor(args) {
    this.points = [];
  }
  handleEvent(e, descartes) {
    this.points = [...this.points, e.coordinates];
  }
  getLayers() {
    const layers = [];
    if (this.points.length >= 3) {
      const points = [...this.points, this.points[0]];
      const polygon = new PolygonLayer({
        id: "draw-polygon",
        data: [points],
        filled: true,
        getPolygon: (pts) => pts,
        getFillColor: (d) => [250, 150, 150, 50],
      });
      layers.push(polygon);
    }
    const lines = this.points
      .map((point, index) => {
        return {
          from: this.points[index - 1],
          to: point,
        };
      })
      .filter((l) => l.from && l.to);
    const line = new LineLayer({
      id: "draw-line",
      data: lines,
      pickable: false,
      getWidth: 3,
      getSourcePosition: (d) => d.from,
      getTargetPosition: (d) => d.to,
      getColor: (d) => [100, 50, 0],
    });
    layers.push(line);

    layers.push(
      new PointCloudLayer({
        id: "draw-points",
        data: this.points,
        radiusPixels: 10,
        getPosition: (d) => d,
        // getNormal: (d) => [0, 0, 1],
        getColor: (d) => [255, 0, 0],
      })
    );
    return layers;
  }
}

export { MeasureInteraction, DrawInteraction };
