import Carte from ".";
import { SimpleMeshLayer, ScenegraphLayer } from "@deck.gl/mesh-layers";
import { defaultGpsOrigin } from "../defaults";
import { COORDINATE_SYSTEM } from "deck.gl";
import './common/loaders'

const defaultConfig = {
    roll: 0,
    pitch: 180,
    yaw: 90
}

export default class GltfCarte implements Carte {
    config
    scenegraphLayer
    getLayers(ctx: any, lastCtx: any, lastLayers: any) {
        const { url, x, y, z, roll, pitch, yaw, name, scale, color } = { ...defaultConfig, ...this.config } as any
        const { longitude, latitude, altitude } = this.config.gpsOrigin || defaultGpsOrigin
        if (!this.scenegraphLayer) {
            const layerConfig: any = {
                id: name,
                scenegraph: url || 'https://raw.githubusercontent.com/KhronosGroup/glTF-Sample-Models/master/2.0/BoxAnimated/glTF-Binary/BoxAnimated.glb',//url,
                data: [{}],
                coordinateSystem: COORDINATE_SYSTEM.METER_OFFSETS,
                coordinateOrigin: [longitude, latitude, altitude || 0],
                getPosition: [x || 0, y || 0, z || 0],//[longitude, latitude], //position || [0, 0, 0],
                getOrientation: [roll, pitch, yaw],
                getColot: [100, 100, 100],
                //     pitch: 180,
                //     yaw: 90,

                pickable: false,
                sizeScale: scale || 1,
                // sizeUnits: "meters",
                // _animations: {
                //     '*': { speed: 5 }
                // },
                _lighting: "pbr"
            }
            if (color)
                layerConfig.getColor = color
            this.scenegraphLayer = new ScenegraphLayer(layerConfig)
        }

        const layers = [
            // new ScenegraphLayer({
            //     id: 'scenegraph-layer',
            //     data: [{ coordinates: [longitude, latitude] }],
            //     pickable: true,
            //     scenegraph: 'https://raw.githubusercontent.com/KhronosGroup/glTF-Sample-Models/master/2.0/BoxAnimated/glTF-Binary/BoxAnimated.glb',
            //     getPosition: [longitude, latitude],
            //     getOrientation: d => [0, 0, 90],

            //     sizeScale: 1,
            //     _lighting: 'pbr'
            // }
            this.scenegraphLayer,
        ]
        return layers
    }
    getRanges() {
        const position = this.config.position || [0, 0, 0]
        const [x, y, z] = position
        const diameter = 10
        const ranges = {
            x, y, z,
        }
        const radius = diameter / 2
        for (const key in ranges) {
            const mid = ranges[key]
            ranges[key] = [mid - radius, mid + radius]
        }
        return ranges
    }
}