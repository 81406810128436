import { forwardFft } from "./fft"

export default {
    name: 'ofdm',
    apply(samples, tr, datasets) {
        const defaultConfig = {
            header: 0,
            size: 64,
            gi: 16,
            pilots: [],
            data: [-20, -19, -18, -17]
        }
        let { header, size, gi, pilots, data } = { ...defaultConfig, ...tr } as any
        const symbolLength = gi + size
        if (!this.samples) {
            this.samples = []
            this.consumed = 0
        }

        let result = []
        const flush = () => {
            const symbol = this.samples.slice(0, symbolLength)
            const guardInterval = symbol.slice(0, gi)
            const dataSamples = symbol.slice(gi)
            // compute fft from dataSamples
            const spectrum = forwardFft(dataSamples)
            console.log('ofdm symbol')
            console.log('check guard interval', guardInterval, dataSamples.slice(dataSamples.length - gi))
            console.log('ofdm spectrum', spectrum)
            // const sample = {
            //     t: accu_t,
            //     n: Math.sqrt(accu_i * accu_i + accu_q * accu_q),
            //     index: this.nextIndex++
            // }
            const dataSpectrum = data.map(idx => spectrum[(idx + size) % size])
            result = [...result, ...dataSpectrum]
            this.samples = this.samples.slice(symbolLength)
        }
        this.samples = [...this.samples, ...samples]
        while (this.consumed < header) {
            this.samples.shift()
            this.consumed++
        }
        while (this.samples.length >= symbolLength) {
            flush()
        }
        return result//.filter(s=>s.db > -300)
    },
    schema: {
        header: {
            type: 'number'
        },
        size: {
            type: 'number'
        },
        gi: {
            type: 'number'
        },
        pilots: { type: 'array', items: 'number' },
        data: { type: 'array', items: 'number' }
    }
}