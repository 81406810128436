import MapVizu from ".";
import { ScenegraphLayer } from "@deck.gl/mesh-layers/src/index";
import { COORDINATE_SYSTEM } from "@deck.gl/core";

import { getRegisteredLoaders } from '@loaders.gl/core/src/lib/api/register-loaders'
import { registerLoaders } from "@loaders.gl/core";
import { GLTFLoader } from "@loaders.gl/gltf";
import { OBJLoader } from '@loaders.gl/obj';

import { load } from "@loaders.gl/core";
// Register the proper loader for scenegraph.gltf
registerLoaders([GLTFLoader, OBJLoader]);

// import sampleSceneBin from "../../assets/models/UFC_FIGHT_ISLAND_STAGE_VENUE_GEO_11_01_2021.bin";
import { timeThursday } from "d3";
import Carte from ".";
import { PathLayer, SimpleMeshLayer, LineLayer } from "deck.gl";
import { getCoordinates } from "./common/coordinates";
import { loadSamples } from "web-ui-blocks/data-pipe";
import { get } from "object-path-immutable";
import { updateScale } from "../scale-manager";
import { computeDiscreteRange, computeNumericRange } from "../utils/data";
import { TerrainLayer } from "@deck.gl/geo-layers";

const data = [{}];
export default class TerrainCarte implements Carte {
    config: any;
    layer: any
    ranges: any
    trajectories: any;

    async init() {
    }


    getLayers() {
        if (this.layer)
            return [this.layer]
        // if (!this.config.url) return;
        // if (this.layer) return [this.layer];
        const url = this.config.url
        let getColor = this.config.color || [100, 100, 100, 20]
        if (getColor.length < 4)
            getColor.push(20)
        const l = new TerrainLayer({
            ...getCoordinates(this.config),
            elevationDecoder: {
                rScaler: 0.5,
                gScaler: -0.01,
                bScaler: -0.5,
                offset: 10
            },
            meshMaxError: 0.01,
            // Digital elevation model from https://www.usgs.gov/
            elevationData: url,
            texture: url,// 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/terrain-mask.png',
            bounds: [-200, -250, 200, 250],
        });
        this.layer = l;
        return [l];
    }

    getBbox() {
    }
}
