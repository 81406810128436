import './init'

import createBoxSelect from "./controls/box-select";
import { get, set } from "object-path-immutable";
import Player from './player'
import createPlayerView from './player/view'

import './style.css'
import './cartes/style.css';
import './controls/controls.css';
// const elt = dosument.querySelector('#app')

const defaultConfig = {
  canvas: '.descartes-canvas',
  background: 'darkgrey'
  // withTooltip: true,
};



import { EventEmitter } from "events";
import { Deck, OrthographicView, OrbitView, MapView, WebMercatorViewport } from "@deck.gl/core";
import { mergeBboxes, pos2Geo } from "./utils/geography";



import CarteManager from "./carte-manager";
import handleKeyboardEvent from "./controls/keyboard";
import { handleClickEvent, handleHoverEvent, handleLeftClick } from "./controls/mouse";
import { fillCoordinates, fitViewToData, getLookAtLatLon, lightingEffect } from "./utils/3d";
import updateSelection from "./selection";
import { defaultGpsOrigin, defaultInitialViewState } from "./defaults";
import renderCarteWidget from "./carte-controls";
import { getInfoObjectId, getObjectId, uniteRanges } from "./utils/data";
import renderHoveredState, { renderDebug } from "./components/hovered-state";
import renderCurrent from "./components/current";
import renderGlobalControls from "./components/global-controls";
import importFiles from "./controls/file-import";
import { DescartesConfiguration } from './descartes'
import { FirstPersonView } from 'deck.gl';
import { Camera, createCameraView } from './camera';
import * as scale from './utils/dataviz'
import renderTimeline from './timeline';
import DataSet from 'web-ui-blocks/data-pipe/dataset';
import { Action } from 'web-ui-blocks/manipulator/action';
import createDataset from 'web-ui-blocks/data-pipe';
import { until } from 'lit/directives/until';
import { html } from 'd3';
import { renderLegend } from './utils/dataviz/legend';
import { BUILTIN_ACTIONS } from './actions';

let dnum = 1;



export type DescartesContext = {
  time: number,
  viewState: any,
  selection: string[],
  isSelected: { [id: string]: boolean }
  knownSelections: { selection: string[], name: string, description?: string }[],
  currentCamera: null | string
  cameras: Camera[],
  variables: any,
  hover?: {
    coordinates: [number, number, number],
    gpsCoordinates: [number, number, number],
    pixelCoordinates: [number, number, number],
    object: any
  },
  lookAt?: string,
  carte?: string,
}


export default class Descartes extends EventEmitter {
  id: string;
  config: any;
  destroyed?: boolean
  container: Element;
  canvas: HTMLCanvasElement;
  deck: any;

  carteManagers: CarteManager[] = [];
  renderings: any[] = [];

  actions: Action<any>[] = []
  context: DescartesContext;
  interaction: any;
  player: any;
  timeline: any;
  dataset: DataSet

  handleKeyDown: any;
  ready: Promise<void>

  constructor(container, config: DescartesConfiguration) {
    super();
    (window as any)._descartes = this;
    this.id = (dnum++).toString();
    config = { ...defaultConfig, ...config }
    let elt = config.canvas ? container.querySelector(config.canvas) : document.createElement("canvas");
    if (!elt) {
      throw "unable to get canvas"
    }
    elt.classList.add("descartes-canvas");
    container.classList.add("descartes-container");
    container.appendChild(elt);
    this.canvas = elt;
    this.container = container;

    this.canvas.addEventListener('contextmenu', e => handleLeftClick(e, this), true)
    createBoxSelect(this.canvas, {}, async (box, { alt, ctrl }) => {
      const t0 = Date.now()
      const times = []
      const tick = () => times.push(Date.now() - t0)
      const picked = {}

      const fastMatches = this.deck.pickObjects(box);
      tick()

      console.log('fast matches', box, fastMatches)
      for (const m of fastMatches) {
        const id = await getInfoObjectId(m, this)
        console.log(id)
        if (!id)
          continue
        picked[id] = true
        if (this.config.deepPick) {
          const { x, y } = m
          const deepMatches = this.deck.pickMultipleObjects({
            x,
            y,
            radius: 0,
            depth: 1000,
          })
          console.log(deepMatches)
          for (const info of deepMatches) {
            const id = await getInfoObjectId(info, this)
            picked[id] = true
          }
        }
      }
      tick()
      console.log('times', times)
      this.select(Object.keys(picked), { add: ctrl, del: alt });
    });

    for (let e of ['dragenter', 'dragover', 'dragleave'])
      elt.addEventListener(e, (evt) => {
        evt.preventDefault()
      })
    elt.addEventListener('drop', async (ev) => {
      ev.preventDefault()
      const { files } = ev.dataTransfer
      await importFiles(files, this)
    })
    this.handleKeyDown = (e) => handleKeyboardEvent(e, this)
    document.addEventListener("keydown", this.handleKeyDown);
    this.ready = this.reset(config);
    this.loop()


    // .then(() => this.fit());
  }

  async reset(newConfig) {
    if (newConfig) {
      this.config = { ...defaultConfig, ...newConfig };
    }
    if (this.deck) {
      this.deck.finalize()
    }
    for (let cm of this.carteManagers) {
      cm.release()
    }
    // TODO : resetScales()
    this.timeline = null
    this.carteManagers = []

    const inWorker = true
    this.dataset = await createDataset(this.config.dataset, inWorker)

    const target =
      get(this.config, ["view", "target"]) ||
      get(this.config, ["defaults", "gpsOrigin"]) || defaultGpsOrigin;
    const initialViewState = {
      maxPitch: 90,
      ...target,
      ...get(this.config, ['view', 'initialState'], defaultInitialViewState),


      //
      // "longitude": -0.7098589083338277, "latitude": 44.68187446184392,
      // "altitude": 10,
      // position: [0, 0, 10],
      // pitch: -30,
      // bearing: 0,
      // maxPitch: 180,
      // minPitch: -180,
    };
    // delete initialViewState.altitude;

    const knownSelections = await Promise.all((this.config.selections || []).map(((s) => {
      let { name, description, selection, url, table, filter, path } = s
      // todo : fetch url
      return { name, description, selection, filter, path, table }
    })))

    this.context = {
      time: 0,
      selection: [],
      isSelected: {},
      viewState: {
        ...initialViewState,
        // target: [target.longitude, target.latitude, target.altitude || 0], 
        rotationX: 90, rotationOrbit: 0
      },
      variables: {
      },
      knownSelections,
      cameras: [
        {
          id: 'default',
          type: 'Map',
          parameters: {
            fit: true
          },
          state: {
            ...initialViewState,
            rotationX: 90, rotationOrbit: 0
          }
        },
        {

          id: 'public1',
          type: 'FirstPerson',
          parameters: {},
          state: {
            // ...initialViewState,
            "longitude": -0.7098951006281433, "latitude": 44.68192537669416,
            position: [0, 0, 10],
            pitch: -20,
            bearing: -180,
            maxPitch: 180,
            minPitch: -180,
          }
        }
      ],
      currentCamera: 'default'
    };

    this.deck = new Deck({
      id: "descartes-" + this.id,
      canvas: this.canvas,
      views: createCameraView(this.context.cameras.filter(c => c.id === this.context.currentCamera)[0]),
      effects: [lightingEffect],

      initialViewState: this.context.viewState,
      layers: [],
      useDevicePixels: false,
      _animate: true,
      pickingRadius: 10,
      parameters: {
        COLOR_CLEAR_VALUE: [0, 0, 0, 255]
      },
      style: {
        'background-color': newConfig.background
      },
      getTooltip: (info) => {
        const { layer, index, object, x, y, coordinate } = info;
        if (this.config.withTooltip && object && layer) {
          // and layer.withTooltip
          return { html: (getInfoObjectId(info, this)) };
          // return { html: `<h1>drone</h1><em>${object.id}</em>` };
        }
      },
      onHover: (info, event) => handleHoverEvent(info, event, this),
      onClick: (info, event) => handleClickEvent(info, event, this),
      onViewStateChange: ({
        interactionState,
        viewId,
        viewState,
        oldViewState,
      }) => {
        this.context = set(this.context, ["viewState"], viewState)
        // this.update({ ...this.context, ...interactionState, viewState });
        // this.deck.setProps({ viewState });
      },
    });

    for (let s of (this.config.scales || [])) {
      scale.declare(s.name, s)
    }

    const promises: Promise<any>[] = [];

    for (let carte of this.config.cartes || []) {
      const { name, type, ...others } = carte;
      promises.push(
        this.addCarte(name, carte)
      );
    }
    if (this.config.actions) {
      let { actions } = this.config
      if (!Array.isArray(actions)) {
        actions = [actions]
      }
      for (let action of actions) {
        console.log('loading actions from ', action)
        try {
          const builtin = BUILTIN_ACTIONS[action]
          if (builtin)
            this.actions = [...this.actions, builtin]
          else {
            import(action).then((m) => {
              console.log('got actions ', m)
              this.actions = [...this.actions, ...m.default]
            })
          }
        } catch (err) {
          console.error(err)
        }
      }


    }

    await Promise.all(promises).then(() => {
      console.log("all cartes initialized");
    }).then(() => {
      const ranges: any = uniteRanges(this.carteManagers.map(cm => cm.getRanges()))
      console.log(ranges)
      const playerNeeded = ranges.t;
      const { timeline } = this.config
      // D
      if (playerNeeded) {
        if (this.player) {

        }
        console.log('ranges', ranges)
        this.player = new Player({ ...(timeline || {}), relative: true, start: ranges.t[0] * 1000, end: ranges.t[1] * 1000 });
        const updatePlayerView = createPlayerView(this.player, this.container.querySelector("#player"))
        // this.renderings.push()
        this.player.on('tick', (t) => {
        })
      }
      this.fit()
      this.render()
      console.log("descartes initialized");
    });
  }
  clean() {
    this.destroyed = true
    if (this.deck) {
      this.deck.finalize()
    }
    for (let cm of this.carteManagers) {
      cm.release()
    }
    this.canvas.remove();
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  select(ids, opts: any = {}) {
    const selection = updateSelection(this.context.selection, ids.filter(Boolean).map(id => id.toString()), opts);
    this.update({ selection });
    const { handleSelection } = this.config;
    if (handleSelection) handleSelection(ids);
  }

  update(changes) {
    if (!this.context || this.destroyed)
      return;
    let oldCtx = this.context
    let newCtx = oldCtx
    for (const k in changes) {
      if (changes[k] !== newCtx[k]) {
        newCtx = { ...newCtx, [k]: changes[k] }
        const v = changes[k]
        if (k === 'viewState') {
          console.log('update initialViewState', v)
          this.deck.setProps({ initialViewState: oldCtx.viewState })
          this.deck.setProps({ initialViewState: v })
        }
        if (k === 'currentCamera') {
          console.log('update currentCamera', v)
          const camera = this.context.cameras.filter(c => c.id === changes[k])[0]

          this.deck.setProps({ views: createCameraView(camera), initialViewState: { ...camera.state } })
          if (camera.parameters.fit) {
            this.fit()
          }
          this.render()
        }
        if (k === 'selection') {
          newCtx.isSelected = {}
          for (let i of v)
            newCtx.isSelected[i] = true
        }
      }
    }
    this.context = newCtx;
  }

  updateView(update) {
    this.update({ viewState: { ...this.context.viewState, ...update } });
  }
  setInteraction(interaction) {
    this.interaction = interaction;
  }

  renderCarteWidgets() {

    const carteWidgetsElement = this.container.querySelector(".carte-widgets");
    if (carteWidgetsElement) {
      if (!this.config.controls.cartes) {
        carteWidgetsElement.innerHTML = ''
        return
      }
      const children = carteWidgetsElement.childNodes
      const perId = {}
      for (let c of children) {
        perId[(c as any).id] = c
      }
      for (const cm of this.carteManagers) {
        let id = "carte-" + cm.name;
        let container = perId[id]
        if (!container) {
          container = document.createElement("div");
          container.id = id;
          container.classList.add("carte-controls");
          carteWidgetsElement.appendChild(container);
        }
        if (container) {
          const state = cm.getState()
          const cls = container.classList
          if (state.active)
            cls.remove('disabled')
          else
            cls.add('disabled')

          renderCarteWidget(container, cm, this);
        }
        delete perId[id]
      }
      for (let id in perId) {
        // TODO : use lit-html
        perId[id].remove()
      }
    }
  }

  async addCarte(name, config: any = {}) {
    if (!name) name = config.type;
    if (!name) throw "a vizu must have a name: " + JSON.stringify(config);
    const matches = this.carteManagers.filter((v) => v.name === name);
    if (matches.length) throw `carte ${name} already exists`;
    let fullConfig = { ...(this.config.defaults || {}), ...config, name, };
    const cm = new CarteManager(fullConfig, this.dataset)
    this.carteManagers.push(cm);
    if (cm.initialized) await cm.initialized;
    return cm;
  }
  async loop() {
    if (this.destroyed)
      return;
    const time = this.player ? this.player.time() / 1000 : null;
    this.update({ time })
    const ti = Date.now()
    await this.render()
    // console.log("render took", (Date.now() - ti) + 'ms')
    requestAnimationFrame(this.loop.bind(this))
  }
  switchCamera() {
    let currentIndex = this.context.cameras.map(c => c.id).indexOf(this.context.currentCamera || "")
    if (currentIndex >= 0)
      currentIndex = (currentIndex + 1) % this.context.cameras.length
    else
      currentIndex = 0
    this.update({ currentCamera: this.context.cameras.filter((c, i) => i === currentIndex)[0].id })
  }
  async render() {
    const { context } = this
    if (!context)
      return
    const target = await getLookAtLatLon(this.carteManagers, this.context);
    if (target) {
      if (target.altitude < 1.0)
        target.altitude = 1.0
      // target.altitude = 2.0

      this.updateView({
        ...target,
        // target: [target.longitude, target.latitude, target.altitude * 1e-5] 
      });
    }

    let layers: any[] = [
    ];

    for (let v of this.carteManagers) {
      const { loaded, active, error } = v.getState();
      if (loaded && active && !error) {
        try {
          layers = [...layers, ...(await v.getLayers(this.context))];
        } catch (err) {
          console.error('failed to get layers for ' + v.name + ' : ' + err.toString())
        }
      }
    }

    for (let { name } of this.config.scales || []) {
      const state = await scale.state(name)
      if (state)
        renderLegend(state, document.body)
      // console.log(state)
    }
    if (this.interaction && this.interaction.getLayers)
      layers = [...layers, this.interaction.getLayers(this.context)];
    this.renderCarteWidgets();

    renderTimeline(this, this.config.timeline)



    this.deck.setProps({ layers });
    if (this.config.controls) {
      const controls = this.config.controls;
      const controlsElement = this.container.querySelector("#controls");
      if (controlsElement) renderGlobalControls(controlsElement, context, this);
    }
    const hoveredStateElement = this.container.querySelector(".hovered-state")
    if (hoveredStateElement) {
      // renderHoveredState(hoveredStateElement, context.hover, this);
    }
    const detailsElement = this.container.querySelector(".details")
    if (detailsElement) {
      renderCurrent(detailsElement, context, this);
    }
    for (let r of this.renderings)
      r(context);
  }
  // WARNING : ne marche pas si il y a des angles dans le viewState : utiliser makeViewport ?
  getViewport() {
    const w = this.container.clientWidth;
    const h = this.container.clientHeight;
    const viewport = new WebMercatorViewport({
      ...this.context.viewState,
      width: w,
      height: h,
    });
    return viewport;
  }
  fit(vizus: null | string[] = null) {
    try {
      if (!vizus) vizus = this.carteManagers.filter(c => c.getState().active).map((v) => v.name);
      if (typeof vizus === "string") vizus = [vizus];
      let viewState

      const bboxes = this.carteManagers
        .map((v) => ((vizus || []).includes(v.name) ? v.getState().bbox : null))
        .filter((b) => b);
      if (!bboxes.length) {
        console.log("unable to get bbox for", vizus);
        return;
      }
      const bbox = mergeBboxes(bboxes);
      console.log("fitting to ", bbox)

      const viewport = this.getViewport();
      const fitted = viewport.fitBounds(bbox, {
        padding: 0,
      });
      const { longitude, latitude, zoom } = fitted
      viewState = { longitude, latitude, zoom }
      if (viewState) {
        this.updateView(viewState)
      }

    } catch (err) {
      console.log('failed to fit', err.toString())
      console.log(err)
    }
  }
  focus(name) {
    const vizu = this.carteManagers.filter((v) => v.name === name)[0];
    if (vizu) {
      const vizuInfosElement = this.container.querySelector("#infos");
      // if (vizu.renderInfos && vizuInfosElement) {
      //   vizu.renderInfos(vizuInfosElement);
      // }

      this.fit(name);
    }
  }
  updateCarte(name, changes) {
    const cm = this.carteManagers.filter(c => c.name === name)[0]
    if (cm)
      cm.settings = { ...cm.settings, ...changes };
  }
}
