import { html } from "lit"
import { until } from "lit/directives/until"
import { getLogs, JournalEntry } from "."
import './journal.css'
import createContextMenu from "../contextmenu"
import { Action } from "../contextmenu/action"
function handleContextmenu(e: PointerEvent, data){
    e.preventDefault()
    const actions: Action<JournalEntry>[]  = [
        {
            name: "copy",
            text: "Copy log",
            async perform(data,evt){
                await navigator.clipboard.writeText(JSON.stringify(data, null, 2))
            }
        }
    ]
    createContextMenu(e, data,actions, `${data.message.slice(0, 10)}${data.message.length > 10 ? '...' : ''}`)
}

function renderJournalEntry(log: JournalEntry) {
    let { timestamp, theme, session, message, details } = log
    const t0 = Number(log.session)
    return html`<div class="row journal-entry ${log.level}" title="${JSON.stringify(log.details, null, 2)}" @contextmenu="${e=>handleContextmenu(e, log)}">
    <span class="timestamp">${(timestamp || t0) - t0}</span>
    <span class="heme">${(theme || []).length ? `[${(theme || [] as any).join('.')}]` : ''}</span>
    <span class="message">${log.message}</span>
</div>`
}


async function getInitialState() {
    const statusEntries = await (getLogs("typeIdx", "status"))
    const sessionDict = {}
    for (let { session, timestamp, message } of statusEntries) {
        if (!sessionDict[session])
            sessionDict[session] = {
                timestamp: Number(session),
                status: [],
            }
            const status = {...JSON.parse(message), session, timestamp}
            sessionDict[session].status.push(status)
    }

    const sessions = Object.values(sessionDict)
        .sort((s1: any, s2: any) => s2.timestamp - s1.timestamp) // sort by decreasing timestamp
        .map((s: any) => {
            let duration = Math.max(...s.status.map(l => Number(l.timestamp) - Number(s.timestamp)))
            s.duration = duration
            return s
        })
    return {
        search: '',
        level: 'trace',
        session: null,
        start: 0,
        end: 0,
        collapse: false,
        sessions
    }
}


function countMessages(session){
    let total = 0
    for(let s of session.status){
        for(let level in s.perLevel)
            total += s.perLevel[level]
    }
    return total
}

function renderDuration(ms){
    return `${Math.round(ms/1000)}s`
}
export default function renderJournal(state, update) {
    console.log('rendering journal', state)
    if (!state.tabState) {
        getInitialState().then((tabState) => {
            console.log(tabState.sessions)
            update(['tabState'], tabState)
        }).catch(err => {
            console.log('failed to get initial state', err.toString())
            console.log(err)
        })

        return html`<div>loading ...</div>`
    }
    const { sessions, level, search, collapse, start, end, session } = state.tabState
    const currentSession = session ? sessions.find(s=>s.timestamp.toString() === session.toString()) : sessions[0]
    console.log('current session', currentSession)
    return html`
    <div>
        <div class="row journal-controls">
            <div class="row">
                <label>session</label>
                <select @input="${e=>{
                    update(['tabState',"session"], e.target.value)
                }}">
                    ${sessions.map((session) => html`<option value="${session.timestamp}">${new Date(session.timestamp).toISOString()}</option>`)}
                </select>
            </div>
            <div class="row">
                <label>level</label>
                <select @input="${console.log}">
                    <option>trace</option>
                    <option>debug</option>
                    <option>info</option>
                    <option>warning</option>
                    <option>error</option>
                    <option>critical</option>
                </select>
            </div>
            <div class="row">
                <label>search</label>
                <input style="flex:1" type="text" id="search" name="search" minlength="1" maxlength="20" size="10"
                    value="${search}" @input="${e => console.log('input', e)}">
            </div>
        </div>
        <br>
        <div class="summary row" style="justify-content:space-between">
            <div>
                session starting at ${new Date(currentSession.timestamp).toISOString()}
            </div>
            <div>
                ~${renderDuration(currentSession.duration)}
            </div>
            <div>
            ~${countMessages(currentSession)} logs
            </div>
            <!-- <pre>
${JSON.stringify(currentSession.status, null, 2)}
            </pre> -->
        </div>
        <div class="column">
            ${until(getLogs("sessionIdx", session ? Number(session) : null).then((entries) => {
                console.log('entries for session', session, entries)
            return html`${entries.map(e => renderJournalEntry(e))}`
        }).catch((err) => {
            console.log('error ! !', err.toString())
        }), html`<span>loading ...</span>`)}
    
        </div>
    </div>`
}