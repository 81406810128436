import { IDBPDatabase, openDB, } from "idb"
import { set } from "object-path-immutable"

let DB: IDBPDatabase
let STORE: string
let SESSION: number
let CONFIG = {
    database: 'journal',
    store: 'default',
    defaultLevel: 'info',
    statusPeriod: 10000,

}
let NEXT_ID = 0;
let STATUS;
let BROKEN_TIME = 0

type LogLevel = 'trace' | 'debug' | 'info' | 'warning' | 'error' | 'fatal'
type LogType = 'status' | 'event' | 'start' | 'progress' | 'success' | 'failure'
export type JournalEntry = {
    message: string;
    type?: LogType;
    group?: string;
    session?: number;
    timestamp?: number;
    level?: LogLevel;
    theme?: string | string[]
    details?: any;
    date?: string;
    id?: string;
}

function sanitizeDetails(obj) {
    if (typeof obj !== 'object')
        return obj
    for (let key in obj) {

        const value = obj[key]
        const t = typeof value
        if (t === 'function') {
            obj = set(obj, key, `...${t}...`)
        }
    }
    return obj
}

function assertInitialized() {
    if (!DB)
        throw `unable to use journal before openingI`
}
export async function log(entry: string | JournalEntry) {
    const now = Date.now()
    try {
        if (BROKEN_TIME) {
            return
        }
        assertInitialized()
        // console.log('adding to journal', entry)
        if (typeof entry === 'string')
            entry = { message: entry }
        entry.session = SESSION
        entry.type = entry.type || 'event'
        entry.timestamp = entry.timestamp || Date.now()
        entry.level = entry.level || CONFIG.defaultLevel as LogLevel
        entry.details = sanitizeDetails(entry.details)
        let theme = entry.theme || ''
        if (typeof theme === 'string')
            theme = theme.split('.')
        for (let i = 0; i < 4; i++) {
            const themei = theme[i]
            if (themei) {
                entry[`theme${i}`] = themei
            }
        }
        entry.date = new Date(entry.timestamp).toISOString().split('T')[0]
        entry.id = [SESSION, (NEXT_ID++).toString().padStart(9, "0")].join('-')
        await DB.put(STORE, {
            ...entry,
        },)

        const { type, theme1, theme2, level } = entry as any

        STATUS.perType[type] = (STATUS.perType[type] || 0) + 1
        STATUS.perLevel[level] = (STATUS.perLevel[level] || 0) + 1
        STATUS.perTheme1[theme1] = (STATUS.perTheme1[theme1] || 0) + 1
        STATUS.perTheme2[theme2] = (STATUS.perTheme2[theme2] || 0) + 1

    } catch (err) {
        console.log('journal is broken', err.toString())
        console.log(err)
        BROKEN_TIME = now
    }

    // TODO : update STATUS
}


export async function cleanup(name) {
    return new Promise((res, rej) => {
        var req = indexedDB.deleteDatabase(name);
        req.onsuccess = function () {
            console.log("Deleted database successfully");
            res(null);
        };
        req.onerror = function (err) {
            console.log("Couldn't delete database");
            rej(err)
        };
        req.onblocked = function () {
            console.log("Couldn't delete database due to the operation being blocked");
        };
    })
}


export async function getLogs(index = "sessionIdx", query: any = null) {
    if (index && !query)
        query = SESSION
    assertInitialized()
    console.log('get', index, query)
    return DB.getAllFromIndex(STORE, index, query)
}
export async function openJournal(config) {
    if (SESSION)
        throw `unable to open journal twice`
    CONFIG = { ...CONFIG, ...config, }
    const { database, store, } = CONFIG as any
    SESSION = Date.now()
    const db = await openDB(database, 1, {
        upgrade(db, oldVersion, newVersion, transaction) {
            console.log('upgrading database', database, 'from', oldVersion, 'to', newVersion)
            const s = db.createObjectStore(store, { keyPath: 'id' })
            s.createIndex('sessionIdx', 'session')
            s.createIndex('typeIdx', 'type')
            s.createIndex('dateIdx', 'date')
            s.createIndex('levelIdx', 'level')
            s.createIndex('theme1Idx', 'theme1')
            s.createIndex('theme2Idx', 'theme2')
        }
    })
    DB = db
    STORE = store
    STATUS = {
        perType: {},
        perLevel: {},
        perTheme1: {},
        perTheme2: {},
    }
    const { statusPeriod } = CONFIG


    let previousUncaught: any = null
    window.addEventListener("unhandledrejection", function (promiseRejectionEvent) {
        const { reason } = promiseRejectionEvent
        log({ message: (reason || '').toString(), level: 'error', theme: ['UNHANDLED'], details: reason })
    });
    window.addEventListener('error', (err) => {
        if (previousUncaught === err) // they come by 2
            return
        previousUncaught = err
        log({ message: (err || '').toString(), level: 'error', theme: ['UNCAUGHT'], details: err })
    })

    console.log('new journal', SESSION)
    console.log(CONFIG)

    function logStatus() {
        const entry = {
            type: 'status' as LogType,
            message: JSON.stringify(STATUS)
        }
        log(entry)
    }
    logStatus()
    if (statusPeriod)
        setInterval(() => {
            logStatus()
        }, statusPeriod)
}