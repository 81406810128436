export function rgbFromCss(css) {
    let r, g, b
    if (css.startsWith("#")) {
        const str = css.slice(1)
        if (str.length === 3) {
            r = 16 * parseInt(str.slice(0, 1), 16)
            g = 16 * parseInt(str.slice(1, 2), 16)
            b = 16 * parseInt(str.slice(2, 3), 16)
        } else if (str.length === 6) {
            r = parseInt(str.slice(0, 2), 16)
            g = parseInt(str.slice(2, 4), 16)
            b = parseInt(str.slice(4, 6), 16)
        } else throw `invalid color string : ${css}`
        return [r, g, b]
    } else if (css.startsWith("rgb")) {
        const parts = css.split("(")[1].split(")")[0].split(",").map(p => parseInt(p.trim()))
        return parts
    } else {
        throw `color string must start with # : ${css}`
    }

}