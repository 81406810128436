import { html } from "lit";
import Descartes, { DescartesContext } from "..";

export function renderCarteDetails(context: DescartesContext, descartes: Descartes) {
    const currentCarteName = context.carte
    const cm = descartes.carteManagers.filter(cm => cm.name === currentCarteName)[0]
    let carteState
    if (cm) {
        carteState = cm.getState()
    }
    if (carteState)
        return html`<div class="column details-content">
    <pre>${JSON.stringify(carteState, (k, v) => k === 'hover' ? undefined : v, 2)}</pre>
</div>`
}