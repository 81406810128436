import { set } from "object-path-immutable";
import cloudAgent from "../cloud-agent";


const GOOGLE_APIS_ENDPOINT = 'https://www.googleapis.com'
const STORAGE_API_ENDPOINT = "https://www.googleapis.com/drive/v3/"

const DEFAULT_ENDPOINT = STORAGE_API_ENDPOINT

const googleInit = {
    params: {
        supportsAllDrives: true
    }
}


export async function retrieveFile(fileId, fields = '*') {
    var path = DEFAULT_ENDPOINT + "files/" + fileId
    return cloudAgent.fetch(path, set(googleInit, ['params', 'fields'], fields)).then((result) => result.json());
}

export function createFile(body, parentId) {
    var path = DEFAULT_ENDPOINT + "files"
    if (parentId)
        body = { ...body, parents: [parentId] }
    return cloudAgent.fetch(path, {
        ...googleInit,
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
    }).then(r => {
        console.log(r)
        return r.json()
    })
}

export async function deleteFile(fileId) {

    var path = DEFAULT_ENDPOINT + "files/" + fileId
    return cloudAgent.fetch(path, { ...googleInit, method: 'DELETE' })
}


export function updateFile(fileId, body) {
    var path = DEFAULT_ENDPOINT + "files/" + fileId
    return cloudAgent.fetch(path, {
        ...googleInit,
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'PATCH',
    }).then(r => {
        console.log(r)
        return r.json()
    })
}

export function retrieveContent(fileId) {
    var path = DEFAULT_ENDPOINT + "files/" + fileId + '?alt=media'
    return cloudAgent.fetch(path).then((response) => response.blob())
}

export function updateContent(fileId, blob) {
    var path = "https://www.googleapis.com/upload/drive/v3/files/"
        + fileId

    const mimeType = blob.type
    let init = { method: 'PATCH', params: { uploadType: 'media' }, mimeType, body: blob }
    init = { ...googleInit, ...init }
    init = set(init, ['params', 'uploadType'], 'media')
    return cloudAgent.fetch(path, init);
}


export async function listFiles(q, overrides: any = {}, limit = 0) {
    const { fields, ...others } = overrides
    var get_params = {
        includeItemsFromAllDrives: true,
        pageSize: 1000,
        //            fields: "nextPageToken, files(id, name, modifiedTime)",
        fields: `nextPageToken, files(${fields || '*'})`,
        q,
        // orderBy: 'starred,viewedByMeTime desc',
        ...others
    }
    var path = DEFAULT_ENDPOINT + "files";
    let again = true
    let pageToken;
    let list = [];
    let failure = false

    let lastBody
    let lastResponse
    while (again) {
        let nextPageToken;
        let params = { ...googleInit.params, ...get_params }
        if (pageToken)
            params.pageToken = pageToken
        const partialList = await cloudAgent.fetch(path, { ...googleInit, params })
            .then((response) => {
                lastResponse = response
                if (!response.ok) {
                    failure = true
                }
                return response
            })
            .then((response) => response.json())
            .then((result => {
                lastBody = result
                nextPageToken = result.nextPageToken
                return result.files;
            }))
        list = list.concat(partialList)
        if (nextPageToken && (!limit || (list.length < limit))) {
            pageToken = nextPageToken
            again = true
        } else {
            again = false
        }
    }
    if (failure) {
        let error = `request to ${decodeURI(lastResponse.url)} returned ${lastResponse.status}`
        if (lastBody)
            error += "\n" + JSON.stringify(lastBody, null, 2)
        throw error
    }
    return list.filter((item, index) => !limit || index < limit)

    // https://content.googleapis.com/drive/v3/files?pageSize=100&fields=nextPageToken, files(id, name)&key=AIzaSyCL277MC1yOY0t6odCcSpm4FJW0IrA3Yfs
}


