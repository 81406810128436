import { Geometry, CubeGeometry } from '@luma.gl/core'

const points = [
    [0, 0, 0],
    [1, 1, -0.5],
    [-1, 1, -0.5],
    [1, 1, 0.5],
    [-1, 1, 0.5],
    [0, 1, 0.75]
]

const triangles = [
    [0, 2, 1],
    [0, 3, 4],
    [0, 1, 3],
    [0, 4, 2],
    [3, 4, 5],

]

const positions = new Float32Array(triangles.length * 3 * 4)
let i = 0;
for (let triangle of triangles)
    for (let idx of triangle) {
        const point = points[idx]
        for (let coordinate of point)
            positions[i++] = coordinate
    }
export const CameraGeometry = new Geometry({
    id: 'camera-geometry',
    // https://luma.gl/modules/engine/docs/api-reference/geometry
    attributes: {
        positions
        // indices: new Uint16Array(
        //     [0, 1, 1, 2, 2, 3, 3, 0, 0, 4, 1, 4, 2, 4, 3, 4]
        // )
    },
    // drawMode: 1// LINES
})