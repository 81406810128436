import { html } from "lit"
import { useGDrive, addGDrive } from "./icon"
import jwt_decode from 'jwt-decode'
import * as _got from 'google-one-tap'
import pickFromDrive from "./picker";

import notify from '../notifications'

declare global {
    const google: typeof _got;
}



const API_KEY = "AIzaSyDotkuMMi-0qdENu9RuYyuw-74earkO_Gc"

const OAUTH2_CLIENT_ID = "1010077190640-s7t5bimvp846ves48o3fvu5u7iljseki.apps.googleusercontent.com"

var SCOPES = 'https://www.googleapis.com/auth/drive.install https://www.googleapis.com/auth/drive.file profile';

var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",];


function getFromCache(key) {
    let result = null
    try {
        const str = localStorage.getItem(key)
        result = JSON.parse(str)
    } catch (err) { }
    if (result)
        console.log('cached hit for ', key, result)
    return result
}
function putInCache(value, key) {
    let result = null
    try {
        const str = localStorage.setItem(key, JSON.stringify(value))
        result = value
    } catch (err) { }
    console.log(value, 'cached as ', key)
    return result
}
function clearCache(key) {
    if (key)
        localStorage.removeItem(key)
    else
        localStorage.clear()
    return null
}

let idToken
let auth_response

let tokenClient = null
let update = () => { }


async function ensureAuthorized() {
    if (isValid(auth_response))
        return
    return new Promise((res, rej) => {
        const to = 60000
        setTimeout(() => {
            rej(`no token after ${to}ms`)
        }, to)
        tokenClient.callback = (response) => {
            response.exp = Date.now() / 1000 + response.expires_in
            console.log('token callback', response)
            auth_response = putInCache(response, "WUB_GOOGLE_AUTH_RESPONSE")
            update()
            res(null)
        }
        console.log('fetching token')
        console.log(tokenClient)
        if (!isValid(idToken)) { // TODO : check scopes

            // Prompt the user to select a Google Account and ask for consent to share their data
            // when establishing a new session.
            tokenClient.requestAccessToken({ prompt: 'consent' });
        } else {
            console.log('already identified')
            // Skip display of account chooser and consent dialog for an existing session.
            tokenClient.requestAccessToken(
                { prompt: '' }
            );
        }
    })

}

function isValid(token) {
    if (!token)
        return
    const { exp } = token
    const now = Date.now() / 1000
    if (exp && (now > exp)) {
        console.log('token expired', now, token)
        return
    }

    return true
}
async function setupAccount() {
    if (isValid(idToken))
        return true
    // also display the One Tap dialog
    await new Promise((res, rej) => {
        google.accounts.id.prompt(function (n) {
            const momentType = n.getMomentType()
            if (momentType === 'dismissed')
                res(null)
        });
    })
    update()
}

async function accountCallback(response) {
    console.log('accountCallback', response)
    idToken = putInCache(jwt_decode(response.credential), "WUB_GOOGLE_ID_TOKEN")
    update()
}


async function googleSignOut() {
    console.log('google signout')
    idToken = clearCache("WUB_GOOGLE_ID_TOKEN")
    auth_response = clearCache("WUB_GOOGLE_AUTH_RESPONSE")
    update()
}
function checkIfGoogleIsAvailable() {
    if (typeof gapi === 'undefined' || typeof google === 'undefined') {
        const error = `GoogleAPI is not available in this scope : load the scripts at https://apis.google.com/js/api.js and https://accounts.google.com/gsi/client`
        throw error
    }
}

let initialized = false
export async function init(_update) {

    checkIfGoogleIsAvailable()
    if (initialized)
        throw `Google must only be initialized once`

    idToken = getFromCache("WUB_GOOGLE_ID_TOKEN")
    auth_response = getFromCache("WUB_GOOGLE_AUTH_RESPONSE")
    update = _update

    google.accounts.id.initialize({
        client_id: OAUTH2_CLIENT_ID,
        callback: accountCallback,
        cancel_on_tap_outside: true,
        auto_select: true,
    });



    // INIT GAPI

    await Promise.all([
        //'client:auth2', // deprecated  but use gsi do not provide authorization : https://developers.google.com/identity/oauth2/web/guides/migration-to-gis#gapi-callback
        'client',
        'picker'
    ].map(api => {
        return new Promise((res, rej) => {
            gapi.load(api, () => {
                res(null)
            });
        });
    }))
    await gapi.client.init({
        apiKey: API_KEY,
        discoveryDocs: DISCOVERY_DOCS,
    })

    tokenClient = (google.accounts as any).oauth2.initTokenClient({
        client_id: OAUTH2_CLIENT_ID,
        scope: SCOPES, //'https://www.googleapis.com/auth/calendar.readonly',
        callback: (r) => console.log('default tokenClient callback', r)
    });

    await setupAccount();
    console.log('Google module initialized')
    initialized = true

}




function isConnected() {
    return isValid(idToken)
}

function renderGoogleStatus() {
    if (isConnected()) {
        return `
click to disconnect
        
You are connected as ${idToken.email}
${JSON.stringify(idToken, null, 2)}

${JSON.stringify(auth_response, null, 2)}
        `
    } else {
        return `Click to connect your google account`
    }
}

// should specify scopes
export async function getAccessToken() {
    checkIfGoogleIsAvailable()
    await ensureAuthorized()
    return auth_response.access_token
}

export function getCurrentAccessToken() {
    return auth_response.access_token
}


export async function getDriveApi() {
    checkIfGoogleIsAvailable()
    gapi.client.setToken({ access_token: await getAccessToken() })
    const drive = (gapi.client as any).drive
    return drive

}

export function getWidget(handleFile) {

    const widgetConfig = {
        actions: [
            // {


            //     name: 'install to your Drive',
            //     icon: addGDrive,
            //     handler: async function () {
            //         const drive = await getDriveApi()
            //         const files = await drive.files.list({
            //             'pageSize': 10,
            //             'fields': 'files(id, name)',
            //         })
            //         console.log(files)
            //         // const response = await gapi.client.drive
            //     }
            // },
            {
                name: 'use Drive File',
                icon: useGDrive,
                handler: async function () {
                    const drive = await getDriveApi()
                    await ensureAuthorized()
                    console.log(google, (google as any).picker)
                    const file = await pickFromDrive({ access_token: auth_response.access_token, apiKey: API_KEY, clientId: OAUTH2_CLIENT_ID });
                    await handleFile(file)
                    // const response = await gapi.client.drive
                }
            }
        ]
    }


    checkIfGoogleIsAvailable()
    const { actions } = widgetConfig
    async function handleClickOnGoogle() {
        if (!isConnected()) {
            await setupAccount();
        } else {
            await googleSignOut()
        }
    }
    setTimeout(() => {
        google.accounts.id.renderButton(document.querySelector("#widget-gsi"), {
            size: 'medium',
            theme: "outline",
            type: "icon",
        }, handleClickOnGoogle)

    })
    return html`
    
    <button > <div title=${renderGoogleStatus()} id="widget-gsi" data->google</div></button>
    ${(isConnected() ? actions : []).map(({ name, icon, handler }) => html`<button @click=${handler} title= "${name}" > ${icon} </button>`)
        }`
} 