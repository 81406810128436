export function normalizeJson(obj) {
    if (typeof obj !== 'object' || Array.isArray(obj))
        return obj
    if (obj instanceof Date)
        return obj.getTime()
    let result = {}
    for (let k of Object.keys(obj).sort())
        result[k] = normalizeJson(obj[k])
    return result
}

