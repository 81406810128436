import { setupVariables } from "~/simple-tool/src/variables";
import { bindFetch } from "../http-utils";
import { bindWorkerBuilder } from "../worker-utils";

async function httpHeadersFromBlob(blob: Blob) {
    let type = blob.type || 'application/octet-stream'
    const size = blob.size

    const entries = {
        'Content-Length': size,
        'Content-Type': type,
    }
    return {
        ...entries,
        entries: () => Object.entries(entries)
    }
}
async function fetchFunction(url, init): Promise<any> {    // const fetch = (await import('node-fetch')).default
    const { method, headers } = init
    if (!url.startsWith('blob:'))
        return fetch(url, init)
    let status = 200
    switch (method) {
        case 'HEAD':
            {
                const response = await fetch(url)
                return {
                    headers: await httpHeadersFromBlob(await response.blob()),
                    status,
                }
            }

        default:
            return fetch(url, init)
    }
}


function getVariablesFromUrl() {
    const { searchParams } = new URL(document.URL)

    let base_url = (window.location.ancestorOrigins || [])[0]
    // todo : use 'url' variable 
    if (!base_url) {
        base_url = window.origin + window.location.pathname
        // TODO : pop filename from path
    }
    if (searchParams.has('base_url'))
        base_url = searchParams.get('base_url') || ""

    const variables = {
        base_url,
    }
    for (let key of searchParams.keys()) {
        const value = searchParams.get(key)
        // console.log(key, value)
        variables[key] = value
    }




    for (let key of ['preset']) { //url, raw, name
        // if (searchParams.has(key))
        variables[key] = searchParams.get(key) || ""
    }

    if (!variables['base_url'].endsWith('/'))
        base_url += "/"
    for (let k in variables) {
        // console.log(k, variables[k])
    }
    return variables
}

bindFetch(fetchFunction)
setupVariables(getVariablesFromUrl())
bindWorkerBuilder((workerUrl) => new Worker(workerUrl, { type: 'module' }))
