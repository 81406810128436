export const name = 'timejoin'
import { set } from 'object-path-immutable'
import { config } from 'process'
import DataTable from '../table'
export const schema = {
    type: 'object',
    properties: {
        'id': {
            type: 'string',
        },
        'externalTable': {
            type: 'string',
        },
        'externalId': {
            type: 'string',
        },
        'externalProps': {
            type: 'array',
            items: { type: "string" }
        },
        "time": {
            type: 'string',
        },
        "externalTime": {
            type: 'string',
        },
        "as": {
            type: 'array',
            items: { type: 'string' }
        }
    },
    required: ["externalTable"]
}


function createCursor(samples = [], config) {
    // 
    const times = samples.map(s => s[config.externalTime || 't'])
    const ti = times[0]
    const tf = times[times.length - 1]

    const approximateDt = (tf - ti) / times.length
    return (time) => {
        let approximateIndex = Math.floor((time - ti) / approximateDt)
        approximateIndex = Math.min(approximateIndex, times.length - 1)
        approximateIndex = Math.max(approximateIndex, 0)
        let cursor = Math.max(approximateIndex, 0)
        cursor = Math.min(cursor, times.length - 1)
        while (samples[cursor] && times[cursor] <= time)
            cursor++
        const after = samples[cursor]
        const afterIndex = cursor
        cursor = cursor - 1
        while (samples[cursor] && times[cursor] > time)
            cursor--
        const before = samples[cursor]
        const beforeIndex = cursor
        if (!after)
            return before
        if (!before)
            return after
        const dt = times[afterIndex] - times[beforeIndex]
        if (dt < 0.01) {
            console.log(cursor, before, after)
            throw `consecutive frames are too close : dt=${dt}s`
        }
        const alpha = (time - before.t) / (dt)
        const state = {
        }
        for (let prop of config.externalProps)
            state[prop] = after[prop] * alpha + before[prop] * (1 - alpha)
        return state
    }


}

export async function apply(samples, tr, dataset: { [name: string]: DataTable }) {
    let { id, time, externalTime, externalTable, externalId, externalProps, as } = tr
    const allExternalSamples = await dataset[externalTable].getSamples()

    const cursorConfig = {
        ...tr,
    }
    if (!externalProps) {
        const firstSample = allExternalSamples[0]
        cursorConfig.externalProps = Object.keys(firstSample).filter(p => Number.isFinite(firstSample[p]))
    }
    if (!as)
        as = externalProps


    const perId = {}
    for (let s of allExternalSamples) {
        if (!perId[s[externalId]])
            perId[s[externalId]] = []
        perId[s[externalId]].push(s)
    }

    const cursors = {}
    return samples.map(s => {
        const localId = s[id]
        const time = s[tr.time || 't']
        const externalSamples = perId[localId]
        if (!cursors[localId])
            cursors[localId] = createCursor(externalSamples, cursorConfig)
        const external = cursors[localId](time)
        if (externalProps)
            for (let i = 0; i < externalProps.length; i++) {
                const localProp = as[i]
                for (let prop of externalProps)
                    s = set(s, [localProp], external[prop])
            }
        else {
            s = set(s, ['external'], external)
        }
        return s
    })
}