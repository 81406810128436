import MapVizu from ".";
import { ScenegraphLayer } from "@deck.gl/mesh-layers/src/index";
import { COORDINATE_SYSTEM } from "@deck.gl/core";

import { getRegisteredLoaders } from '@loaders.gl/core/src/lib/api/register-loaders'
import { registerLoaders } from "@loaders.gl/core";
import { GLTFLoader } from "@loaders.gl/gltf";
import { OBJLoader } from '@loaders.gl/obj';

import { load } from "@loaders.gl/core";
// Register the proper loader for scenegraph.gltf
registerLoaders([GLTFLoader, OBJLoader]);

// import sampleSceneBin from "../../assets/models/UFC_FIGHT_ISLAND_STAGE_VENUE_GEO_11_01_2021.bin";
import { timeThursday } from "d3";
import Carte from ".";
import { PathLayer, SimpleMeshLayer, LineLayer } from "deck.gl";
import { getCoordinates } from "./common/coordinates";
import { get } from "object-path-immutable";
import { updateScale } from "../scale-manager";
import { computeDiscreteRange, computeNumericRange } from "../utils/data";

const data = [{}];
export default class TrajectoryCarte implements Carte {
    config: any;
    layer: any
    ranges: any
    trajectories: any;

    async init(dataset) {

        const t0 = Date.now()
        const name = this.config.name
        console.log('telemetry', name)
        const time = function (...args) {
            const dt = Date.now() - t0
            console.log(dt, name, ...args)
        }

        time(`initializing telemetry`)
        let parsed = []
        let current = ''
        this.config = { ...this.config }
        let { samples } = this.config
        let result = await fetch(samples.url, {
            mode: 'cors',
            method: 'HEAD'
        })
        const len = Number(result.headers.get("Content-Length"))
        time("head", result.headers.get("Content-Length"))
        console.log(result)
        result = await fetch(samples.url, {
            mode: 'cors',
            headers: {
                'range': "bytes=0-1023"
            }
        })
        parsed = await dataset.getSamples(samples,)
        time(`${parsed.length} samples loaded`)
        let { color, intensity } = this.config

        if (color) {
            const { property, scale } = color
            let min = Number.MAX_VALUE
            let max = - Number.MIN_VALUE
            for (let s of parsed) {
                const value = get(s, property)
                if (Number.isFinite(value)) {
                    min = Math.min(min, value)
                    max = Math.max(max, value)
                }
            }
            updateScale(scale, [min, max], [this.config.name, 'samples'].join(':'))
        }

        const groups = parsed.reduce((prev, curr) => {
            const { id } = curr
            if (!prev[id]) prev[id] = []
            prev[id].push(curr)
            return prev
        }, {})
        const ranges = {
            // t: computeNumericRange(parsed, ['time']),
            x: computeNumericRange(parsed, ['position', 0]),
            y: computeNumericRange(parsed, ['position', 1]),
            z: computeNumericRange(parsed, ['position', 2]),
            id: computeDiscreteRange(parsed, ['id'])
        }

        time(`ranges computed`)

        // this.objects = ranges['id'].map((id) => {
        //     let objectSamples = parsed.filter(item => item.id === id)
        //         .sort((a, b) => a.time - b.time)
        //     if (this.config.memory)
        //         objectSamples = applyMemory(objectSamples, this.config.memory);
        //     objectSamples = objectSamples.map(s => {
        //         const identity = identities[s.id]
        //         return { ...(identity || {}), ...s }
        //     })
        //     return {
        //         id,
        //         interpolate: createInterpoler(objectSamples)
        //     }
        // })


        const perIdObjects = {}
        for (let s of parsed) {
            const { id } = s
            if (!perIdObjects[id])
                perIdObjects[id] = []
            perIdObjects[id].push(s)
        }
        this.trajectories = []
        for (let id in perIdObjects) {
            const samples = perIdObjects[id]
            for (let i = 0; i < samples.length - 1; i++) {
                const s = samples[i]
                this.trajectories.push({
                    sourcePosition: s.position, targetPosition: samples[i + 1].position,
                    id,
                    color: [100, 100, 100]
                })
            }
            // this.trajectories.push({
            //     name: id,
            //     path: samples.map((s, i) => {
            //         if (i === samples.length - 1)
            //             return
            //         return {  }
            //     }).filter(Boolean),
            //     color: [100, 100, 100]
            // })
        }
        console.log('trajectories', this.trajectories)
        time(`done`)

        this.ranges = ranges;
        console.log(getCoordinates(this.config))
    }


    getLayers() {
        if (this.layer)
            return [this.layer]
        // if (!this.config.url) return;
        // if (this.layer) return [this.layer];

        let getColor = this.config.color || [100, 100, 100, 20]
        if (getColor.length < 4)
            getColor.push(20)
        const l = new LineLayer({
            id: this.config.name,
            ...getCoordinates(this.config),

            data: this.trajectories,
            // getPath: d => d.path,

            //"https://raw.githubusercontent.com/KhronosGroup/glTF-Sample-Models/master/2.0/BoxAnimated/glTF-Binary/BoxAnimated.glb",
            //   loadOptions: GLTFLoader,
            //   getColor,
            widthMinPixels: 2,
            getWidth: 1,
            getColor: [100, 100, 100, 20],
            pickable: false,
            parameters: {
                depthMask: false
            }
        });
        this.layer = l;
        return [l];
    }

    getBbox() {
    }
}
