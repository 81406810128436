import { bbox } from "../utils/geography";
import { GeoJsonLayer } from '@deck.gl/layers';
import MapVizu from ".";
import { get } from "object-path-immutable";
import Carte from ".";
import { convertGeoJson } from "../utils/geography/geojson";
import { SELECTED_COLOR } from "../defaults";
import { colorToRGBArray } from "../utils/colors";

const COUNTRIES =
  "https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_scale_rank.geojson"; //eslint-disable-line

let baseMap = new GeoJsonLayer({
  id: "geojson-map",
  data: COUNTRIES,
  // Styles
  stroked: true,
  filled: true,
  lineWidthMinPixels: 2,
  opacity: 0.4,
  getLineColor: [60, 60, 60],
  getFillColor: [200, 200, 200],
});



const adapt = (c) => (c.length === 2 ? [...c, 0] : c);
export function collectGeojsonPoints(geojson, points = []) {
  const { type } = geojson;
  try {
    switch (type) {
      case "FeatureCollection": {
        for (let feature of geojson.features) {
          points = collectGeojsonPoints(feature, points);
        }
        return points;
      }
      case "FeatureCollection": {
        for (let feature of geojson.features) {
          points = collectGeojsonPoints(feature, points);
        }
        return points;
      }
      case "Feature":
        return collectGeojsonPoints(geojson.geometry, points);
      case "Polygon":
        return [...points, ...geojson.coordinates[0]];
      case "MultiPolygon":
        for (let polygon of geojson.coordinates)
          points = [...points, ...polygon[0]];
        return points;
      case "Point":
        return [...points, geojson.coordinates];
      case "MultiPoint":
        return [...points, ...geojson.coordinates];
      case "LineString":
        return [...points, ...geojson.coordinates];
      case "MultiLineString":
        for (let line of geojson.coordinates) points = [...points, ...line];
        return points;
      case "GeometryCollection":
        for (let geometry of geojson.geometries) {
          points = collectGeojsonPoints(geometry, points);
        }
        return points;
      default:
        throw "unknown geojson type: " + type;
    }
  } catch (err) {
    console.log("failure with type", type);
    return points;
  }
}

function isValidFeature(feature) {
  if (!feature.geometry) return false;
  return true;
}

function cleanGeojson(geojson) {
  switch (geojson.type) {
    case "FeatureCollection":
      return {
        ...geojson,
        features: geojson.features.filter((f) => isValidFeature(f)),
      };

    default:
      return geojson;
  }
}

const defaultConfig = {
  styles: {},
};

export default class GeojsonCarte implements Carte {
  config: any;
  geojson

  async init() {

    let { url, geojson, coordinates, gpsOrigin } = this.config;
    if (!geojson && url) {
      geojson = await fetch(url).then((r) => r.json());
    }
    geojson = cleanGeojson(geojson);
    switch (coordinates) {
      case "xy":
      case "xyz":
        geojson = convertGeoJson(geojson, { from: "xy", gpsOrigin })
        break;
      case "geoxyz":
      case "geoxy":
        geojson = convertGeoJson(geojson, { from: "geoxy", gpsOrigin })
      default:
        break;

    }
    this.geojson = geojson
  }

  getStyle(feature, kind?) {
    let styleId = get(feature, ["properties", "styleMapHash", "normal"]);
    if (styleId) styleId = styleId.slice(1);
    let style = get(this.config, ['styles', styleId], {});
    if (!kind)
      kind = feature.geometry.type
    switch (kind) {
      case "Polygon":
        style = style.polygon;
        break;
      case "Point":
        style = style.icon;
        break;
      default:
        break;
    }
    return style || {};
  }

  getFillColor(feature) {
    const DEFAULT_FILL_COLOR = [0, 0, 100, 100];
    if (feature.geometry.type === "Point") return DEFAULT_FILL_COLOR;
    try {
      const style = this.getStyle(feature);
      if (style.color) return colorToRGBArray(style.color);
    } catch (err) { }
    return DEFAULT_FILL_COLOR;
  }
  getLineWidth(feature) {
    const DEFAULT_WIDTH = 1;
    if (feature.geometry.type === "Point") return DEFAULT_WIDTH;
    const style = this.getStyle(feature, "line");
    if (style.width) return style.width;
    return DEFAULT_WIDTH;
  }
  getLineColor(feature) {
    const DEFAULT_LINE_COLOR = [0, 0, 0, 200];
    if (feature.geometry.type === "Point") return DEFAULT_LINE_COLOR;
    const style = this.getStyle(feature, "line");
    if (style.color) return colorToRGBArray(style.color);
    return DEFAULT_LINE_COLOR;
  }
  getObject(id: any, ctx: any) {
  }
  getLayers(ctx, lastCtx, lastLayers) {
    const featureId = this.config.name + ':geojson'
    const { geojson } = this;
    const l = new GeoJsonLayer({
      id: this.config.name,
      data: { ...geojson, id: featureId },
      // pickable: true,
      //   stroked: true,
      filled: true,
      pointType: 'circle+text',
      lineWidthUnits: "pixels",
      // extruded: true,
      //   lineWidthScale: 1,
      //   lineWidthMinPixels: 2,
      getFillColor: (f) => ctx.selection.indexOf(featureId) >= 0 ? SELECTED_COLOR : this.getFillColor(f),
      getLineColor: (f) => this.getLineColor(f),
      // getRadius: 2,
      autoHighlight: true,
      getLineWidth: (f) => this.getLineWidth(f),
      getElevation: 0,
      pointRadiusMinPixels: 10
    });
    return [l];
  }
  getRanges() {
    try {
      const points = collectGeojsonPoints(this.geojson).map(adapt)
      const longitudes = points.map(p => p[0])
      const latitudes = points.map(p => p[1])
      return {
        longitude: [Math.min(...longitudes), Math.max(...longitudes)],
        latitude: [Math.min(...latitudes), Math.max(...latitudes)]
      }
    } catch (err) {
      return null
    }

  }
}
