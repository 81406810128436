// TODO : get header line
// TODO : get stream from response
// TODO : handle contiguous chunks (don't throw first line) : lineStarts dict

import { getMetadata, loadRange, localFetch } from "../../http-utils"
import { DataChunk } from "../chunk"
import { infereBuilder } from "../../util"
// import splitSmartly from 'split-smartly' // parenthesis
import splitOuter from 'split-outer'

const getUrl = (spec) => spec.csv || spec.url || spec.file



const HEADER_LINE = true
const SEPARATOR = ","

export async function getChunkAt(spec, offset, size): Promise<DataChunk> {

    const fullSize = await getSize.bind(this)(spec)
    if (offset + size > fullSize)
        size = fullSize - offset
    const response = await loadRange(getUrl(spec), offset, size)
    const tFirstByte = Date.now()
    const text = await response.text()
    const tLoaded = Date.now()
    let lines = text.split("\n")

    let before = 0, after = 0
    if (offset) {
        const first = lines[0]
        before = first.length
        lines = lines.slice(1)
        // console.log('before', before, 'B : ', first.split(',').length + ' fields')
    }
    if ((!fullSize && size) || (offset + size < fullSize)) {
        const last = lines.pop()
        after = last.length + 1 // ensure the next chunk starts with \n
        // console.log('after :', after + 'B')
    }
    size = size - after - before
    // console.log('parsing csv chunk', lines)
    const samples: any = []
    let nPerLine = 0
    for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim()
        if (!line)
            continue
        const words = splitOuter(line, { trim: true }).map(w => {
            let word = w.trim()
            if (word.startsWith('"') && word.endsWith('"')) {
                word = word.slice(1, word.length - 1)
            }
            return word
        }) // line.split(SEPARATOR)

        if (!nPerLine)
            nPerLine = words.length
        if (!i && !offset && HEADER_LINE) {
            this.fields = words.map(w => ({ name: w }))
        } else {
            const sample = {}
            if (words.length != this.fields.length) {
                console.log(lines)
                throw `invalid csv : line ${i} : ${this.fields.length} fields expected but ${words.length} found`
            }
            for (let j = 0; j < this.fields.length; j++) {
                const field = this.fields[j]
                const value = words[j]
                if (!field.builder)
                    field.builder = infereBuilder(value, field.name)
                const { name, builder } = field
                sample[name] = builder(value)
            }
            samples.push(sample)
        }
    }
    return { samples: () => samples, offset: offset + before, size: size, tFirstByte, tLoaded, nrows:samples.length }
}

export async function getSize(spec) {
    if (!this.metadata) {
        this.metadata = await getMetadata(getUrl(spec))
    }
    if (this.metadata.size)
        return this.metadata.size
    if (!this.metadata.size) {
        this.ab = await localFetch(getUrl(spec)).then(r => r.arrayBuffer())
    }
    if (this.ab)
        return this.ab.byteLength
}