const FFT = require('fft.js');

export function forwardFft(samples) {
    console.log('forward fft', samples)
    const size = samples.length
    const fftSamples = samples
    const s1 = fftSamples[0]
    const s2 = fftSamples[1]
    let dt = 0, df = 1, t = s1.t
    if (s1 && s2 && Number.isFinite(s1.t) && Number.isFinite(s2.t)) {
        dt = s2.t - s1.t
        df = 1 / dt / size
    }



    const f = new FFT(size)
    const input = f.createComplexArray()
    const output = f.createComplexArray()
    for (let idx = 0; idx < size; idx++) {
        let sample = fftSamples[idx]
        if (typeof sample === 'number')
            sample = { i: sample, q: 0 }
        const { i, q } = sample
        input[2 * idx] = i
        input[2 * idx + 1] = q || 0
    }
    console.log('computing fft')

    f.transform(output, input)


    let result = []
    for (let idx = 0; idx < size; idx++) {
        const i = output[idx * 2]
        const q = output[idx * 2 + 1]
        const sample = { t, i, q, n: Math.sqrt(i * i + q * q) }
        result.push(sample)
    }
    if (dt)
        result = result.map((s, i) => {
            if (i > result.length / 2)
                i -= result.length
            return { ...s, f: i * df }
        })
    // const low = result.slice(0, Math.floor(result.length / 2))
    // const high = result.slice(Math.floor(result.length / 2))
    // result = [...high, ...low]
    console.log("forwardFft", result)
    return result
}
