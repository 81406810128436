import { render, html } from "lit";
import Descartes from "..";

import './hovered.css'

function getCoordinates(hoveredState) {
    const [longitude, latitude, altitude] = (hoveredState || {}).gpsCoordinates || [];
    const [x, y, z] = (hoveredState || {}).coordinates || [];
    const [px, py] = (hoveredState || {}).pixelCoordinates || []
    const { object, event, info } = hoveredState || {}
    const validGps = Number.isFinite(longitude) && Number.isFinite(latitude)
    const validCoordinates = Number.isFinite(x) && Number.isFinite(y)
    const validPixel = Number.isFinite(px) && Number.isFinite(py)
    return (validGps || validCoordinates) ? {
        x, y, z, latitude, longitude, altitude, px, py
    } : {}
}

export default function renderHoveredState(hoveredState: any, descartes: Descartes) {
    const { x, y, z, latitude, longitude, altitude, px, py } = getCoordinates(hoveredState)
    const { object, event, info } = hoveredState || {}
    const validGps = Number.isFinite(longitude) && Number.isFinite(latitude)
    const validCoordinates = Number.isFinite(x) && Number.isFinite(y)
    const validPixel = Number.isFinite(px) && Number.isFinite(py)
    return html`
    <div class="hovered-state">
        <!--  <div style="position:fixed;top:${py - 10}px;left:${px - 5}px;color:black;pointer-events:none;user-select:none;">X</div>  -->
        <div class="coordinates" style="width:200px;">

        ${validGps ? html`<div><span class="label">longitude</span><span class="value">${longitude.toFixed(8)}°</span></div>
            <div><span class="label">latitude</span><span class="value">${latitude.toFixed(8)}°</span></div>
            <div><span class="label">altitude</span><span class="value">${(altitude || 0).toFixed(3)}m</span></div>` : ''
        }
        ${validCoordinates ? html`<div><span class="label">x</span><span class="value">${x.toFixed(3)}m</span></div>
            <div><span class="label">y</span><span class="value">${y.toFixed(3)}m</span></div>
            <div><span class="label">z</span><span class="value">${z.toFixed(3)}m</span></div>` : ''
        }
        ${validPixel ? html`<div><span class="label">px</span><span class="value">${px.toFixed(1)}</span></div>
        <div><span class="label">py</span><span class="value">${py.toFixed(1)}</span></div>` : ''
        }
        </div>
        ${(false) ? html`<pre>${JSON.stringify(info, (k, v) => ['layer', 'viewport',].indexOf(k) >= 0 ? undefined : v, 2)}</pre>`: ''}
    </div>`;
}

export function renderDebug(container, state) {
    if (!container) return
    render(html`<pre>${JSON.stringify(state, null, 2)}</pre>`, container)
}