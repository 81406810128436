import * as d3 from 'd3'
import { colorToRGBArray, COLOR_SCHEMES } from "../colors"




export function computeColorScale(domain, config) {
    console.log('compute color scale', config)
    switch (typeof domain[0]) {
        case 'number':
            return computeContinuousColorScale(domain, config)
        case 'string':
            return computeOrdinalColorScale(domain, config)
        default:
            throw `unable to infere color scale type for domain ${domain}`
    }
}

const defaultOrdinalColorConfig = {
    range: {},
    palette: COLOR_SCHEMES[0].palette
    //paletteFromHex("8dd3c7ffffb3bebadafb807280b1d3fdb462b3de69fccde5d9d9d9bc80bdccebc5ffed6f") //['green', 'orange', 'blue', 'grey', 'red']
}

export function computeOrdinalColorScale(domain, config = {}) {
    let { range, scheme, continuous, type, palette, name } = { ...defaultOrdinalColorConfig, ...config } as any
    if (!palette) {
        const colorScheme = COLOR_SCHEMES.find(c => c.name === scheme)
        if (!colorScheme)
            throw `no color scheme "${scheme}" (${COLOR_SCHEMES.map(c => c.name).join(',')})`
        palette = colorScheme.palette
    }
    let availablePalette = [...palette]

    const valid = {}
    for (let v of domain)
        valid[v] = true
    let validate = value => valid[value]
    let colors = domain.map((value, index) => {
        let color = range[value] || availablePalette[0]
        availablePalette = availablePalette.filter(c => c !== color)
        return color
    })
    let scale = d3.scaleOrdinal(colors)
    scale.domain(domain)
    // const legend = buildOrdinalColorScaleLegend(scale, { ...defaultLegendConfig, ...(config) })
    return {
        config,
        name,
        domain,
        range: colors,
        type: 'ordinal'
        // legend
    }
}


export function computeContinuousColorScale(domain, config) {
    let { range, scheme, continuous, type, name } = config
    range = range || ["green", "red"]
    const clamp = true
    let validate = value => {
        let [min, max] = domain
        if (value == undefined || value === null) return true
        if (!Number.isFinite(min) || !Number.isFinite(max) || (!clamp && (value < min || value > max))) {
            return false
        }
        return true
    }
    let scale = type === 'log' ? d3.scaleLog() : d3.scaleSequential(range) //
    // d3 color schemes : https://github.com/d3/d3-scale-chromatic //interpolateTurbo
    scale.domain(domain)
    // const scale = d3.scaleSequential(
    //     // d3.interpolateGreens
    // ).domain(domain)//d3.scaleLinear().domain(extent).range(d3.)
    // const legend = buildNumericColorScaleLegend(scale, { ...defaultLegendConfig, ...(config) })
    return {
        config,
        name,
        domain,
        range,
        type: 'continuous'
    }
}

export function scaleFunction(scaleState) {
    const { type, domain, range } = scaleState
    let colorScale = null
    if (!type)
        throw `no domain computed`

    switch (type) {
        case 'continuous':
            {
                let scale = type === 'log' ? d3.scaleLog() : d3.scaleSequential(range) as any
                // d3 color schemes : https://github.com/d3/d3-scale-chromatic //interpolateTurbo
                scale.domain(domain)
                return (v) => {

                    const output = scale(v < domain[0] ? domain[0] : v > domain[1] ? domain[1] : v)
                    return colorToRGBArray(output)
                }
            }
        case 'ordinal':
            {
                let scale = d3.scaleOrdinal(range)
                scale.domain(domain)
                return (input) => {
                    const output = colorToRGBArray(scale(input))
                    return output
                }
            }

        default:
            throw `invalid scale type ${type}`
    }
}