import { get } from "object-path-immutable";
import createContextMenu from "web-ui-blocks/contextmenu/index";
import Descartes from "..";
import { fillCoordinates, strCoordinates } from "../utils/3d";
import { getInfoObjectId } from "../utils/data";
import { alt, geo2Pos, pos2Geo } from "../utils/geography"


export function pickObject(evt, descartes: Descartes) {
    const viewport = descartes.getViewport();
    const px = evt.clientX;
    const py = evt.clientY;
    let [longitude, latitude] = viewport.unproject([px, py]);
    let coords = { longitude, latitude };

    const infos = descartes.deck.pickMultipleObjects({
        x: px,
        y: py,
        radius: 5,
        depth: 10,
    });

    const object = infos.map(o => o.object).filter(Boolean)[0];
    return object
}

export function handleLeftClick(evt, descartes: Descartes) {
    evt.preventDefault()
    const { context, actions } = descartes;

    const view = descartes.deck.getViews()[0];
    const viewport = descartes.getViewport();
    const px = evt.clientX;
    const py = evt.clientY;
    let [longitude, latitude] = viewport.unproject([px, py]);
    let coords = { longitude, latitude };

    const infos = descartes.deck.pickMultipleObjects({
        x: px,
        y: py,
        radius: 5,
        depth: 10,
    });

    const object = infos.map(o => o.object).filter(Boolean)[0];
    coords = fillCoordinates(object || coords, descartes)
    let title = strCoordinates(coords)
    if (object)
        title = `${object.id}\n\n` + title
    if (actions.length)
        createContextMenu(evt, descartes, actions, title);
}

export function decodeClickEvent(evt) {
    if (evt.button === 2)// contextmenu
        return
    let modifiers = { add: evt.ctrlKey, del: evt.altKey };
    return modifiers
}

export async function handleClickEvent(info, event, descartes: Descartes) {
    const evt = event.srcEvent || event;
    // const { x, y } = event.center;

    // const viewport = descartes.getViewport();
    // let coordinates;
    // const { object, layer } = info;
    // if (object) {
    //     let targetZ = alt(object);
    //     coordinates = viewport.unproject([x, y], { targetZ });
    // } else {
    //     coordinates = viewport.unproject([x, y], { targetZ: 0 });
    // }

    let {
        index,
        object,
        coordinate,
        layer
    } = info
    if (
        descartes.interaction &&
        descartes.interaction.handleEvent &&
        !descartes.interaction.handleEvent({ event, coordinate, info }, descartes)
    ) {
        return;
    }

    const modifiers = decodeClickEvent(evt)
    if (!modifiers)// contextmenu
        return
    const id = await getInfoObjectId(info, descartes)
    if (id) {
        descartes.select([id], modifiers);
    } else {
        descartes.select([], modifiers);
    }
    //pivot should snap
    descartes.update({ pivot: coordinate });
}

export function handleHoverEvent(info, event, descartes: Descartes) {
    const view = descartes.deck.getViews()[0];
    const {
        object,
        coordinate,
        color,
        layer,
        viewport,
        index,
        picked,
        x, y, pixel
    } = info || {};
    // const hover = object ? object.id : null;

    const hover: any = {
        gpsCoordinates: null,
        coordinates: null,
        pixelCoordinates: pixel,
        info,
        event,
        object
    }
    if (coordinate) {
        const gpsCoordinates: any = [...coordinate]
        if (gpsCoordinates.length < 3)
            gpsCoordinates.push(0)
        hover.gpsCoordinates = gpsCoordinates
        const gpsOrigin = get(descartes.config, ['defaults', 'gpsOrigin'])
        if (gpsOrigin) {
            hover.coordinates = geo2Pos(hover.gpsCoordinates, gpsOrigin)
        }
    }
    descartes.update({ hover });
}

// INFO
const sampleInfo = {
    "color": null,
    "layer": null,
    "viewport": {
        "id": "default-view",
        "x": 0,
        "y": 0,
        "width": 956,
        "height": 1056,
        "_frustumPlanes": {},
        "isGeospatial": true,
        "zoom": 20.60895250797127,
        "scale": 1599237.2026635373,
        "distanceScales": {
            "unitsPerMeter": [
                0.00002053284275904775,
                0.00002053284275904775,
                0.00002053284275904775
            ],
            "metersPerUnit": [
                48702.46228128116,
                48702.46228128116,
                48702.46228128116
            ],
            "unitsPerDegree": [
                1.4222222222222223,
                2.283138043457449,
                0.00002053284275904775
            ],
            "degreesPerUnit": [
                0.703125,
                0.4379936652825684,
                48702.46228128116
            ]
        },
        "focalDistance": 1.5,
        "position": [
            0,
            0,
            0
        ],
        "meterOffset": [
            0,
            0,
            0
        ],
        "longitude": 0.44999999999999746,
        "latitude": 51.470000000088426,
        "center": [
            256.64,
            341.6615080343574,
            0
        ],
        "viewMatrixUncentered": [
            1514.4291691889557,
            0,
            0,
            0,
            0,
            1514.4291691889557,
            0,
            0,
            0,
            0,
            1514.4291691889557,
            0,
            0,
            0,
            -1.5,
            1
        ],
        "viewMatrix": [
            1514.4291691889557,
            0,
            0,
            0,
            0,
            1514.4291691889557,
            0,
            0,
            0,
            0,
            1514.4291691889557,
            0,
            -388663.1019806536,
            -517422.15375631757,
            -1.5,
            1
        ],
        "projectionMatrix": [
            3.3138075313807533,
            0,
            0,
            0,
            0,
            3,
            0,
            0,
            0,
            0,
            -1.1413427561837457,
            -1,
            0,
            0,
            -0.2141342756183746,
            0
        ],
        "viewProjectionMatrix": [
            5018.526786601058,
            0,
            0,
            0,
            0,
            4543.287507566867,
            0,
            0,
            0,
            0,
            -1728.4827620071828,
            -1514.4291691889557,
            -1287954.7145132956,
            -1552266.4612689526,
            1.497879858657244,
            1.5
        ],
        "viewMatrixInverse": [
            0.0006603148039835659,
            0,
            0,
            0,
            0,
            0.0006603148039835659,
            0,
            0,
            0,
            0,
            0.0006603148039835659,
            0,
            256.63999999999993,
            341.6615080343573,
            0.0009904722059753488,
            0.9999999999999999
        ],
        "cameraPosition": [
            256.63999999999993,
            341.6615080343573,
            0.0009904722059753488
        ],
        "pixelProjectionMatrix": [
            2398855.8039953057,
            0,
            0,
            0,
            0,
            -2398855.8039953057,
            0,
            0,
            -723897.1428723208,
            -799618.6013317686,
            -1728.4827620071828,
            -1514.4291691889557,
            -615641636.5373553,
            819597483.550007,
            1.497879858657244,
            1.5
        ],
        "viewportMatrix": [
            478,
            0,
            0,
            0,
            0,
            -528,
            0,
            0,
            0,
            0,
            1,
            0,
            478,
            528,
            0,
            1
        ],
        "pixelUnprojectionMatrix": [
            4.1686540655528157e-7,
            0,
            0,
            0,
            0,
            -4.1686540655528157e-7,
            0,
            0,
            -1198.5003300330027,
            -1595.547966563087,
            -0.004625472513053195,
            -4.669966996699667,
            1367.8994707053316,
            1821.067333885419,
            0.004618934742716724,
            5.330033003300327
        ],
        "pitch": 0,
        "bearing": 0,
        "altitude": 1.5,
        "fovy": 36.86989764584402,
        "orthographic": false,
        "_subViewports": null,
        "_pseudoMeters": false
    },
    "index": -1,
    "picked": false,
    "x": 572,
    "y": 375,
    "pixel": [
        572,
        375
    ],
    "coordinate": [
        0.45004132829693744,
        51.4700419031905
    ],
    "devicePixel": null
}