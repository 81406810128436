const FFT = require('fft.js');

export const LONG_SYNC_SEQUENCE = [
    -0.0455, -1.0679, 0.3528, -0.9865,
    0.8594, 0.7348, 0.1874, 0.2475,
    0.5309, -0.7784, -1.0218, -0.4897,
    -0.3401, -0.9423, 0.8657, -0.2298,
    0.4734, 0.0362, 0.0088, -1.0207,
    -1.2142, -0.4205, 0.2172, -0.5195,
    0.5207, -0.1326, -0.1995, 1.4259,
    1.0583, -0.0363, 0.5547, -0.5547,
    0.3277, 0.8728, -0.5077, 0.3488,
    -1.1650, 0.5789, 0.7297, 0.8197,
    0.6173, 0.1253, -0.5353, 0.7214,
    -0.5011, -0.1935, -0.3110, -1.3392,
    -1.0818, -0.1470, -1.1300, -0.1820,
    0.6663, -0.6571, -0.0249, 0.4773,
    -0.8155, 1.0218, 0.8140, 0.9396,
    0.1090, 0.8662, -1.3868, -0.0000,
    0.1090, -0.8662, 0.8140, -0.9396,
    -0.8155, -1.0218, -0.0249, -0.4773,
    0.6663, 0.6571, -1.1300, 0.1820,
    -1.0818, 0.1470, -0.3110, 1.3392,
    -0.5011, 0.1935, -0.5353, -0.7214,
    0.6173, -0.1253, 0.7297, -0.8197,
    -1.1650, -0.5789, -0.5077, -0.3488,
    0.3277, -0.8728, 0.5547, 0.5547,
    1.0583, 0.0363, -0.1995, -1.4259,
    0.5207, 0.1326, 0.2172, 0.5195,
    -1.2142, 0.4205, 0.0088, 1.0207,
    0.4734, -0.0362, 0.8657, 0.2298,
    -0.3401, 0.9423, -1.0218, 0.4897,
    0.5309, 0.7784, 0.1874, -0.2475,
    0.8594, -0.7348, 0.3528, 0.9865,
    -0.0455, 1.0679, 1.3868, -0.0000,
]

// -32 -> 31
export const LONG_SYNC_FREQUENCY_DOMAIN = [
    0, 0, 0, 0, 0, 0,
    // next : -26
    1, 1, -1, -1, 1, 1, -1, 1, -1, 1, 1, 1, 1, 1, 1, -1, -1, 1, 1, -1, 1, -1, 1, 1, 1, 1, 0,
    1, -1, -1, 1, 1, -1, 1, -1, 1, -1, -1, -1, -1, -1, 1, 1, -1, -1, 1, -1, 1, -1, 1, 1, 1, 1,
    // next : 27
    0, 0, 0, 0, 0
]
const low = LONG_SYNC_FREQUENCY_DOMAIN.slice(32, 64)
const high = LONG_SYNC_FREQUENCY_DOMAIN.slice(0, 32)
const arranged = [...low, ...high]

const fft = new FFT(64)
const input = fft.toComplexArray(arranged)
const output = fft.createComplexArray()
fft.inverseTransform(output, input)
// console.log('frequency domain', input)
// console.log("time domain", output)
// fft.transform(input, output)
// console.log("frequency domain", input)
export const LONG_SYNC_TIME_DOMAIN = output
