export default {
    "name": "choreo-viewer",
    "canvas": ".descartes-canvas",
    "background": "#eeeeff",
    "defaults": {
        "gpsOrigin": {
            "latitude": 44.68180539011111,
            "longitude": -0.7091738936666666
        }
    },
    "actions":[
        "add_camera",
        "copy"
    ],
    "controls": {
        "cartes": true,
        "hover": true,
        "selection": true,
        "global": true,
        "player": true
    },
    "details": "selection",
    details: 'view',
    details: 'objects',
    "variables": {
        "choreo_url": {
            "type": "string",
            "default": "choreo.ndjson",
            "_default":"http://localhost/neighbors-bench/build/humanity/choreo.ndjson"
        },
        "choreo2_samples_url": {
            "type": "string",
            "default": "http://localhost:8080/choreo2.ndjson"
        },
        "choreo3_samples_url": {
            "type": "string",
            "default": "http://localhost:8080/choreo3.ndjson"
        },
        "anchors_url": {
            "type": "string",
            "default": "http://localhost:8080/anchors.json"
        },
        "fly_zone_url": {
            "type": "string",
            "default": "http://localhost:8080/fly_zone.obj"
        },
        "environment_url": {
            "type": "string",
            "default": "environment.obj"
        },
        "length_scale": {
            "type": "number",
            "default": 1.0
        }
    },
    "scales": [],
    "dataset": [
        {
            "name": "choreo-samples",
            "url": {
                "__var": "choreo_url"
            },
            //            "url": ["$choreo_samples_url", "$choreo2_samples_url", "$choreo3_sample"],
            // "format": "ndjson",
            // "schema": false,
            // expected : x,y,z,t,r,g,b,name|id[,label]

        },
        {
            name: 'anchor',
            url: {
                __var: "anchors_url"
            },
            transforms: [

                {
                    type: 'compute',
                    expr: `s=>['conn','sync'].map(prop=>""+prop+"="+s[prop]).join(',')`,
                    as: ['status']
                },
                {
                    type: 'compute',
                    expr: 's=>[0,200,0]',
                    as: ['color']
                },
                {
                    type: 'compute',
                    expr: 's=>(s.serial || s.tagSerial || "").toString()',
                    as: ['id']
                },
                {
                    type: 'compute',
                    expr: 's=>s.name',
                    as: ['label']
                },
                {
                    type: 'compute',
                    expr: 's=>0.3',
                    as: ['size']
                },
            ]
        },
    ],
    "cartes": [
        {
            "type": "helpers"
        },
        {
            "type":"tiles"
        },
        {
            "fast":true,
            "name": "choreo",
            "type": "stream",
            "table": "choreo-samples",
            "identifier": 'name',
            "time": 't',
            "solid": true,
            segmentSize: 50 * 1024 * 1024,
            // "label":true,
            "shape":"sphere",
            // "props": [
            //     { "key": 'position', "method": 'linear' },
            //     { "key": 'color', "method": 'linear' },
            // ]
        },
        {
            "name": 'anchors',
            "type": 'stream',
            "samples": "anchor",
            "shape": "cube",
            "solid": true,
            "position": "d=>[d.x, d.y, d.z]",
            "label": true,
            "color": [0, 200, 0],
            "optional": true
        },
        {
            name: 'environment',
            type: 'environment',
            url: "$environment_url",
            color: [100, 100, 100],
            "optional": true
        },
        {
            name: 'flight-zone',
            type: 'environment',
            url: "$fly_zone_url",
            color: [0, 0, 100],
            "optional": true
        },
    ]
}