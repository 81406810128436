export function computeExtent(samples) {
    let n = 0, nNumbers = 0, nStrings = 0, nNulls = 0
    for (let s of samples) {
        n++
        if (s === null || s === undefined) {
            nNulls++
        } else if (Number.isFinite(s))
            nNumbers++
        else if (typeof s === 'string') {
            nStrings++
        } else {
            throw `unable to compute domain with value ${typeof s === 'object' ? JSON.stringify(s) : s}`
        }
    }
    if (!n)
        return
    if (nNumbers + nNulls === n) {
        let min = Number.MAX_VALUE
        let max = - Number.MIN_VALUE
        for (let value of samples) {
            if (Number.isFinite(value)) {
                min = Math.min(min, value)
                max = Math.max(max, value)
            }
        }
        return [min, max]
    } else if (nStrings + nNulls + nNumbers === n) {
        let domain: string[] = []
        for (let value of samples) {
            if (typeof value === 'string' && !domain.includes(value))
                domain.push(value)
        }
        return domain
    } else {
        throw `unable to compute domain from mixed value types : ${[n, nNulls, nNumbers, nStrings]}`
    }
}

export function mergeExtents(domains){
    return Object.values(domains).reduce((prev: any, current: any) => {
        if (!Array.isArray(current))
            current = [current]
        current = (current || []).filter(v => v !== null && v !== undefined)
        if (!current.length)
            return prev

        const continuous = current.filter(v => Number.isFinite(v)).length === current.length
        if (continuous) {
            console.log('continuous color scale', current)
            let min = Math.min(...current)
            if (prev.length > 0)
                min = Math.min(prev[0], min)
            let max = Math.max(...current)
            if (prev.length > 1)
                max = Math.max(prev[1], min)
            return [min, max]
        } else {
            for (let item of current)
                if (prev.indexOf(item) < 0)
                    prev.push(item)
            return prev
        }
    }, []) as any[]
}