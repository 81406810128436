const linearAlgebra = require('linear-algebra')(),     // initialise it
    Vector = linearAlgebra.Vector,
    Matrix = linearAlgebra.Matrix;
// var jsRegression = require('js-regression');




// const MLR = require('ml-regression-multivariate-linear')
// const regression = require('regression')


const linSystem = require('linear-equation-system')


function get(obj, path) {
    if (typeof path == 'string')
        path = [path]
    let current = obj
    for (let part of path) {
        if (!current)
            return undefined
        if (Number.isFinite(part)) { // array indexing
            if (part < 0)
                part = current.length + part
        }
        current = current[part]
    }
    return current
}

function predict(model, input) {
    const { equation, predict } = model
    if (equation) {
        const [slope, y0] = model.equation
        return [y0 + slope * input[0]]
    }
    if (predict) {
        return model.predict(input)
    }
    throw `invalid model : ${JSON.stringify(model)}`
}

function createBaseFunction(config, index) {
    const { nVariables } = config
    if (index >= nVariables + 1)
        return false
    if (!index)
        return () => 1
    return (variables) => {
        return variables[index - 1]
    }
}

function computeVectorsStatistics(vectors) {
    const count = vectors.length
    const dim = vectors[0].length
    let totalVariance = 0
    let n2 = 0

    let min = vectors[0].map((_) => -Number.MAX_VALUE)
    let max = vectors[0].map((_) => Number.MAX_VALUE)
    let mean = vectors[0].map((_) => 0.0)
    let variance = vectors[0].map((_) => 0.0)

    for (let i = 0; i < count; i++) {
        const vector = vectors[i]
        let norm2 = 0
        for (let j = 0; j < dim; j++) {
            const coordinate = vector[j]
            min[j] = Math.min(min[j], coordinate)
            max[j] = Math.max(max[j], coordinate)
            mean[j] += coordinate
            norm2 += coordinate * coordinate
        }
        n2 += norm2
    }
    for (let j = 0; j < dim; j++) {
        mean[j] /= count
    }
    n2 / count

    for (let i = 0; i < count; i++) {
        const vector = vectors[i]
        for (let j = 0; j < dim; j++) {
            const coordinate = vector[j]
            const dx = (coordinate - mean[j])
            variance[j] += dx * dx
        }
    }
    for (let j = 0; j < dim; j++) {
        variance[j] /= count
        totalVariance += variance[j]
    }
    return {
        dim, count, mean, variance, totalVariance, n2 // autocorrelation ?
    }
}

function computeFitStatistics(fit) {
    const { model, inputVector, outputVector, dimin, dimout } = fit
    const inputStatistics = computeVectorsStatistics(inputVector)
    const outputStatistics = computeVectorsStatistics(outputVector)
    const errorVector = []
    for (let i = 0; i < inputVector.length; i++) {
        const prediction = predict(model, inputVector[i])
        let error = []
        for (let j = 0; j < dimout; j++)
            error.push(outputVector[i][j] - prediction[j])
        errorVector.push(error)
        // console.log(` ${prediction.map(v => v.toFixed()).join(',')} (error=${norm(error).toFixed(1)})`)
    }
    const errorStatistics = computeVectorsStatistics(inputVector)
    return {
        inputStatistics,
        outputStatistics,
        errorStatistics,
        r2: 1 - errorStatistics.n2 / outputStatistics.totalVariance,
    }
}

function fitModel(inputVector, outputVector, config) {
    const nVariables = inputVector[0].length
    const baseFunctions = []
    for (let idx = 0; idx < nVariables + 1; idx++) {
        baseFunctions.push(createBaseFunction({ ...config, nVariables }, idx))
    }
    const nExperiments = outputVector.length
    const nAlphas = baseFunctions.length
    const Y = [].concat(outputVector)
    const A = []
    for (let rowIndex = 0; rowIndex < nExperiments; rowIndex++) {
        const row = []
        for (let colIndex = 0; colIndex < nAlphas; colIndex++) {
            row.push(baseFunctions[colIndex](inputVector[rowIndex]))
        }
        A.push(row)
    }

    const matrixA = new Matrix(A)
    const matrixATranspose = (new Matrix(A)).trans()
    const vectorY = new Matrix(Y.map(value => [value]))
    const alphas = linSystem.solve(
        matrixATranspose.dot(matrixA).data,
        matrixATranspose.dot(vectorY).data
    )


    // statistics



    return {
        alphas,
        count: inputVector.length,
        dimin: inputVector[0].length,
        dimout: outputVector[0].length,
        predict(variables) {
            let result = 0
            for (let i = 0; i < baseFunctions.length; i++) {
                result += alphas[i] * baseFunctions[i](variables)
            }
            return [result]
        }
    }
}

function norm(v) {
    return Math.sqrt(norm2(v))
}

function norm2(v) {
    let e = 0.0
    for (let i = 0; i < v.length; i++) {
        const dx = v[i]
        e += dx * dx
    }
    return e
}

function computeError(predition, experiment, config = {}) {
    let e = 0.0
    for (let i = 0; i < predition.length; i++) {
        const dx = predition[i] - experiment[i]
        e += dx * dx
    }
    return Math.sqrt(e)
}

function assertVector(v, dim) {
    const numbers = v.filter(n => Number.isFinite(n))
    if (numbers.length !== v.length)
        throw `some components are not numbers : ${JSON.stringify(v)}`.slice(0, 50)
    if (v.length !== dim)
        throw `invalid vector dimension : ${JSON.stringify(v)}`.slice(0, 50)
}
export default [
    {
        name: 'model',
        apply(samples, tr, dataset) {
            let { input, output, groupby, base } = tr
            groupby = groupby || []
            if (!this.perGroup) {
                this.dimin = input.length
                this.dimout = output.length
                this.perGroup = {}

            }

            // const regression = new jsRegression.LinearRegression(
            //     // {
            //     //     alpha: 0.001,
            //     //     iterations: 300,
            //     //     lambda: 0.0
            //     // }
            // )
            for (let sample of samples) {
                const group = (groupby).map(prop => get(sample, prop))
                const groupKey = group.join(':')
                if (!this.perGroup[groupKey]) {
                    this.perGroup[groupKey] = {
                        dimin: this.dimin,
                        dimout: this.dimout,
                        inputVector: [], // nsamples x dimin
                        outputVector: [], // nsamples x dimout
                        model: null,
                        group
                    }
                }
                const fit = this.perGroup[groupKey]
                const inputValue = input.map(p => get(sample, p))//.slice(0, 1)
                const outputValue = output.map(p => get(sample, p))
                assertVector(inputValue, this.dimin)
                assertVector(outputValue, this.dimout)
                fit.dirty = true
                fit.inputVector.push(inputValue)
                fit.outputVector.push(outputValue)
                // console.log(`${inputValue.map(a => a.toFixed()).join(',')} -> ${outputValue.map(a => a.toFixed()).join(',')}`)
                // experiment.push([...inputValue, outputValue[0]].map(v => v + Math.random() * 0.0))
            }
            let result = []
            for (let group in this.perGroup) {
                const fit = this.perGroup[group]
                if (fit.dirty) {
                    const { inputVector, outputVector } = fit
                    const model = fitModel(inputVector, outputVector, {})
                    fit.model = model
                    fit.statistics = computeFitStatistics(fit)
                    fit.dirty = false

                }
                let outputSample = { ...fit.model, ...fit.statistics }
                for (let key in outputSample)
                    if (typeof outputSample[key] === 'function')
                        delete outputSample[key]
                for (let i = 0; i < groupby.length; i++) {
                    outputSample[groupby[i]] = fit.group[i]
                }

                result.push(outputSample)
            }
            // console.log(experiment)
            // const model1 = regression.linear(experiment.map(l => [l[0], l[l.length - 1]]));
            // const model2 = new MLR(inputVector, outputVector)
            // const model3 = fitModel(inputVector, outputVector, {})
            // const models = [
            //     model1,
            //     model2,
            //     model3
            // ]


            return result
        },
        schema: {
            type: 'object',
            properties: {
                input: { type: 'array' }, output: { type: 'array' }, groupby: { type: 'array' }, base: { type: 'array' }
            },
            required: ['input', 'output']
        }
    },
    {
        name: 'predict',
    }
].map(t => ({ ...t, name: 'fit:' + t.name })) as any[]