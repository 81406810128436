import { html, render } from "lit";
import { get, set } from "object-path-immutable";
import Descartes from "..";
import { mulMV } from "../utils/algebra/mat";
import { rendercontextDetails } from "./context-details";
import { renderDebug } from "./hovered-state";
import { renderObjectsDetails, renderSummary } from "./objects-details";
import { renderSelectionDetails } from "./selection-details";
import { renderCarteDetails } from "./carte-details";
import { renderViewDetails } from "./view-details";
import {download} from 'web-ui-blocks/io'
import {until} from 'lit/directives/until';

import "./details.css"

const renderSelection = (state) =>
  state.selection.length
    ? html`<div>${state.selection.length} selected</div>`
    : "";

const renderHover = (state) =>
  state.hover && state.hover.object
    ? html`<div class="hover">
  <pre>${JSON.stringify(state.hover.object, null, 2)}</pre>
</div>`
    : "";

const renderDetails = (details, update) => {
  if (!details) return;
  const { name, value } = details;
  return html`<h1>${name}</h1>
<pre>${JSON.stringify(value, null, 2)}</pre>
<div>
  <button @click="${(e) => {
      console.log(" confirm"); update({ details: null }); }}">
    confirm
  </button>
  <button @click="${(e) => {
      console.log(" cancel"); update({ details: null }); }}">
    cancel
  </button>
</div>`;
};

const DETAILS = [
  {
    name: 'carte',
    get(context, descartes){
      const currentCarteName = context.carte
      const cm = descartes.carteManagers.filter(cm => cm.name === currentCarteName)[0]
      let carteState
      if (cm) {
          carteState = cm.getState()
      }
      return carteState
    },
    render(context, descartes){

    }
  }
]

function downloadDetails( context, descartes){
  const details = DETAILS.filter(d=>d.name === context.details)[0]
  if(!details){
    throw `unknown details : ${context.details}`
  }
  const value= details.get(context, descartes)
  download(details.name+'.json', JSON.stringify(value, null, 2))
}


function renderDetailsTab(context, descartes: Descartes) {
  return html`<div class="row tabs">
  ${["view", "context", "selection", "carte", "objects", "variables",""].map(kind => html`<div
    class="${context.details === kind ? 'selected' : ''}" @click=${e=> descartes.update({
    details:
         kind
  })}>${kind || 'close'}</div>`)}
  <div @click=${e=>downloadDetails(context, descartes)}>download</div>
</div>`
}


function renderVariablesDetails(context, descartes: Descartes) {
  return html`<div class="column">
  ${["droll", "dpitch", "dyaw"].map(variable => {
    const currentValue = context.variables[variable] || 0
    const update =  (v)=>descartes.update({variables: set(context.variables , [variable], Number(v))})
    return html`<div
    class="row" @input=${e=> update(e.target.value)}>
    <div>${variable}</div>
    <input type="range" min="-90" max="90" step="5" value=${currentValue} />
    <div>${currentValue}</div>
  </div>`
  })}
</div>`
}



let lastContent = html`<div>loading ...</div>`, updatePromise = null
async function updateCurrent(currentDetails, descartes: Descartes){
  let ti = Date.now()
  let content: any = ''
  // console.log('updating current')
  try{
    const configDetails: any = get(descartes, ['config', 'details']);
    const {context} = descartes
    switch (currentDetails) {
      case 'none':
        break;
      case 'view':
        content = await renderViewDetails(context, descartes)
        break;
      case 'context':
        content = await rendercontextDetails(context, descartes)
        break;
      case 'selection':
        content = await renderSelectionDetails(context, descartes)
        break;
      case 'carte':
        content = await renderCarteDetails(context, descartes)
        break;
      case 'variables':
          content = await renderVariablesDetails(context, descartes)
          break;
      case 'objects':
        content = html`
        <section>${await renderObjectsDetails(context, descartes)}</section>
        ${context.selection.length === 1 ? html`<section>${await renderSelectionDetails(context, descartes)}</section>` : ''}
        <section style="flex:unset">${renderSummary(context, descartes)}</section>        
        `
        break;
      default:
        console.error(`invalid details : ${currentDetails}`)
    }
    if(configDetails === 'tabs')
      content = html`${renderDetailsTab(context, descartes)}${content}`
  
  }catch(err){
    content  = html`<div>error<br>${err.toString()}</div>`
  }

  lastContent = content
  updatePromise = null
  let tf = Date.now()
  // console.log('right panel update took', (tf-ti)+'ms')

}
export default function renderCurrent(container, context, descartes: Descartes) {
  const configDetails: any = get(descartes, ['config', 'details']);
  const currentDetails: any = configDetails === 'tabs' ?  context.details : configDetails ;
  if (!currentDetails) {
    container.style.display = 'none'
    return
  }else{
    container.style.display = 'flex'
  }


  if(!updatePromise){
    updatePromise = updateCurrent(currentDetails, descartes)
  }

  if(lastContent){
    container.style['display'] = 'flex'
  }else{
    container.style['display'] = 'none'
  }
  return render(lastContent , container)
  }
