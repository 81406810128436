import { modulus } from "./complex"

const BPSK_CONSTELLATION = [
    {
        name: "1",
        i: 1,
        q: 0,
        bit: 1
    },
    {
        name: "0",
        i: -1,
        q: 0,
        bit: 0
    }
]


export function decodeConstellationPoint(point, constellation = BPSK_CONSTELLATION) {
    let bestScore = Number.MAX_VALUE, bestConstellationPoint = null
    for (let c of constellation) {
        const score = modulus({
            i: point.i - c.i,
            q: point.q - c.q
        })
        if (score < bestScore) {
            bestScore = score
            bestConstellationPoint = c
        }
    }
    let confidence = 0.8
    if (point.n < 0.02)
        confidence = 0.5
    return {
        ...point,
        point: bestConstellationPoint,
        score: bestScore,
        bit: bestConstellationPoint.bit,
        confidence
    }
}
