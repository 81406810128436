import { render, html } from 'lit'
import * as d3 from 'd3'
import { scaleFunction } from './scale'
import { colorFromRGBArray } from '../colors'
import { addDistributionToLegend } from './distribution'
function legendFromScaleState(state, container) {
    const { type } = state
    switch (type) {
        case 'ordinal':
            return buildOrdinalColorScaleLegend(state)
        case 'continuous':
            return buildNumericColorScaleLegend(state)
        default:
            throw `unable to build legend frome scale of type ${type}`
    }

}
export function renderLegend(scaleState, container) {
    let legendContainer = container.querySelector(`#scale-legends`)
    if (!legendContainer) {
        legendContainer = document.createElement('div')
        legendContainer.id = 'scale-legends'
        container.appendChild(legendContainer)
    }
    // prevent recomputation
    const rendered = legendFromScaleState(scaleState, legendContainer)
    render(rendered, legendContainer)
}


function buildColorScaleLegendBasis(config) {
    let { title, height, width } = config

    const container = document.createElement('div')
    container.classList.add('color-legend')
    const r = d3.select(container)
    r.append('div').attr("class", 'legend-title')
    r.append('svg').attr("class", 'legend-svg').attr('width', width)
        .attr('height', height)

    var legendTitle = r.select(".legend-title")

    legendTitle.html(title)

    return container
}


const defaultLegendConfig = {
    height: 450,
    width: 600,
    margin: { top: 10, bottom: 20, left: 10, right: 20 },
}

export function buildOrdinalColorScaleLegend(scaleState) {
    let { domain, range, legend, name, config } = scaleState
    legend = legend || defaultLegendConfig
    if (!legend.title)
        legend = { ...legend, title: name }
    const scale = scaleFunction(scaleState)
    let { width, height, margin } = legend
    let legendWidth = width - margin.left - margin.right;
    let legendHeight = height - margin.top - margin.bottom;
    let container = buildColorScaleLegendBasis(legend)
    let legendSvg = d3.select(container).select('svg')
    const axisWidth = 0.75 * legendWidth
    const scaleWidth = legendWidth - axisWidth

    let unit = Math.floor(legendHeight / domain.length)

    var items = legendSvg.selectAll(".item")
        .data(domain)
        .enter().append("g").attr("class", "item").attr("transform", (d, i) => `translate(${margin.left}, ${margin.top + i * unit})`)

    items.append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("height", unit)
        .attr("width", scaleWidth)
        .style("fill", function (d, i) {
            const color = scale(d)
            const s = colorFromRGBArray(color)
            return s;
        })

    items.append("text")
        // .attr('x', 0).attr("y", unit / 2)
        .attr("transform", ` translate(${scaleWidth + 10},${unit / 2}) rotate(0)`)
        .attr('text-anchor', 'left')
        .attr('text-align', 'bottom')
        .text(d => d as any)

    return container
}


const linspace = function (start, stop, npoints) {
    const delta = (stop - start) / (npoints - 1)
    return d3.range(start, stop + delta, delta).slice(0, npoints)
}
export function buildNumericColorScaleLegend(scaleState) {
    let { domain, range, legend, name, config } = scaleState
    legend = legend || defaultLegendConfig
    if (!legend.title)
        legend = { ...legend, title: name }
    const scale = scaleFunction(scaleState) as any
    let { width, height, margin } = legend
    let legendWidth = width - margin.left - margin.right;
    let legendHeight = height - margin.top - margin.bottom;
    let container = buildColorScaleLegendBasis(legend)
    let legendSvg = d3.select(container).select('svg')
    // container.addEventListener('click', (e)=>{
    //     console.log('clicked on legend', e)
    // })
    // legendSvg.style['z-index'] = 200

    // const scaleWidth = 40 // legendWidth - axisWidth
    // const axisWidth = legendWidth - scaleWidth //0.55 * legendWidth

    const distributionPos = 0
    const distributionWidth = 60
    const scalePos = distributionPos + distributionWidth
    const scaleWidth = 5
    const axisPos = scalePos + scaleWidth
    const axisWidth = legendWidth - scaleWidth - distributionWidth
    let [min, max] = domain


    // append gradient bar
    var gradient = legendSvg.append('defs')
        .append('linearGradient')
        .attr('id', 'gradient')
        .attr('x1', '0%') // bottom
        .attr('y1', '100%')
        .attr('x2', '0%') // to top
        .attr('y2', '0%')
        .attr('spreadMethod', 'pad');

    // programatically generate the gradient for the legend
    // this creates an array of [pct, colour] pairs as stop
    // values for legend
    let intermediate = [0, 0.25, 0.5, 0.75, 1]
    var pct = linspace(0, 100, intermediate.length).map(function (d) {
        return Math.round(d) + '%';
    })


    var colourPct = d3.zip(pct, intermediate.map(a => scale(min + a * (max - min))).map(c => colorFromRGBArray(c)))

    colourPct.forEach(function (d) {
        gradient.append('stop')
            .attr('offset', d[0])
            .attr('stop-color', d[1])
            .attr('stop-opacity', 1);
    });

    let group = legendSvg.append('g').attr('transform', `translate(${margin.left},${margin.top})`)
    group.append('rect')
        .attr('x', scalePos)
        .attr('y', 0)
        .attr('width', scaleWidth)
        .attr('height', legendHeight)
        .style('fill', 'url(#gradient)');


    // create a scale and axis for the legend
    var legendScale = d3.scaleLinear()
        .domain([min, max])
        .range([legendHeight, 0]);

    var legendAxis = d3.axisRight()
        .scale(legendScale)
    //.tickValues(d3.range(min, max + 1))
    switch (config.format) {
        case 'simple':
            break;
        default:
            legendAxis.tickFormat(d3.format(".2e"));
            break;
    }


    group.append("g")
        .attr("class", "legend axis")
        .attr("transform", `translate(${axisPos}, 0)`)
        .call(legendAxis);

    const { distribution } = scaleState
    let maxCount = distribution.reduce((prev, curr) => Math.max(prev, curr.count), 0)
    const xScale = d3.scaleLinear().domain([0, maxCount]).range([0, distributionWidth])
    const yScale = d3.scaleLinear().domain(scaleState.domain).range([legendHeight, 0])
    var items = legendSvg.selectAll(".distribution-item")
        .data(distribution)
        .enter().append("g").attr("class", ".distribution-item").attr("transform", (d, i) => `translate(${margin.left}, ${margin.top})`)
    items.append("rect")
        .attr("x", d => distributionWidth - xScale(d.count))
        .attr("y", d => yScale(d.domain[0]))
        .attr("height", d => yScale(d.domain[0]) - yScale(d.domain[1]))
        .attr("width", d => xScale(d.count))
        .style("fill", function (d, i) {
            const mid = (d.domain[0] + d.domain[1]) / 2
            const color = scale(mid)
            const s = colorFromRGBArray(color)
            return s;
        })
    return container
}