export function colorToRGBArray(color = "ff0000") {

    if (Array.isArray(color)) return color;
    if (color.startsWith('#'))
        color = color.slice(1)
    if (color.startsWith('rgb')) {
        return color.slice(4).split(')')[0].split(',').map(n => Number(n))
    }
    const r = color.slice(0, 2);
    const g = color.slice(2, 4);
    const b = color.slice(4, 6);
    const a = color.slice(6, 8) || "0";
    return [r, g, b, a].map((v) => parseInt(v, 16));
}

export function colorFromRGBArray(color = [0, 0, 0]) {
    return '#' + color.slice(0, 3).map(n => n.toString(16).padStart(2, '0')).join('')
}

export function paletteFromHex(hex) {
    var n = hex.length / 6 | 0, colors = new Array(n), i = 0;
    while (i < n) colors[i] = "#" + hex.slice(i * 6, ++i * 6);
    return colors;
}



export const COLOR_SCHEMES = [
    {
        name: "",
        palette: paletteFromHex("1f77b4ff7f0e2ca02cd627289467bd8c564be377c27f7f7fbcbd2217becf")
    }
]