import { getVariable } from "~/simple-tool/src/variables"


let baseFetch = (!global || typeof global.fetch === 'undefined') ? () => { throw `no fetch binded` } : global.fetch


const api: any = {}
let isCacheDisabled = true
let baseUrl

export function bindFetch(_fetch) {
    baseFetch = _fetch
}

export function getBaseUrl() {
    return baseUrl
}

export function setBaseUrl(_baseUrl) {
    if (!_baseUrl.endsWith('/'))
        _baseUrl += '/'
    baseUrl = _baseUrl
}

export function cacheDisabled() {
    return cacheDisabled
}
export function setCacheDisabled(bool = true) {
    isCacheDisabled = bool
}

export function defineFetch(_fetch) {
    api.fetch = _fetch
}

export function localFetch(url, init?) {
    if (isCacheDisabled)
        init = { cache: 'no-cache', ...init }
    if (!url.includes('://'))
        url = (baseUrl || "") + url
    if (getVariable('verbose', false))
        console.log('fetch function', url, init)
    return (api.fetch || baseFetch)(url, init)
}

export function checkResponse(response: Response) {
    if (!response.status.toString().startsWith("2"))
        throw `request to ${response.url} returned code ${response.status} `
}

const CHUNK_SIZE = 1024 * 1024 * 5

async function tryFetchWithFeature(url, baseInit, trialInit): Promise<Response> {
    let response: Response
    let fullInit = { ...baseInit, ...trialInit }
    try {
        response = await localFetch(url, fullInit)
    } catch (err) {
        console.error(url, fullInit)
        console.error(err)
        throw `failed to fetch ${url} : ${err.toString()}`
        console.log('failed to load range : fallback to full load')
        response = await localFetch(url, baseInit)
    }
    checkResponse(response)
    return response
}


export async function loadRange(url, offset, size = CHUNK_SIZE, init: any = {}) {
    if (!init) init = {}
    const headers = { ...(init.headers || {}), 'range': `bytes=${offset}-${offset + size - 1}` }
    const response = await tryFetchWithFeature(url, init, { headers })
    return response

}



export async function getMetadata(cfg) {
    if (typeof cfg === 'string')
        cfg = { url: cfg }
    const { url, init, transforms, format } = cfg

    // const response = await localFetch(url, { ...(init || {}), ...({ method: 'HEAD', mode: 'cors' }) })
    const response = await tryFetchWithFeature(url, { ...init, mode: 'cors' }, { method: 'HEAD' })
    let mimeType, size, lastModified, cacheControl
    let headers = {}
    const rHeaders: any = response.headers
    for (let [k, v] of rHeaders.entries()) {
        headers[k] = v
        switch (k.toLowerCase()) {
            case 'content-length':
                size = Number(v)
                break;
            case 'content-type':
                mimeType = v
                break;
        }
    }
    return { mimeType, size, headers }
}