// TODO : handle contiguous chunks (don't throw first line) : lineStarts dict

import { getMetadata, loadRange } from "../../http-utils"
import { DataChunk } from "../chunk"
const getUrl = (spec) => spec.ndjson || spec.url || spec.file




export async function getChunkAt(spec, offset, size): Promise<DataChunk> {
    const fullSize = await getSize.bind(this || {})(spec)
    if (offset + size > fullSize)
        size = fullSize - offset
    const response = await loadRange(getUrl(spec), offset, size)
    const tFirstByte = Date.now()
    const text = await response.text()
    let lines = text.split("\n")
    const tLoaded = Date.now()

    let before = 0, after = 0
    if (offset) {
        const first = lines[0]
        before = first.length
        lines = lines.slice(1)
        // console.log('before', before, first)
    }
    if ((!fullSize && size) || (offset + size < fullSize)) {
        const last = lines.pop()
        after = last.length + 1
        // console.log('after', after, last)
    }
    size = size - after - before
    // console.log('parsing csv chunk', lines)
    const samples: any = []
    let nPerLine = 0
    for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim()
        if (!line)
            continue
        let jsonString = line
        let boundary = null
        if (line.includes('{')) {
            const objectBoundary = [line.indexOf('{'), line.lastIndexOf('}')]
            if (!boundary || objectBoundary[0] < boundary[0])
                boundary = objectBoundary
        }
        if (line.includes('[')) {
            const arrayBoundary = [line.indexOf('['), line.lastIndexOf(']')]
            if (!boundary || arrayBoundary[0] < boundary[0])
                boundary = arrayBoundary
        }
        if (line.includes('"')) {
            const stringBoundary = [line.indexOf('"'), line.lastIndexOf('"')]
            if (!boundary || stringBoundary[0] < boundary[0])
                boundary = stringBoundary
        }
        if (boundary)
            jsonString = line.slice(boundary[0], boundary[1] + 1)
        let json = JSON.parse(jsonString)
        samples.push(json)
    }
    return { samples: () => samples, offset: offset + before, size, tFirstByte, tLoaded, nrows: samples.length }
}

export async function getSize(spec) {
    if (!this.metadata) {
        this.metadata = await getMetadata(getUrl(spec))
    }
    return this.metadata.size
}