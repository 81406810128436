/*
    json files : 
    * telemetry (array)
    * spec
    * geojson

*/

import Descartes from "..";

function nameFromFilename(filename) {
    let name = filename;
    for (let separator of [".", "-"]) {
        const parts = name.split(separator);
        name = parts[0];
    }
    return name;
}

const FILE_HANDLERS = [
    {
        type: "srteam",
        filter: async (file) => {
            if (file.name.endsWith('ndjson')) {
                // should test first lines contain x,y,z,t,name|id
                return true
            }
            if (
                ["application/x-fme-vectordata", "application/json"].indexOf(
                    file.type
                ) >= 0
            ) {
                const beginning = await file.slice(0, 10).text();
                if (beginning.trim()[0] !== "[") return false;

                return true;
            }
            return false;

        },
        handle: async (file, descartes: Descartes) => {
            const parts = file.name.split(".")
            parts.pop()
            const carteSpec = {
                type: 'stream',
                url: URL.createObjectURL(file)
            }
            await descartes.addCarte(parts.join("."), carteSpec);
        }
    },
    {
        type: "descartes",
        filter: async (file) => {
            if (!file.name.endsWith('json')) {
                return false
            }
            if (file.size > 100000) {
                throw `file is too large`
            }
            const txt = await file.text()
            const json = JSON.parse(txt)
            if (typeof json === 'object' && Array.isArray(json.cartes))
                return true;

        },
        handle: async (file, descartes: Descartes) => {
            const txt = await file.text()
            const json = JSON.parse(txt)
            await descartes.reset(json)
        }
    }
];

export default async function importFiles(files: File[], descartes: Descartes) {
    const vizus = [];
    for (let file of files) {
        const name = nameFromFilename(file.name);
        let ok = false
        for (let { type, filter, handle } of FILE_HANDLERS) {
            try {
                let valid: any = filter(file);
                if (valid.then) valid = await valid;
                if (valid) {
                    const h: any = handle(file, descartes);
                    if (h && h.then)
                        await h
                    ok = true
                    break;
                }
            } catch (err) {
                console.log("failed to handle file", file.name, "as", type);
                console.log(err);
            }
        }
        if (!ok)
            console.log("no handler found for file", file);
    }

    return vizus;
}
