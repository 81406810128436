import DescartesController from "./controller";
import { Deck, OrthographicView, OrbitView, MapView, FirstPersonView } from "@deck.gl/core";

export type CameraType = 'FirstPerson' | 'Map'

export type Camera = {
    id: string,
    type: CameraType,
    state: any,
    parameters: any
}
export function createCameraView(camera: Camera) {
    const { id } = camera
    switch (camera.type) {
        case 'FirstPerson':
            return new FirstPersonView({ id, controller: true })
        case '_Map':
            return new OrbitView({
                id,
                controller: { type: DescartesController, doubleClickZoom: false, inertia: true }
            })
        case 'Map':
            return new MapView({
                id,
                controller: { type: DescartesController, doubleClickZoom: false, inertia: true }
            })
        default:
            throw `invalid camera ${camera.type} ${JSON.stringify(camera.type)}`
    }
}