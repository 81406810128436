import { ArrowLoader } from '@loaders.gl/arrow';
import { load } from '@loaders.gl/core';

// import arrowUrl from 'url:../test-data/got.arrow'
// (async function testArrow() {
//     console.log('testing arrow loader')

//     const options = {}
//     const data = await load(arrowUrl, ArrowLoader, options);

//     console.log(data)
// })