export const defaultGpsOrigin = {
    latitude: 44.837789,
    longitude: -0.57918,
}

export const defaultInitialViewState = {
    //altitude: 10,
    near: 1e-4,
    far: 10000,
    zoom: 15,
    bearing: 0,
    pitch: 0,
    maxZoom: 24,
    minZoom: 0,

    orthographic: true
    // maxPitch: 90,
}

const sanFranciscoViewState = {
    longitude: -122.4,
    latitude: 37.8,
    zoom: 12,
    pitch: 0,
    bearing: 0
}
export const SELECTED_COLOR = [100, 255, 100];
export const HOVERED_COLOR = [100, 255, 100]