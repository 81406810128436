// TODO : get header line
// TODO : get stream from response
// TODO : handle contiguous chunks (don't throw first line) : lineStarts dict

import { checkResponse, getMetadata, localFetch } from "../../http-utils"
const getUrl = (spec) => spec.json || spec.url || spec.file



export async function loadJson(spec, init?) {
    console.log("loading json",)
    const response = await localFetch(getUrl(spec), init)
    checkResponse(response)
    let json = await response.json()
    const samples = Array.isArray(json) ? json : [json]
    return samples
}

export async function getChunkAt(spec, offset, size) {
    if (!this.samples) {
        this.samples = await loadJson(spec)
    }
    size = await getSize.bind(this)(spec)
    const nrows = this.samples.length
    return { samples: () => this.samples, offset: 0, size, nrows }
}

export async function getSize(spec) {
    if (!this.metadata) {
        this.metadata = await getMetadata(getUrl(spec))
    }
    return this.metadata.size
}