import { get } from "object-path-immutable"
export function buildFunction(spec) {
    const fn = new Function("", `return (${spec})`)
    return fn()
}
export function buildGetter(spec, len = 3) {
    if (!spec) return
    if (typeof spec === 'string') {
        if (spec.includes("=>")) {
            return buildFunction(spec)
        } else {
            const path = spec.split('.')
            return (sample) => get(sample, path)
        }
    } else if (Array.isArray(spec)) {
        if (spec.length !== len)
            throw `getter must have length of ${len}`
        if (spec.filter(Number.isFinite).length === len)
            return () => spec
        const getters = spec.map(s => buildGetter(s, 1))
        return (sample) => getters.map(g => g(sample))
    } else if (len === 1) {
        return () => spec
    }
    throw `invalid getter : ${spec}`
}