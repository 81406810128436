import Descartes from "."


export const BUILTIN_ACTIONS = {
    'add_camera': {
        name: 'add_camera',
        text: "Add camera",

        async perform(descartes: Descartes, event?) {

            console.log('adding camera', descartes.context)
            const { cameras } = descartes.context
            let idx = 1, name
            do {
                name = `camera-${idx++}`
            } while (cameras.find(c => c.id === name))
            const newCamera = {
                id: name
            }
            descartes.update({ cameras: [...cameras, newCamera] })
        }
    },
    'copy': {
        name: 'copy',
        text: "Copy coordinates",

        async perform(descartes: Descartes, evt?) {
            console.log(...arguments)
            const { context, actions } = descartes;

            const view = descartes.deck.getViews()[0];
            const viewport = descartes.getViewport();
            const px = evt.clientX;
            const py = evt.clientY;
            let [longitude, latitude] = viewport.unproject([px, py]);
            let coords = { longitude, latitude };
            const json = { longitude, latitude, px, py }

            await navigator.clipboard.writeText(JSON.stringify(json))

        }
    }
}