import { buildFunction } from "~/data-pipe/util"
let initialRawVariables = {}
let rawVariables = {}
let declarations = {}


export function resolveUrl(url) {
    if (url.includes('://'))
        return url
    let baseUrl = getVariable('base_url')
    if (!baseUrl.endsWith('/'))
        baseUrl += '/'
    // let relativeUrl = url.split('./').slice(1).join('./')
    url = baseUrl + url
    console.log('resolved url', url)
    return url
}

export function setupVariables(vars?) {
    if (!vars)
        vars = initialRawVariables
    // console.log('setup variables', vars)
    rawVariables = vars
    initialRawVariables = { ...vars }
    let { base_url, url } = vars
    if (!base_url && url) {
        const parts = url.split('/')
        parts.pop()
        base_url = [...parts, ''].join('/')
    }
    if (base_url)
        setBaseUrl(base_url)
}


function envsubst(input, env) {
    let v = false//input.includes("$PATH")
    if (v) {
        console.log('========')
        console.log('envsubst', input, env)
    }

    const regex = /\$(?:(\w+)|{(\w+)})/g;


    const result = input.replaceAll(regex, (original, g1, g2) => {
        if (v)
            console.log(original, g1, g2)
        const variable = g1 || g2;
        const value = env[variable] !== undefined
            ? JSON.stringify(env[variable])
            : original
        if (v)
            console.log(variable, value, env[variable], env)
        return value;
    });
    if (v)
        console.log(result)
    try {
        let parsedString = JSON.parse(result)
        if (typeof parsedString === 'string')
            return parsedString
    } catch (err) { }
    return result
}

export function specialize(spec, variables = {}) {
    declareVariables(variables, "spec")
    let values = {}
    for (let key in variables) {
        values[key] = getVariable(key)
    }
    let specialized = spec
    for (let i = 0; i < 2; i++)
        specialized = JSON.parse(JSON.stringify(specialized, (k, v) => {
            if (v === null) {
                return v
            } else if (typeof v === 'string' && v.includes('$')) {
                return envsubst(v, values)
            } else if (k === 'url' && typeof v === 'string') {
                return resolveUrl(v)
            } else if (typeof v === 'object') {
                const { __var, __expr } = v
                if (__var) {
                    const value = values[__var]
                    return value
                } else if (__expr) {
                    const fun = buildFunction(__expr)
                    try {
                        const result = fun(values)
                        return result
                    } catch (err) {
                        throw `failed to evaluate ${__expr} : ${err.toString()}`
                    }
                }
            } else if (typeof v === 'string') {
                let replaced = envsubst(v, values)
                return replaced
            }
            return v
        }))
    return specialized
}



export function setVariable(name, value) {
    rawVariables[name] = value
}



function coerceVariable(str = '', schema) {
    const { type } = schema
    if (!type)
        throw `unable to coerce value without schema : ${str}`
    let value: any = str
    if (type === 'number')
        return Number(value)
    else if (type === 'array') {
        const parts = value.split(',')
        return parts.map(part => coerceVariable(part, schema.items || { type: 'string' }))
    } else if (type === 'boolean') {
        if (["0", "false"].indexOf((value || "").toString().toLowerCase()) >= 0)
            return false
        return Boolean(value)
    } else {
        return value
    }
}



import Ajv from "ajv"
import addFormats from "ajv-formats"
import { setBaseUrl } from "~/data-pipe/http-utils"

const ajv = new Ajv({ allErrors: true, verbose: true, strictSchema: false, coerceTypes: 'array' })
addFormats(ajv)
export function getVariable(name, strict = true) {
    let schema = getVariableSchemas()[normalizeVariableName(name)]
    if (!schema) {
        if (strict)
            throw `unable to get undeclared variable : ${name}`
        else
            schema = { default: 0 }
    }
    // console.log('getting variable', name, schema, rawVariables)
    let value = rawVariables[name]
    if (value === undefined)
        value = (schema || {}).default
    if (value !== undefined) {
        if (value === null)
            value = ""
        value = value.toString()
    }
    let { type } = schema
    if (!type && schema.default) {
        type = typeof schema.default
        if (type === 'object' && Array.isArray(schema.default))
            type = 'array'
    }
    if (type) {
        schema = { ...schema, type }
        // console.log('coerce ', value, 'to', schema)
        value = coerceVariable(value, schema)
        if (!ajv.validate(schema, value)) {
            console.log("VALIDATION ERROR")
            console.log(ajv.errorsText(ajv.errors))
            console.log(ajv.errors)
            throw `invalid variable ${name} : ${value}. See console for details`
        }
    }
    if (value === undefined)
        throw `variable ${name} is undefined`
    if (0) {
        console.log('getVariable', name)
        console.log('raw', rawVariables[name])
        console.log('schema', schema)
        console.log('final', value)
    }

    return value
}

export function declareVariables(schemas, source = "") {
    declarations[source] = {}
    for (let key in schemas)
        declarations[source][normalizeVariableName(key)] = { ...schemas[key], source }
}

function normalizeVariableName(key) {
    return key
}

export function getVariableSchemas() {
    let schemas = {}
    for (let source in declarations) {
        for (let key in declarations[source]) {
            if (schemas[key]) {
                const message = `multiple variable declarations for ${key} : ${JSON.stringify(schemas[key])} and ${JSON.stringify(declarations[source][key])}`
                throw message
                // console.error(message)

            }
            schemas[key] = declarations[source][key]
        }
    }
    return schemas

}

export function getRawVariables() {
    return rawVariables
}

export function getCurrentURL(preset: any = {}) {
    let url = new URL(document.URL)
    url.searchParams.set('preset', preset.name)
    for (let name in rawVariables) {
        if (rawVariables[name])
            url.searchParams.set(name, rawVariables[name])
    }
    return url.href
}
