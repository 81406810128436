{
    "$schema": "http://json-schema.org/draft-07/schema",
    "$id": "https://descartes.beap.ovh/schema.json",
    "title": "Descartes configuration",
    "type": "object",
    "$defs": {
        "GpsPoint": {
            "type": "object",
            "required": [
                "longitude",
                "latitude"
            ],
            "additionalProperties": true,
            "properties": {
                "longitude": {
                    "type": "number",
                    "minimum": -180,
                    "maximum": 180
                },
                "latitude": {
                    "type": "number",
                    "minimum": -90,
                    "maximum": 90
                },
                "altitude": {
                    "type": "number"
                }
            }
        },
        "Pose": {
            "type": "object",
            "properties": {
                "x": {
                    "type": "number"
                },
                "y": {
                    "type": "number"
                },
                "z": {
                    "type": "number"
                },
                "roll": {
                    "type": "number"
                },
                "pitch": {
                    "type": "number"
                },
                "yaw": {
                    "type": "number"
                }
            }
        },
        "Dataset": {
            "anyOf": [
                {
                    "type": "string"
                },
                {
                    "type": "object"
                }
            ]
        }
    },
    "properties": {
        "variables": {
            "type": "object"
        },
        "name": {
            "type": "string"
        },
        "defaults": {
            "type": "object",
            "title": "Default configuration for cartes",
            "properties": {
                "gpsOrigin": {
                    "$ref": "#/$defs/GpsPoint"
                }
            }
        },
        "background": {
            "type": "string"
        },
        "actions": {
            "type": "string"
        },
        "details": {
            "enum": [
                "selection",
                "objects",
                "view",
                "context",
                "tabs",
                "carte",
                "variables"
            ]
        },
        "actions": {
            "type": "array"
        },
        "selections": {
            "type": "array"
        },
        "controls": {
            "additionalProperties": {
                "type": "boolean"
            }
        },
        "scales": {
            "type": "array",
            "items": {
                "type": "object"
            }
        },
        "canvas": {
            "type": "string"
        },
        "identities": {
            "$ref": "#/$defs/Dataset"
        },
        "timeline": {
            "type": "object",
            "properties": {
                "periods": {
                    "$ref": "#/$defs/Dataset"
                },
                "overview": {
                    "$ref": "#/$defs/Dataset"
                }
            }
        },
        "dataset": {
            "type": "array"
        },
        "cartes": {
            "type": "array",
            "items": {
                "properties": {
                    "name": {
                        "type": "string"
                    }
                },
                "oneOf": [
                    {
                        "properties": {
                            "type": {
                                "enum": [
                                    "graph"
                                ]
                            }
                        }
                    },
                    {
                        "properties": {
                            "type": {
                                "enum": [
                                    "environment"
                                ]
                            }
                        }
                    },
                    {
                        "properties": {
                            "type": {
                                "enum": [
                                    "helpers"
                                ]
                            }
                        }
                    },
                    {
                        "properties": {
                            "type": {
                                "enum": [
                                    "group"
                                ]
                            }
                        }
                    },
                    {
                        "properties": {
                            "type": {
                                "enum": [
                                    "trajectory"
                                ]
                            }
                        }
                    },
                    {
                        "properties": {
                            "type": {
                                "enum": [
                                    "terrain"
                                ]
                            }
                        }
                    },
                    {
                        "properties": {
                            "type": {
                                "enum": [
                                    "domain"
                                ]
                            }
                        }
                    },
                    {
                        "properties": {
                            "type": {
                                "enum": [
                                    "tiles"
                                ]
                            }
                        }
                    },
                    {
                        "properties": {
                            "type": {
                                "enum": [
                                    "stream"
                                ]
                            },
                            "url": {
                                "type": "string"
                            }
                        }
                    },
                    {
                        "properties": {
                            "type": {
                                "enum": [
                                    "telemetry"
                                ]
                            },
                            "samples": {
                                "$ref": "#/$defs/Dataset"
                            },
                            "shape": {
                                "anyOf": [
                                    {
                                        "enum": [
                                            "cube",
                                            "sphere",
                                            "duck"
                                        ]
                                    },
                                    {
                                        "type": "string"
                                    }
                                ]
                            },
                            "solid": {
                                "type": "boolean"
                            },
                            "label": {
                                "anyOf": [
                                    {
                                        "type": "boolean"
                                    }
                                ]
                            }
                        }
                    },
                    {
                        "properties": {
                            "type": {
                                "enum": [
                                    "image"
                                ]
                            },
                            "url": {
                                "type": "string"
                            },
                            "width": {
                                "type": "number"
                            },
                            "height": {
                                "type": "number"
                            },
                            "anchor": {
                                "type": "array",
                                "items": {
                                    "type": "number"
                                }
                            },
                            "pixelAnchor": {
                                "type": "array",
                                "items": {
                                    "type": "number"
                                }
                            }
                        }
                    },
                    {
                        "properties": {
                            "type": {
                                "enum": [
                                    "geojson"
                                ]
                            },
                            "url": {
                                "type": "string"
                            },
                            "geojson": {
                                "type": "string"
                            },
                            "coordinates": {
                                "enum": [
                                    "geoxy",
                                    "xy",
                                    "xyz",
                                    "epsg:4326"
                                ]
                            }
                        }
                    },
                    {
                        "properties": {
                            "type": {
                                "enum": [
                                    "gltf"
                                ]
                            },
                            "url": {
                                "type": "string"
                            },
                            "scale": {
                                "type": "number"
                            },
                            "color": {
                                "type": "array",
                                "maxLength": 3,
                                "minLength": 3,
                                "items": {
                                    "type": "number"
                                }
                            }
                        },
                        "allOf": [
                            {
                                "$ref": "#/$defs/Pose"
                            }
                        ]
                    },
                    {
                        "properties": {
                            "type": {
                                "enum": [
                                    "live"
                                ]
                            },
                            "url": {
                                "type": "string"
                            },
                            "scale": {
                                "type": "number"
                            },
                            "color": {
                                "type": "array",
                                "maxLength": 3,
                                "minLength": 3,
                                "items": {
                                    "type": "number"
                                }
                            }
                        },
                        "allOf": [
                            {
                                "$ref": "#/$defs/Pose"
                            }
                        ]
                    }
                ]
            }
        }
    },
    "additionalProperties": false
}