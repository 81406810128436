// const BASE_URL = "http://localhost:8080/.forloop-workspace/6e58ff1010df1114/"

// const TAG_TELEMETRY_URL = "http://localhost:8080/taguwb.ndjson"
// const DRONE_TELEMETRY_URL = BASE_URL + "DRONE.ndjson"
// const ANCHORS_URL = BASE_URL + "anchors.json"
// const ANCHORS_TELEMETRY_URL = BASE_URL + "anchoruwb.ndjson"

export default {
    name: "uwb-flight-telemetry",
    canvas: '.descartes-canvas',
    defaults: {
        gpsOrigin: {
            latitude: 51.470,
            longitude: 0.450,
        }
    },
    controls: {
        cartes: true,
        hover: true,
        selection: true,
        global: true,
        player: true
    },
    details: 'selection',
    scales: [
        {
            name: 'tag-color-scale',
            format: 'simple',
            legend: true,
            title: 'uwb quality',
            domain: [0, 1000]
            // domain: [8500, 9000]
        },
        {
            name: 'anchor-color-scale',
            format: 'simple',
            range: {
                'conn=3,sync=3': 'green',
                'conn=0,sync=0': 'grey',
                'conn=3,sync=0': 'red',
                'conn=3,sync=2': 'orange',
            }
        }
    ],
    variables: {
        "drones_telemetry_url": {
            type: "string",
            "default": "http://localhost:8080/increased-DRONE.ndjson"
        },
        "ls_url": {
            type: "string",
            "default": "http://localhost:8080/ls-locates.ndjson"
        },
        "anchors_telemetry_url": {
            type: "string",
            "default": "http://localhost:8080/anchoruwb.ndjson"
        },
        "anchors_url": {
            type: "string",
            "default": "http://localhost:8080/anchors.json"
        },
        fly_zone_url: {
            type: "string",
            "default": "http://localhost:8080/fly_zone.obj"
        },
        environment_url: {
            type: "string",
            "default": "http://localhost:8080/environment.obj"
        },
        "choreo_samples_url": {
            "type": "string",
            "default": "http://localhost:8080/choreo.ndjson"
        },
    },
    dataset: [
        {
            "name": "choreo-samples",
            "url": {
                "__var": "choreo_samples_url"
            },
            "format": "ndjson",
            "transforms": [
                {
                    "type": "compute",
                    "expr": "d=>[d.x, d.y, d.z]",
                    "as": [
                        "position"
                    ]
                },
                {
                    "type": "compute",
                    "expr": "d=>d.t",
                    "as": [
                        "time"
                    ]
                },
                {
                    "type": "compute",
                    "expr": "d=>Number.isFinite(d.g) ? [Math.min(d.r*3, 255), Math.min(d.g*3, 255), Math.min(d.b*3, 255)] : [255,0,0]",
                    "as": [
                        "color"
                    ]
                },
                {
                    "type": "compute",
                    "expr": "d=>d.name",
                    "as": [
                        "id"
                    ]
                }
            ],
        },
        {
            name: 'tag-telemetry',
            url: {
                __var: "drones_telemetry_url"
            },
            transforms: [
                {
                    type: "filter",
                    "expr": "d=>Number.isFinite(d.x)"
                },
                {
                    type: "compute",
                    expr: "d=>d.guid",
                    as: "id"
                },
                {
                    type: "compute",
                    expr: "d=>new Date(d.time).getTime()/1000",
                    as: "time"
                },
                {
                    "type": "normalize",
                    "field": "time",
                    "as": "time",
                    "from": 0
                },
                // {
                //     type: "compute",
                //     expr: "d=>d.time + 5",
                //     as: "time"
                // },
            ]
        },
        {
            name: 'least-squares',
            url: {
                __var: "ls_url"
            },
            transforms: [
                {
                    type: "compute",
                    expr: "d=>d.txSerial.toString()",
                    as: "id"
                },
                {
                    type: "compute",
                    expr: "d=>new Date(d.time || d.timestamp).getTime()/1000",
                    as: "time"
                },
                {
                    "type": "normalize",
                    "field": "time",
                    "as": "time",
                    "from": 0
                },
                {
                    type: "compute",
                    expr: "d=>d.error * 1000",
                    as: "error"
                }
            ]
        },
        {
            name: 'anchor',
            url: {
                __var: "anchors_url"
            },
            transforms: [

                {
                    type: 'compute',
                    expr: `s=>['conn','sync'].map(prop=>""+prop+"="+s[prop]).join(',')`,
                    as: ['status']
                },
                {
                    type: 'compute',
                    expr: 's=>[0,200,0]',
                    as: ['color']
                },
                {
                    type: 'compute',
                    expr: 's=>(s.serial || s.tagSerial || "").toString()',
                    as: ['id']
                },
                {
                    type: 'compute',
                    expr: 's=>s.name',
                    as: ['label']
                },
                {
                    type: 'compute',
                    expr: 's=>0.3',
                    as: ['size']
                },
            ]
        },
        {
            name: 'anchor-telemetry',
            url: {
                __var: "anchors_telemetry_url"
            },
            transforms: [

                {
                    type: 'compute',
                    expr: `s=>['conn','sync'].map(prop=>""+prop+"="+s[prop]).join(',')`,
                    as: ['status']
                },
                {
                    type: 'filter',
                    expr: "d=>d.x || d.y || d.z"
                },
                {
                    type: 'filter',
                    expr: "d=>d.role"
                },

                // {
                //     type: 'compute',
                //     expr: 's=>[0,200,0]',
                //     as: ['color']
                // },
                {
                    type: 'compute',
                    expr: 's=>(s.serial || s.tagSerial || "").toString()',
                    as: ['id']
                },
                {
                    type: 'compute',
                    expr: 's=>s.name',
                    as: ['label']
                },
                {
                    type: 'compute',
                    expr: 's=>0.3',
                    as: ['size']
                },
                {
                    type: "compute",
                    expr: "d=>new Date(d.time).getTime()/1000",
                    as: "time"
                },
                {
                    "type": "normalize",
                    "field": "time",
                    "as": "time",
                    "from": 0
                },
            ]
        },
    ],
    cartes: [
        {
            type: 'helpers'
        },
        {
            name: 'uwb-tag',
            type: 'stream',
            samples: "tag-telemetry",
            position: "d=>[d.x/1000, d.y/1000, d.z/1000]",
            color: //[0, 0, 200],
            {
                property: 'errorD1',
                scale: 'tag-color-scale'
            },
            solid: true
        },
        {
            name: 'ls-solution',
            optional: true,
            type: 'stream',
            samples: "least-squares",
            position: "d=>[d.x/1000, d.y/1000, d.z/1000]",
            color: [0, 0, 150],
            // {
            //     property: 'error',
            //     scale: 'tag-color-scale'
            // },
            solid: true
        },
        // {
        //     name: 'drone-estimation',
        //     type: 'telemetry',
        //     samples: {
        //         url: DRONE_TELEMETRY_URL,
        //         transforms: [
        //             {
        //                 type: "filter",
        //                 expr: "d=>Number.isFinite(d.positionX)",
        //                 as: "position"
        //             },
        //             {
        //                 type: "compute",
        //                 expr: "d=>[d.positionY, d.positionX, -d.positionZ]",
        //                 as: "position"
        //             },
        //             {
        //                 type: "compute",
        //                 expr: "d=>[0,0,255]",
        //                 as: "color"
        //             },
        //             {
        //                 type: "compute",
        //                 expr: "d=>d.guid",
        //                 as: "id"
        //             },
        //             {
        //                 type: "compute",
        //                 expr: "d=>new Date(d.time).getTime()/1000",
        //                 as: "time"
        //             }
        //         ]
        //     },
        //     solid: true
        // },
        {
            name: 'anchors',
            type: 'stream',
            samples: "anchor-telemetry",
            shape: 'cube',
            solid: true,
            position: "d=>[d.x/1000, d.y/1000, d.z/1000]",
            label: true,
            color: {
                property: 'status',
                scale: 'anchor-color-scale'
            },
        },
        {
            name: 'environment',
            type: 'environment',
            url: "$environment_url",
            color: [100, 100, 100]
        },
        {
            optional: true,
            name: 'flight-zone',
            type: 'environment',
            url: "$fly_zone_url",
            color: [0, 0, 100]
        },
        // {
        //     "name": "choreo",
        //     "type": "telemetry",
        //     "samples": "choreo-samples",
        //     "solid": true,
        //     "props": [
        //         { "key": 'position', "method": 'linear' },
        //         { "key": 'color', "method": 'linear' },
        //     ]
        // },
    ]
}