// import { mustHaveProps } from "../util";
import { get } from 'object-path-immutable'
import { buildFunction } from '../../util';
const mustHaveProps = (a, b) => true
const DEG_TO_RAD = Math.PI / 180;
const RAD_TO_DEG = 180 / Math.PI;
const EARTH_RADIUS = 6378137.0;

interface GpsPoint {
    latitude: number;
    longitude: number;
    altitude?: number
}

const defaultGpsOrigin = {
    latitude: 0,
    longitude: 0,
    altitude: 0,
}

function pos2Geo([x, y, z], { latitude, longitude, altitude }: GpsPoint = defaultGpsOrigin) {
    const cos = Math.cos(latitude * DEG_TO_RAD);
    return [
        longitude + ((x / EARTH_RADIUS) * RAD_TO_DEG) / cos,
        latitude + (y / EARTH_RADIUS) * RAD_TO_DEG,
        (altitude || 0) + z,
    ];
}


function computeBarycenter(samples, props = ["x", "y", "z"]) {
    const n = samples.length
    const coordinates = samples.map(s => props.map(p => s[p]))
    const barycenter = coordinates.reduce((prev, curr) => prev.map((coord, dir) => coord + curr[dir] / n), props.map(_ => 0))
    return barycenter
}

function geo2Pos([longitude, latitude, altitude], o) {
    const lat0 = o.latitude;
    const lon0 = o.longitude;
    const alt0 = o.altitude || 0;
    const cos = Math.cos(lat0 * DEG_TO_RAD);
    return [
        (longitude - lon0) * cos * EARTH_RADIUS * DEG_TO_RAD,
        (latitude - lat0) * EARTH_RADIUS * DEG_TO_RAD,
        altitude - alt0,
    ];
}

function rotationMatrixFromAxisAngle(axis, angle, dim = 4) {
    const theta = Number(angle || 0) * Math.PI / 180
    const c = Math.cos(theta)
    const s = Math.sin(theta)
    let [kx, ky, kz] = axis
    const norm = Math.sqrt(kx * kx + ky * ky + kz * kz)
    if (!Number.isFinite(norm) || norm < 0.000001) {
        throw `invalid rotation axis ${JSON.stringify(axis)}`
    }
    kx = kx / norm
    ky = ky / norm
    kz = kz / norm
    let matrix = [
        [c + kx * kx * (1 - c), kx * ky * (1 - c) - kz * s, kx * kz * (1 - c) + ky * s],
        [ky * kz * (1 - c) + kz * s, c + ky * ky * (1 - c), ky * kz * (1 - c) - kx * s],
        [kx * kz * (1 - c) - ky * s, kz * ky * (1 - c) + kx * s, c + kz * kz * (1 - c)]
    ]

    if (dim === 4) {
        for (let row of matrix)
            row.push(0.0)
        matrix.push([0, 0, 0, 1.0])
    }

    return matrix
}
function shape(m) {
    return [m.length, m[0].length]
}
function dot(m1, m2) {
    let result = []
    if (m1[0].length !== m2.length)
        throw `unable to multiply matrices : ${shape(m1).join('x')} ${shape(m2).join('x')}`
    let nrows = m1.length
    let ncols = m2[0].length
    let n = m1[0].length
    for (let irow = 0; irow < nrows; irow++) {
        let row = []
        for (let icol = 0; icol < ncols; icol++) {
            let value = 0.0
            for (let i = 0; i < n; i++) {
                value += m1[irow][i] * m2[i][icol]
            }
            row.push(value)
        }
        result.push(row)
    }
    return result
}
function oneMatrix() {
    return [
        [1, 0, 0, 0],
        [0, 1, 0, 0],
        [0, 0, 1, 0],
        [0, 0, 0, 1],
    ]
}

function translateMatrix([x, y, z]) {
    return [
        [1, 0, 0, x],
        [0, 1, 0, y],
        [0, 0, 1, z],
        [0, 0, 0, 1],
    ]
}

function scaleMatrix([x, y, z]) {
    return [
        [x, 0, 0, 0],
        [0, y, 0, 0],
        [0, 0, z, 0],
        [0, 0, 0, 1],
    ]
}

function renderMatrix(m) {
    let s = ""
    for (let row of m)
        s += (`${row.map(n => n.toFixed(1)).join(' , ')}\n`)
    return s
}

function matrixFromTransform({ center, translate, rotate, scale }) {
    if (!translate)
        translate = [0, 0, 0, 0]
    let result = oneMatrix()
    // offset by -center
    if (center)
        result = dot(translateMatrix(center.map(n => -n)), result)
    if (scale)
        result = dot(scaleMatrix(scale), result)
    if (rotate) {
        const { axis, angle } = rotate
        result = dot(rotationMatrixFromAxisAngle(axis, angle), result)
    }
    if (center)
        result = dot(translateMatrix(center), result)
    if (translate)
        result = dot(translateMatrix(translate), result)

    // console.log('transform matrix')
    // console.log(renderMatrix(result))
    return result
}




export function geo2xyz(samples, tr, datasets) {
    mustHaveProps(tr, ["origin"])
    const { latitude, longitude, altitude, origin, x, y, z } = tr

    return samples.map(s => {
        const lonlatalt: [any, any, any] = [s[longitude || "longitude"], s[latitude || "latitude"], s[altitude || "altitude"] || 0]
        const xyz = geo2Pos(lonlatalt, origin)
        return {
            ...s,
            [x || "x"]: xyz[0],
            [y || "y"]: xyz[1],
            [z || "z"]: xyz[2],
        }
    })
}

export function distance(samples, tr, datasets) {
    mustHaveProps(tr, ["from", "to"])
    const { from, to, as } = tr
    const dim = Math.min(from.length, to.length)

    return samples.map(s => {
        const p1 = from.map(prop => Number(s[prop]))
        const p2 = to.map(prop => Number(s[prop]))
        let d2 = 0
        for (let i = 0; i < dim; i++) {
            const delta = p1[i] - p2[i]
            d2 += delta * delta
        }
        return {
            ...s,
            [as || 'distance']: Math.sqrt(d2)
        }
    })
}

export default [
    {
        name: 'geo2xyz',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ["origin"])
            const { latitude, longitude, altitude, origin, x, y, z } = tr

            return samples.map(s => {
                const lonlatalt: [any, any, any] = [s[longitude || "longitude"], s[latitude || "latitude"], s[altitude || "altitude"] || 0]
                const xyz = geo2Pos(lonlatalt, origin)
                return {
                    ...s,
                    [x || "x"]: xyz[0],
                    [y || "y"]: xyz[1],
                    [z || "z"]: xyz[2],
                }
            })
        },
        schema: {
            type: 'object',
            properties: {
                latitude: { type: 'number' },
                longitude: { type: 'number' },
                altitude: { type: 'number' },
                origin: { type: 'number' },
                x: { type: 'number' },
                y: { type: 'number' },
                z: { type: 'number' }
            }
        }
    },
    {
        name: 'align',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ["from", "to"])
            let { props, filter, position } = tr


            filter = buildFunction(filter || "d=>true")
            position = position || [props.map(n => 0)]

            return samples.map(s => {
                throw `align not implemented`
            })
        },
        schema: {
            type: 'object',
            properties: {
                props: { type: 'array', items: { type: 'string', } },
                filter: { type: 'string' },
                position: { type: 'array', }
            },
            required: ["props"]
        }
    },
    {
        name: 'distance',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ["from", "to"])
            const { from, to, as } = tr
            const dim = Math.min(from.length, to.length)

            return samples.map(s => {
                const p1 = from.map(prop => Number(get(s, prop.split('.'))))
                const p2 = to.map(prop => Number(get(s, prop.split('.'))))
                let d2 = 0
                for (let i = 0; i < dim; i++) {
                    const delta = p1[i] - p2[i]
                    d2 += delta * delta
                }
                return {
                    ...s,
                    [as || 'distance']: Math.sqrt(d2)
                }
            })
        },
        schema: {
            type: 'object',
            properties: {
                from: { type: 'array', items: { type: 'string', } },
                to: { type: 'array', items: { type: 'string', } },
                as: { type: 'string' }
            },
            required: ["from", "to", "as"]
        }
    },
    {
        name: 'transform',
        async apply(samples, tr, datasets) {
            mustHaveProps(tr, ["props"])
            let { props, as, center, vprops } = tr
            if (!Array.isArray(props[0]))
                props = [props]
            if (!as)
                as = JSON.parse(JSON.stringify(props))
            const dim = props[0].length
            center = this.center || center
            if (center) {
                if (typeof center === 'string')
                    center = { table: center }
                if (!Array.isArray(center)) {
                    const { table } = center
                    console.log('computing center from table', table)
                    const tableSamples = await datasets[table].getSamples()
                    const barycenter = computeBarycenter(tableSamples)
                    console.log('got barycenter', barycenter)
                    center = barycenter
                    this.center = center
                }
            } else {
                center = [0, 0, 0, 0]
            }

            const matrix = this.matrix || matrixFromTransform({ ...tr, center })

            return samples.map(s => {
                for (let i = 0; i < props.length; i++) {
                    // let input = props[i].map((p, i) => s[p] - center[i])
                    let input = props[i].map((p, i) => s[p])
                    while (input.length < 3) input.push(0)
                    while (input.length < 4) input.push(1)
                    input = input.map(n => [n])
                    // let output = dot(matrix, input).map((row, i) => row[0] + center[i])
                    let output = dot(matrix, input).map((row, i) => row[0])
                    let outputProps = as[i]
                    for (let j = 0; j < dim; j++)
                        s = { ...s, [outputProps[j]]: output[j] }
                }
                return s
            })
        },
        schema: {
            type: 'object',
            properties: {
                props: {
                    type: 'array', items: {
                        anyOf: [
                            { type: 'array', items: { type: 'string' } },
                            { type: 'string' }
                        ]
                    }
                },
                vProps: {
                    type: 'array', items: {
                        anyOf: [
                            { type: 'array', items: { type: 'string' } },
                            { type: 'string' }
                        ]
                    }
                },
                translate: { type: 'array', items: { type: 'number', } },
                scale: { type: 'array', items: { type: 'number', } },
                center: { oneOf: [{ type: 'array', items: { type: 'number', } }, { type: 'string' }] },
                rotate: { type: 'object', properties: { axis: { type: 'array', items: { type: 'number', } }, angle: { type: 'number', description: 'angle in degrees' } } },
                as: { type: 'string' }
            },
            required: ["props"]
        }
    }
].map(tr => ({ ...tr, name: 'geo:' + tr.name })) as any[]
