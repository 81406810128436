import Carte from ".";
import { EventEmitter } from "events";

import { get, set } from 'object-path-immutable'
import { getObjectsLayers } from "../utils/layers/objects";
async function emitterFromWs(url): Promise<EventEmitter> {
    return new Promise((res, rej) => {
        try {
            const ws = new WebSocket(url)
            const emitter = new EventEmitter();

            ws.onmessage = (ev) => {
                try {
                    const lines = ev.data.split("\n").map(l => l.trim()).filter(Boolean)
                    for (let l of lines) {
                        try {
                            const json = JSON.parse(l)
                            emitter.emit('data', json)
                        } catch (err) {
                            console.log(`invalid stream event "${l}"`, err.toString())
                        }
                    }
                } catch (err) {
                    console.log('failed to handle ws message', err.toString())
                }
            }
            ws.onopen = () => res(emitter)
            ws.onerror = (err) => {
                const errorMessage = new Error(`failed to connect to websocket ${url}`)

                rej(errorMessage)
            }
        } catch (err) {
            console.log('failure', err.toString())
        }

    })
}


// TODO : trouver une façon de recevoir les events d'une autre window
// TODO : gérer les event dources

const OBJECT_TYPE = 'DRONE'

const defaultConfig = {
    expiration: 10000,
    emitter: 'ws://10.0.0.100:8010',
    updateInterval: 1000,
    // format: 'raw' // or dronisos
    format: {
        dronisos: {
            type: 'dronetracker',
            latitude: ['state', 'latitude'],
            longitude: ['state', 'longitude'],
            altitude: ['state', 'altitude'],
        }
    }
}

export default class LiveCarte implements Carte {
    config: any;
    emitter: EventEmitter
    updateInterval: any
    objects: any = {}

    getLayers(ctx: any, lastCtx: any, lastLayers: any) {
        const type = get(this.config, ['format', 'dronisos', 'type'])
        // TODO : handle indoor and outdoor drones (lon,lat,alt)
        const objects = Object.values(this.objects[type] || {}).map((obj: any) => {
            return { x: obj._longitude, y: obj._latitude, z: obj._altitude, ...obj, }
        })
        const layers = getObjectsLayers(objects, ctx, this.config)
        return layers
    }

    async init(dataset, config) {
        this.config = { ...defaultConfig, ...config }
        const { url } = this.config
        if (url.startsWith("ws://")) {
            this.emitter = await emitterFromWs(url)
            console.log('stream connected', this.emitter)
            console.log(this.config)
        } else {
            throw `invalid stream url : ${url}`
        }

        this.emitter.on('data', this.handleNewSample.bind(this))
        this.updateInterval = setInterval(this.update.bind(this), 1000)
    }
    async getIds(ctx: any) {
        let ids = []
        for (let type in this.objects)
            ids = ids.concat(Object.keys(this.objects[type]).map(id => `${type} / ${id}`))
        return ids
    }
    getObject(id: any, ctx: any) {
        const [type, guid] = id.split('/').map(s => s.trim())
        return this.objects[type][guid]
    }
    update() {
        const now = Date.now()
        const { memory } = this.config
        let xdomain = [Number.MAX_VALUE, -Number.MAX_VALUE]
        let ydomain = [Number.MAX_VALUE, -Number.MAX_VALUE]
        let zdomain = [Number.MAX_VALUE, -Number.MAX_VALUE]
        for (let type in this.objects)
            for (let id in this.objects[type]) {
                const o = this.objects[type][id]
                const x = o._longitude
                const y = o._latitude
                const z = o._altitude
                xdomain[0] = Math.min(xdomain[0], x)
                xdomain[1] = Math.max(xdomain[1], x)
                ydomain[0] = Math.min(ydomain[0], y)
                ydomain[1] = Math.max(ydomain[1], y)
                zdomain[0] = Math.min(zdomain[0], z)
                zdomain[1] = Math.max(zdomain[1], z)
            }
        this.ranges = { x: xdomain, y: ydomain, z: zdomain }
        return
        for (let type in this.objects)
            for (let id in this.objects[type]) {
                const o = this.objects[type][id]
                if (memory && o._t > (memory * 1000)) {
                    delete this.objects[type][id]
                }
            }
    }
    getRanges() {
        return this.ranges
    }

    handleNewSample(sample) {
        let { format } = this.config
        format = format.dronisos
        const { _type, latitude, longitude, altitude } = format
        const { type, id, path, value } = sample
        if (type !== format.type)
            return
        const timestamp = Date.now()
        const _latitude = get(value, latitude, undefined)
        const _longitude = get(value, longitude, undefined)
        const _altitude = get(value, altitude, undefined) + 10
        this.objects = set(this.objects, [type, id], { ...value, _t: timestamp, _latitude, _longitude, _altitude, id: `${type} / ${id}`, _type: type })
    }
}