import { get } from "object-path-immutable";
import { buildFunction, buildGetter } from "../getter";
import Descartes from "../..";


const defaultConfig = {
    tprop: 't',
    props: [
        { key: 'x', method: 'step' },
        { key: 'y', method: 'step' },
        { key: 'z', method: 'step' },
        { key: 'r', method: 'step' },
        { key: 'g', method: 'step' },
        { key: 'b', method: 'step' },
        // { key: 'position', method: 'step' },
        // { key: 'color', method: 'linear' },
    ]
}

export function getObjectId(o) {
    let result = ""
    for (let prop of ['_guid',  'id', 'name'])
        if (!result && (o[prop] || o[prop] === 0))
            result = o[prop]
    return result.toString()
}

export async function getInfoObjectId(info, descartes: Descartes) {
    if (!info)
        return null
    let { index, object, layer } = info
    if (index >= 0 && !object) {
        const { cm } = layer
        if (cm) {
            return (await cm.getIds())[index]
        }
    }
    if (object)
        return object.id
}

// tous les samples doivent avoir le même schéma, tous avoir du "t" et être ordonnés selon "t"
export function createInterpoler(samples, config) {
    config = { ...defaultConfig, ...config }
    let { tprop, props } = config
    let lastIdx = 0
    const epsilon = 0.3
    return (t) => {
        let idx = lastIdx;
        while (samples[idx][tprop] > t && idx > 0)
            idx--;
        while (samples[idx][tprop] < t && idx < samples.length - 1)
            idx++;
        const low = samples[idx - 1];
        const high = samples[idx];
        if (!low) return high
        if (!high) return low
        const dt = high[tprop] - low[tprop]
        if (dt < epsilon || true) return low;
        let alpha = (t - low[tprop]) / dt
        if (alpha < 0) alpha = 0
        if (alpha > 1) alpha = 1

        if (!props)
            return low
        const result = {
            ...low,
            _dt: t - low[tprop],
            _t: t,
            _alpha: alpha,
            id: getObjectId(low),
            label: low.label || getObjectId(low)
        };
        for (let prop of props || []) {
            const { key, method } = prop
            try {
                let value;
                if (method === 'step')
                    value = (low[key])
                else if (method === 'linear')
                    value = ((1 - alpha) * low[key] + alpha * high[key])
                else {
                    throw `invalid interpolation method : ${JSON.stringify(prop)}`
                }

                result[key] = value
            } catch (err) {
                console.log(err.toString())
            }
        }
        lastIdx = idx
        return result;
    }
}

export function computeNumericRange(values, path?: any[], exact = false) {
    let step = exact ? 1 : 101
    console.log('computing range', path, values.length)
    let range
    if (path)
        path = path.join('.')
    for (let i = 0; i < values.length; i += step) {
        const s = values[i]
        const v = s[path] // path ? get(s, path) : s
        if (Number.isFinite(v)) {
            if (!range) range = [v, v]
            else {
                range = [Math.min(range[0], v), Math.max(range[1], v)]
            }
        }
    }
    return range
}

export function computeDiscreteRange(values, path?: any[]) {
    let range: any[] = []
    const exists = {}
    for (let s of values) {
        const v = path ? get(s, path) : s
        if (!exists[v]) {
            exists[v] = 1
            range.push(v)
        }
    }
    return range;
}



export function uniteRanges(ranges) {
    const union = {}
    for (let range of (ranges || []).filter(Boolean)) {
        for (const k in range || {}) {
            if (!range[k])
                continue
            if (union[k]) {
                if (typeof union[k][0] === 'number') {
                    union[k] = [Math.min(union[k][0], range[k][0]), Math.max(union[k][1], range[k][1])]
                } else {
                    for (let item of range[k])
                        if (!union[k].includes(item))
                            union[k] = [...union[k], item]
                }
            } else {
                union[k] = [...range[k]]
            }
        }
    }
    return union
}

