import { render, html } from 'lit';
import { normalizeJson } from 'web-ui-blocks/json/utils'
import { computeColorScale } from './utils/dataviz/scale';
export default class ScaleManager {
    domains: any = {};
    h = 0;
    config: any;


    domain = []
    range = []
    scale: (any) => any
    legend: any
    validate: (any) => boolean

    constructor(config) {
        const { type, name, domain, legend } = config
        this.config = config
        this.range = []
        this.scale = () => null
    }

    update(partDomain, partName) {
        const domains = normalizeJson({ ...this.domains, [partName]: partDomain })
        if (JSON.stringify(domains) !== JSON.stringify(this.domains)) {
            console.log('updating scale', this.config.name, 'for', partName, domains)
            this.h++
            let domain = this.config.domain
            if (!domain)
                domain = Object.values(domains).reduce((prev: any, current: any) => {
                    if (!Array.isArray(current))
                        current = [current]
                    current = (current || []).filter(v => v !== null && v !== undefined)
                    if (!current.length)
                        return prev

                    const continuous = current.filter(v => Number.isFinite(v)).length === current.length
                    if (continuous) {
                        console.log('continuous color scale', current)
                        let min = Math.min(...current)
                        if (prev.length > 0)
                            min = Math.min(prev[0], min)
                        let max = Math.max(...current)
                        if (prev.length > 1)
                            max = Math.max(prev[1], min)
                        return [min, max]
                    } else {
                        console.log('discrete color scale', current)
                        for (let item of current)
                            if (prev.indexOf(item) < 0)
                                prev.push(item)
                        return prev
                    }
                }, []) as any[]
            this.domain = domain

            const { scale, validate, legend } = computeColorScale(domain, { ...this.config, domain, title: this.config.title || this.config.name })
            this.scale = scale
            this.validate = validate
            this.legend = legend


            this.domains = domains
        }

    }
    renderLegend() {
        if (this.config.legend !== false)
            return this.legend
    }
}

let scales: { [name: string]: ScaleManager } = {}

export function declareScale(spec) {
    const { name } = spec
    if (scales[name])
        throw `scale ${name} already exists`
    scales[name] = new ScaleManager(spec)
}

export function resetScales() {
    scales = {}
}
export function updateScale(scaleName, partialDomain, partName) {
    console.log('updateScale', scaleName)
    if (!scales[scaleName])
        throw `scale ${scaleName} does not exist`
    scales[scaleName].update(partialDomain, partName)
}



export function getScales() {
    return scales
}

export function scale(name, value) {
    const sm = scales[name]
    if (!sm)
        return
    if (!sm.validate(value))
        return
    const output = sm.scale(value)
    return output
}

