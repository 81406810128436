export async function askUser(current, cfg = {}) {
    let { name } = current
    let dialog = document.querySelector('#main-modal') as any
    if (!dialog) {
        dialog = document.createElement('dialog')
        dialog.id = "main-modal"
        dialog.innerHTML = `
        <form method="dialog" >
          <div class="content">

          </div>
          <div class="row">
            <button class="confirm" value="confirm">Confirm</button>
            <button class="cancel" value="cancel">Cancel</button>
          </div>
        </form>
      `
        document.body.appendChild(dialog)
    }
    // await new Promise((res) => setTimeout(res, 100))
    dialog.querySelector('.content').innerHTML = `
    <h2>Gimme your name</h2>
    <input type="text" name="name" id="" placeholder="Enter a name ..." />
    `

    const form = dialog.querySelector('form')
    form.addEventListener('change', (a) => console.log('form changed', a))
    // form.addEventListener('keydown', e => {
    //     console.log('key event caught')
    //     e.stopPropagation()
    //     e.preventDefault()
    // }, false)

    let resolve, reject
    const cancelHandler = () => {
        console.log('dialog cancelled')
        reject('dialog cancelled')
    }
    const closeHandler = () => {
        const r = dialog.returnValue
        let output = {}
        for (let name in form.elements) {
            const value = form.elements[name].value
            if (!Number.isFinite(Number(name)) && value !== undefined)
                output[name] = value
        }
        console.log('dialog closed', r, form)
        if (r === 'confirm')
            resolve(output)
        else if (r === 'cancel' || true)
            reject('cancelled by user')

    }
    try {
        let result: any = await new Promise((res, rej) => {
            resolve = res
            reject = rej
            dialog.showModal()
            dialog.addEventListener('cancel', cancelHandler)
            dialog.addEventListener('close', closeHandler)
        })
        console.log('result : ', result)

        name = (result.name || name).toString()
        current = {
            ...(current || {}),
            name
        }
    } catch (err) {
        form.reset()
        dialog.removeEventListener('cancel', cancelHandler)
        dialog.removeEventListener('close', closeHandler)
        current = null
    }
    return current
}