import { get } from "object-path-immutable";
import Descartes from ".";
import { buildFunction } from "./utils/getter";
export type KnownSelection = {
  name: string;
  description: string;
  selection?: string[];
  path?: string[];
  filter?: string[];
  table?: string;
}

export default function updateSelection(initialSelection, items, opts) {
  let selection = items;
  if (opts.add) {
    selection = initialSelection;
    for (let item of items) {
      let id = item;
      if (selection.indexOf(id) < 0) {
        selection = [...selection, id];
      }
    }
  } else if (opts.del) {
    selection = initialSelection.filter(
      (id) =>
        items
          .indexOf(id) < 0
    );
  }
  return selection.filter(Boolean)
}

export async function resolveKnownSelection(knownSelection, descartes: Descartes) {
  console.log('resolving known selection', knownSelection)
  let { selection, table, path, filter, url } = knownSelection
  if (!selection) {
    if (url) {
      throw `knownSelection from url not implemented`
    } else if (table) {
      selection = await descartes.dataset.getSamples(table)
    } else {
      throw `knownSelection from cartes not implemented`
    }
  }
  if (filter) {
    filter = buildFunction(filter)
    selection = selection.filter(filter)
  }
  if (path) {
    selection = selection.map(s => get(s, path))
  }
  return selection
}
