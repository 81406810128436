

export default (spec, dataset) => {
    return {
        async getChunkAt(offset, size) {
            const nrows = spec.samples.length
            return { samples: () => spec.samples, offset: 0, size: nrows, nrows }
        },

        getSize() {
            return spec.samples.length
        }
    }
}