import Carte from '.';

import { get, set } from 'object-path-immutable';
import { getObjectsLayers, getTableLayers } from '../utils/layers/objects';
import * as scale from '../utils/dataviz';
import { buildFunction } from 'web-ui-blocks/data-pipe/util';
import { DataSet } from 'web-ui-blocks/data-pipe';


const method = "linear"
const VIEWER_PROPS = [
    {
        name: "wifiAP",
        method: "step",
        type: 'string'
    },
    {
        name: "wifiSignal",
        method: "step",
        type: 'f32'
    },
    {
        name: "battery",
        method: "step",
        type: 'f32'
    },
    {
        name: "x",
        method,
        type: 'f64'
    },
    {
        name: "y",
        method,
        type: 'f64'
    },
    {
        name: "z",
        method,
        type: 'f64'
    },
    {
        name: "r",
        method,
        type: 'f32',
        default: 100
    },
    {
        name: "g",
        method,
        type: 'f32',
        default: 100
    },
    {
        name: "b",
        method,
        type: 'f32',
        default: 100
    },
    {
        name: "size",
        method,
        type: 'f32',
        default: 1.0
    },
    {
        name: "yaw",
        method,
        type: 'f32',
        default: 0
    }
]


const DEBUG = true

function applyMemory(items, duration) {
    let imin = 0;
    let finalItems = []

    for (let i = 0; i < items.length; i++) {
        const { time } = items[i]
        while (items[imin].time < time - duration)
            imin++;
        // const memorizedItems = items.slice(imin, i + 1);
        let finalItem = {}
        for (let memIndex = imin; memIndex < i + 1; memIndex++) {
            const memorizedItem = items[memIndex]
            for (let key in memorizedItem)
                finalItem[key] = memorizedItem[key]
            // finalItem = { ...finalItem, ...memorizedItem }
        }
        finalItems.push(finalItem)
    }
    return finalItems
}

async function getIdentities(spec) {
    let identities, list
    if (!spec)
        return {}
    const merge = (id, item) => {
        if (!id)
            return
        identities = identities || {}
        identities[id] = { ...(identities[id] || {}), ...(item || {}) }
    }
    if (Array.isArray(spec))
        list = spec
    if (typeof spec === 'string') {
        const json = await fetch(spec).then(s => s.json())
        if (Array.isArray(spec))
            list = spec
        else
            for (let id in json) {
                merge(id, json[id])
            }
    }
    if (list) {
        for (let item of list) {
            merge(item.id, item)
        }

    }
    if (!identities)
        throw `invalid identity spec : ` + JSON.stringify(spec)
    return identities
}


function clone(arr) {
    return arr.map(o => ({ ...o }))
    return JSON.parse(JSON.stringify(arr))
}

const defaultConfig = {
    memory: 20.0
}

export type StreamCarteConfig = {
    table: string;
    time: string;
    identifier: string;
    props: any[]
}

export default class StreamCarte implements Carte {
    dataset: DataSet
    state = {}
    cache: any = {}
    config;
    ranges: any;
    ids: string[] = []
    h: number = 0
    async getLayers(ctx: any, lastCtx: any, lastLayers: any) {
        // should return last
        const time = ctx.time || 0
        // console.log('getLayers at ', time)
        const data = await this.dataset.compute('viewer:at', time)
        const columns: any = {
            id: this.ids
        }
        for (let { name, buffer, type } of data) {
            columns[name] = buffer
            columns.length = buffer.length
        }
        const { colorScale, color } = this.config
        if (colorScale && color) {
            const array = columns[color]
            const [r, g, b] = scale.map(colorScale, array)
            columns.r = r
            columns.g = g
            columns.b = b
        }

        const invalids = columns.x.filter(n => !Number.isFinite(n))
        if (invalids.length)
            console.log(invalids.length + " invalids")
        const layers = getTableLayers(columns, ctx, this.config)
        return layers
    }
    async getIds(ctx: any) {
        return this.ids
    }
    getObject(id: any, ctx: any) {
        return this.dataset.compute("viewer:object_at", id, ctx.time || 0)
    }
    getRanges() {
        return this.ranges
    }
    _getTimeOverview(ti, tf): any[] {
        const samples = this.objects[0]
        const getObjectIntensity = curr => curr.z
        //(curr.r + curr.g + curr.b) / 3
        let getIntensity = (t) => {
            // const samples = this.getDataAtTime(t);

            let value = samples.reduce((prev, curr) => prev + getObjectIntensity(curr), 0)
            return value
        }
        // const [tMin, tMax] = this.ranges.t
        // if (!this.config.color)
        //     getIntensity = (t) => (t >= ti && t <= tf) ? 1 : 0


        const dt = 0.3
        let result = []
        const t = (ti + tf) / 2
        const value = getIntensity(t)
        result.push({ t: ti, value })
        return result;
    }
    async init(dataset: DataSet, config: StreamCarteConfig) {
        this.config = config
        this.dataset = dataset

        const { identifier, time, props, name, segmentSize } = this.config


        this.config = { ...defaultConfig, ...this.config }
        const table = this.config.samples || this.config.table

        await dataset.extend("viewer", { table, time, identifier, props: VIEWER_PROPS, segmentSize })
        const [x, y, z, t] = await Promise.all(["x", "y", "z", time].map(prop => dataset.getTableStats(table, prop).then(summary => [summary.min, summary.max])))
        this.ranges = { x, y, z, t }
        this.ids = await dataset.compute("viewer:objects")
        const { color, colorScale } = this.config
        console.log('upadting scales')


    }

    getState() {
        return this.state
    }

    useSamples(parsed) {
        console.log('in useSamples', parsed)
        if (this.config.nonempty)
            throw `no sample found`
        let { position, color, intensity, name } = this.config
        const t0 = Date.now()
        const time = function (...args) {
            const dt = Date.now() - t0
            if (DEBUG)
                console.log(dt + ' ' + name + ' : ', ...args)
        }
        for (let property of ['x', "y", "z", "r", "g", "b", 'color']) {
            const cfg = this.config[property]
            if (typeof cfg === 'string' && cfg.includes('=>')) {
                const getter = buildFunction(cfg)
                for (let s of parsed)
                    s[property] = getter(s)
            }
        }

        time(`using ${parsed.length} samples `)


        if (color && !Array.isArray(color)) {

            const { property, scale } = color

            const domain = computeDomain(parsed.map(s => get(s, property)))
            if (domain)
                updateScale(scale, domain, [this.config.name, 'samples'].join(':'))
        }

    }


}



