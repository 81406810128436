import { get, set } from "object-path-immutable"
import DataTable from "../table"
import { buildFunction, mustHaveProps } from "../../util"
import * as timejoin from './timejoin'
function cleanupSample(s) {
    for (let prop in s)
        if (Array.isArray(s[prop]) || typeof s[prop] === "object")
            delete s[prop]
    return s
    // delete s.comCounters
}

import geoTransforms from './geo'
import fitTransforms from './fit'
import minkowski from './minkowski'
import dsp from './dsp'
import wifi from './wifi'


export const TRANSFORMS: any[] = [
    {
        name: 'impute',
        apply(samples, tr, datasets) {
            const { props } = tr
            let result = []
            for (let s of samples) {
                let newSample = { ...s }
                for (let p of props)
                    newSample[p] = 0
                result.push(newSample)
            }
            return result
        },
        schema: {
            type: 'object',
            properties: {
                'props': {
                    type: 'array',
                    items: { type: 'string' }
                }
            },
            required: []
        }
    },
    {
        name: 'enumerate',
        // not working with multiple chunks
        apply(samples, tr, datasets) {
            const prop = tr.as || 'index'
            let index = 0
            let result = []
            for (let s of samples) {
                let newSample = { ...s, [prop]: index++ }
                result.push(newSample)
            }
            return result
        },
        schema: {
            type: 'object',
            properties: {
                'as': {
                    type: 'string',
                }
            },
            required: []
        }
    },
    {
        name: 'parse',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ["format", /*"keep"*/])
            let { format, robust, path, keep, as } = tr
            let result = []
            if (Array.isArray(path)) {
                if (path.length > 1)
                    throw `parsing deep property not implemented`
                path = path.join('')
            }
            if (as && !Array.isArray(as))
                as = [as]
            for (let s of samples) {
                try {
                    let serialized = (path) ? get(s, path) : s
                    let parsed = serialized
                    do {
                        parsed = JSON.parse(parsed)
                    } while (typeof parsed === 'string' && ['[', '{', '"', '\\'].find(char => parsed.startsWith(char)))
                    let output = null
                    if ((as || []).length) {
                        output = set({}, as, parsed)
                    } else {
                        output = parsed
                    }
                    let propsToKeep = keep || []
                    if (keep === true) {
                        propsToKeep = Object.keys(s).filter(p => p !== path)
                    }
                    for (let prop of propsToKeep) {
                        const value = get(s, prop)
                        output[prop] = value
                    }
                    result.push(output)
                } catch (err) {
                    console.log(err.toString())
                    if (!robust)
                        throw err
                }
            }
            return result
        },
        schema: {
            type: 'object',
            properties: {
                'format': {
                    type: 'string',
                    enum: ['json']
                },
                robust: {
                    type: 'boolean'
                },
                keep: {
                    type: 'array'
                },
                path: {
                    type: 'array'
                },
                as: {
                    type: 'array'
                }
            },
            required: []
        }
    },
    {
        name: 'ignore',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ["fields", /*"keep"*/])
            const { fields } = tr
            let result = []
            for (let s of samples) {
                let newSample = { ...s }
                for (let prop of fields) {
                    delete newSample[prop]
                }
                result.push(newSample)
            }
            return result
        },
        schema: {
            type: 'object',
            properties: {
                'fields': {
                    type: 'array',
                    items: { type: 'string' }
                }
            },
            required: ['fields']
        }
    },
    {
        name: 'extract',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ["path", /*"keep"*/])
            const { path, keep, as } = tr
            let result = []
            for (let s of samples) {
                for (let newSample of get(s, path, [])) {
                    if (keep) {
                        if (keep === true)
                            newSample = { ...s, [as || (path).join('.')]: newSample }
                        else {
                            let keptProps = keep
                            if (!Array.isArray(keptProps))
                                keptProps = [keptProps]
                            for (let prop of keptProps) {
                                newSample = { ...newSample, [prop]: s[prop] }
                            }
                        }
                    }
                    result.push(newSample)
                }
            }
            return result
        },
        schema: {
            type: 'object',
            properties: {
                'path': {
                    type: 'array',
                    items: { type: 'string' }
                }
            },
            required: ['path']
        }
    },
    {
        name: 'compute',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ['expr', 'as'])
            const { expr, as } = tr
            const fun = buildFunction(expr)
            let result = []
            for (let s of samples) {
                let value = fun(s)
                // if (as === 'tmp' && done < 100) {
                //     console.log(s, s[as])
                //     done++
                // }
                if (value === undefined)
                    value = null
                result.push({ ...s, [as]: value })
            }


            return result
        },
        schema: {
            type: 'object',
            properties: {
                'expr': {
                    type: 'string',
                },
                'as': {
                    type: 'string',
                },
            },
            required: ['expr', 'as']
        }
    },
    {
        name: 'join',
        async apply(samples, tr, dataset: { [name: string]: DataTable }) {
            const { id, externalTable, externalId, externalProps } = tr
            const externalSamples = await dataset[externalTable].getSamples()
            const perId = {}
            for (let s of externalSamples) {
                perId[s[externalId]] = s
            }
            return samples.map(s => {
                const localId = s[id]
                const externalSample = perId[localId]
                if (externalSample)
                    for (let prop of externalProps)
                        s[prop] = externalSample[prop]
                return s
            })
        },
        schema: {
            type: 'object',
            properties: {
                'id': {
                    type: 'string',
                },
                'externalTable': {
                    type: 'string',
                },
                'externalId': {
                    type: 'string',
                },
                'externalProps': {
                    type: 'array',
                    items: { type: "string" }
                }
            },
            required: ["externalTable"]
        }
    },
    timejoin,
    {
        name: 'memory',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ["field", "as"])
            const { field, as } = tr
            const n = tr.n || 1
            const groupby = tr.groupby || []
            const previousSamples = {}

            return samples.map(s => {
                const key = groupby.map(part => s[part]).join(";")
                if (!previousSamples[key])
                    previousSamples[key] = []
                if (previousSamples[key].length >= n) {
                    const len = previousSamples[key].length
                    const memory = previousSamples[key].slice(len - n)
                    s[as] = memory
                } else {
                    s[as] = []
                }
                const value = s[field]
                previousSamples[key].push(value)
                return s
            })
        },
        schema: {
            type: 'object',
            properties: {
                'field': {
                    type: 'string',
                },
                'as': {
                    type: 'string',
                },
            },
            required: ["field", "as"]
        }
    },
    {
        name: 'filter',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ['expr'])
            const { expr } = tr
            const fun = buildFunction(expr)
            return samples.filter(fun)
        },
        schema: {
            type: 'object',
            properties: {
                'expr': {
                    type: 'string',
                }
            },
            required: ["expr"]
        }
    },
    {
        name: 'quadrature',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ["field", "as"])
            const { field, as, initialValue, initialValueField, step, variable } = tr
            let value
            return samples.map((sample, i) => {
                if (!i) {
                    value = initialValueField ? samples[0][initialValueField] : initialValue || 0
                    samples[0][as] = value
                    return { ...sample, [as]: value }
                }
                const previousSample = samples[i - 1]
                let derivative = previousSample[field]
                let currentStep = step
                if (variable) {
                    currentStep = sample[variable] - previousSample[variable]
                }
                value += currentStep * derivative
                return {
                    ...sample,
                    [as]: value
                }
            })
        },
        schema: {
            type: 'object',
            properties: {
                'field': {
                    type: 'string',
                },
                'as': {
                    type: 'string',
                },
            },
            required: ["field", "as"]
        }
    },
    {
        name: 'derivative',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ["fields", "time"])
            let { fields, time, as, groupby } = tr
            if (!as)
                as = fields.map(f => f + '_dot')
            if (!groupby)
                groupby = []
            const npoints = 2
            const getGroup = (sample) => groupby.map(part => sample[part]).join('-')
            const perGroup = {}
            return samples.map((sample, index) => {
                const group = getGroup(sample)
                if (!perGroup[group])
                    perGroup[group] = []
                const groupSamples = perGroup[group]
                groupSamples.push(sample)
                while (groupSamples.length > npoints)
                    groupSamples.shift()
                if (groupSamples.length < npoints)
                    return sample
                const points = groupSamples
                let result = { ...sample }
                const dt = points[npoints - 1][time] - points[0][time]
                for (let ifield = 0; ifield < fields.length; ifield++) {
                    const field = fields[ifield]
                    const dfield = points[npoints - 1][field] - points[0][field]
                    if (Number.isFinite(dfield)) {
                        result[as[ifield]] = dfield / dt

                    } else {
                        // throw `invalid derivation ${JSON.stringify(tr)} for sample ${JSON.stringify(sample)}`
                    }
                    // console.log(group, dfield, dt)
                }
                return result
            })
        },
        schema: {
            type: 'object',
            properties: {
                'fields': {
                    type: 'array', items: { type: 'string' },
                },
                'groupby': {
                    type: 'array', items: { type: 'string' },
                },
                'time': {
                    type: 'string',
                },
                'as': {
                    type: 'array', items: { type: 'string' },
                },
            },
            required: ["fields", "time"]
        }
    },
    {
        name: 'normalize',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ['field'])
            const { field, as, from, } = tr
            if (!this.summary) {
                let min = Number.MAX_VALUE, max = -Number.MAX_VALUE
                for (let s of samples) {
                    const value = s[field]
                    if (!Number.isFinite(value))
                        continue
                    min = Math.min(min, value)
                    max = Math.max(max, value)
                }
                if (!isnum(tr.min) || min < tr.min)
                    tr.min = min
                if (!isnum(tr.max) || max > tr.max)
                    tr.max = max
            }

            const fromValue = from || 0
            if (Number.isFinite(fromValue)) {
                for (let s of samples) {
                    const value = s[field]
                    if (!Number.isFinite(value))
                        continue
                    s[as || field] = value - tr.min + (fromValue)
                }
            }
            return samples
        },
        schema: {
            type: 'object',
            properties: {
                'field': {
                    type: 'string',
                },
            },
            required: ["field"]
        }
    },
    {
        name: 'scale',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ['fields', 'factor'])
            const { fields, factor } = tr
            return samples.map(sample => {
                for (let i = 0; i < fields.length; i++) {
                    const field = fields[i]
                    sample[field] = sample[field] * factor
                }
                return sample
            })
        },
        schema: {
            type: 'object',
            properties: {
                'fields': {
                    type: 'array',
                    "items": {
                        type: "string"
                    }
                },
                'factor': {
                    type: 'number',
                },
            },
            required: ["fields", "factor"]
        }
    },
    {
        name: 'project',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ['fields'])
            const { fields, as } = tr
            return samples.map(sample => {
                let result = {}
                for (let i = 0; i < fields.length; i++) {
                    const field = fields[i]
                    const prop = (as || [])[i] || field
                    result[prop] = sample[field]
                }
                return result
            })
        },
        schema: {
            type: 'object',
            properties: {
                'field': {
                    type: 'string',
                },
            },
            required: ["field"]
        }
    },
    {
        name: 'aggregate',
        apply(samples, tr, datasets) {
            mustHaveProps(tr, ["ops"])
            let { groupby, ops, args, as } = tr
            if (!args) {
                args = Object.keys(samples[0])
            }
            if (typeof ops === "string")
                ops = args.map(a => ops)
            if (!this.perGroup) {
                this.perGroup = []
            }
            const { perGroup } = this
            const getGroupId = (s) => (groupby || []).map(prop => (s[prop] || "").toString()).join('+')
            for (let s of samples) {
                const groupId = getGroupId(s)
                let group = perGroup.find(gr => gr.id === groupId)
                if (!group) {
                    group = {
                        id: groupId,
                        groupby: {},
                        count: 0,
                        // ? samples: [],
                        state: ops.map(o => ({})),
                        values: ops.map(o => null)
                    }
                    for (let prop of groupby || [])
                        group.groupby[prop] = s[prop]
                    perGroup.push(group)
                }
                group.count++
                group.values = ops.map((op, index) => {
                    const arg = (args || [])[index]
                    const updater = AGGREGATE_FUNCTIONS[op]
                    if (!updater)
                        throw `unknown aggregate function : "${op}"`
                    return updater(group.state[index], arg, s, group.count)
                })
            }
            return perGroup.map(gr => {
                let output = { ...gr.groupby }
                for (let i = 0; i < ops.length; i++) {
                    const prop = (as || [])[i] || [ops[i], (args || [])[i]].filter(Boolean).join('_')
                    output[prop] = gr.values[i]
                }
                return output
            })
        }
        ,
        schema: {
            type: 'object',
            properties: {
                'groupby': {
                    type: 'array',
                    items: { type: 'string' }
                },
                'ops': {
                    type: 'string',
                    enum: ['count', 'mean', 'sum', 'last']
                },
                'args': {
                    type: 'string',
                },
                'as': {
                    type: 'string',
                },
            },
            required: ["field"]
        }
    }
].concat(geoTransforms, fitTransforms, minkowski, dsp, wifi)



const TRANSFORM_FUNCTIONS = {
    // extract: applyExtractTransform,
    // compute: applyComputeTransform,
    // join: applyJoinTransform,
    // memory: applyMemoryTransform,
    // filter: applyFilterTransform,
    // quadrature: applyQuadratureTransform,
    // derivative: applyDerivationTransform,
    // normalize: applyNormalizeTransform,
    // project: applyProjectTransform,
    // aggregate: applyAggregateTransform,
    // ...geoTransforms
}


for (let { name, apply, schema } of TRANSFORMS) {
    TRANSFORM_FUNCTIONS[name] = apply
}



export function loadPluginTransforms(plugin) {
    console.log('loading transforms from', plugin)
    const { name, transforms } = plugin
    for (let tr of transforms) {
        tr.name = [name, tr.name].filter(Boolean).join(':')
        TRANSFORM_FUNCTIONS[tr.name] = tr.apply
        TRANSFORMS.push(tr)
    }
}


export async function applyTransform(samples, transform, dataset: { [name: string]: DataTable }) {
    if (transform.debug)
        console.log('before', transform, samples)
    let transformFunction = TRANSFORM_FUNCTIONS[transform.type]
    if (!transformFunction)
        throw `transform ${transform.type} not implemented`
    try {
        let result = await transformFunction.bind(this)(samples, transform, dataset)
        return result
    } catch (err) {
        console.log(err)
        throw new Error(transform.type + ` failed : ` + err.toString())
    }

}

const AGGREGATE_FUNCTIONS = {
    count(state, arg, sample, count) {
        if (!arg)
            return count
        state.count = (count || 0)
        const value = sample[arg]
        if (value !== undefined && value !== null)
            state.count++
        return state.count
    },
    sum(state, arg, sample, count) {
        if (!arg)
            throw `"arg" expected for sum aggregate`
        const value = sample[arg]
        if (value) {
            if (!Number.isFinite(value))
                throw `"${arg}" must be a number but is ${value}`
            state.sum = (state.sum || 0) + value
        }
        return state.sum || 0
    },
    mean(state, arg, sample, count) {
        if (!arg)
            throw `"arg" expected for sum aggregate`
        const value = sample[arg]
        if (value) {
            if (!Number.isFinite(value))
                throw `"${arg}" must be a number but is ${value}`
            state.sum = (state.sum || 0) + value
            state.n = (state.sum || 0) + 1
            state.mean = (state.sum || 0) / state.n
        }
        return state.mean || 0
    },
    last(state, arg, sample, count) {
        if (!arg)
            throw `"arg" expected for last aggregate`
        const value = sample[arg]
        if (value !== null && value !== undefined) {
            state.value = value
        }
        return state.value
    }
}

function applyAggregateTransform(samples, tr, datasets) {
    mustHaveProps(tr, ["ops"])
    let { groupby, ops, args, as } = tr
    if (!args) {
        args = Object.keys(samples[0])
    }
    if (typeof ops === "string")
        ops = args.map(a => ops)
    if (!this.perGroup) {
        this.perGroup = []
    }
    const { perGroup } = this
    const getGroupId = (s) => (groupby || []).map(prop => (s[prop] || "").toString()).join('+')
    for (let s of samples) {
        const groupId = getGroupId(s)
        let group = perGroup.find(gr => gr.id === groupId)
        if (!group) {
            group = {
                id: groupId,
                groupby: {},
                count: 0,
                // ? samples: [],
                state: ops.map(o => ({})),
                values: ops.map(o => null)
            }
            for (let prop of groupby || [])
                group.groupby[prop] = s[prop]
            perGroup.push(group)
        }
        group.count++
        group.values = ops.map((op, index) => {
            const arg = (args || [])[index]
            const updater = AGGREGATE_FUNCTIONS[op]
            if (!updater)
                throw `unknown aggregate function : "${op}"`
            return updater(group.state[index], arg, s, group.count)
        })
    }
    return perGroup.map(gr => {
        let output = { ...gr.groupby }
        for (let i = 0; i < ops.length; i++) {
            const prop = (as || [])[i] || [ops[i], (args || [])[i]].filter(Boolean).join('_')
            output[prop] = gr.values[i]
        }
        return output
    })
}

function applyDerivationTransform(samples, tr, datasets) {
    mustHaveProps(tr, ["fields", "time"])
    let { fields, time, as, groupby } = tr
    if (!as)
        as = fields.map(f => f + '_dot')
    if (!groupby)
        groupby = []
    const npoints = 2
    const getGroup = (sample) => groupby.map(part => sample[part]).join('-')
    const perGroup = {}
    return samples.map((sample, index) => {
        const group = getGroup(sample)
        if (!perGroup[group])
            perGroup[group] = []
        const groupSamples = perGroup[group]
        groupSamples.push(sample)
        while (groupSamples.length > npoints)
            groupSamples.shift()
        if (groupSamples.length < npoints)
            return sample
        const points = groupSamples
        let result = { ...sample }
        const dt = points[npoints - 1][time] - points[0][time]
        for (let ifield = 0; ifield < fields.length; ifield++) {
            const field = fields[ifield]
            const dfield = points[npoints - 1][field] - points[0][field]
            if (Number.isFinite(dfield)) {
                result[as[ifield]] = dfield / dt

            } else {
                // throw `invalid derivation ${JSON.stringify(tr)} for sample ${JSON.stringify(sample)}`
            }
            // console.log(group, dfield, dt)
        }
        return result
    })
}

function applyQuadratureTransform(samples, tr, datasets) {
    mustHaveProps(tr, ["field", "as"])
    const { field, as, initialValue, initialValueField, step, variable } = tr
    let value
    return samples.map((sample, i) => {
        if (!i) {
            value = initialValueField ? samples[0][initialValueField] : initialValue || 0
            samples[0][as] = value
            return { ...sample, [as]: value }
        }
        const previousSample = samples[i - 1]
        let derivative = previousSample[field]
        let currentStep = step
        if (variable) {
            currentStep = sample[variable] - previousSample[variable]
        }
        value += currentStep * derivative
        return {
            ...sample,
            [as]: value
        }
    })
}

function applyMemoryTransform(samples, tr, datasets) {
    mustHaveProps(tr, ["field", "as"])
    const { field, as } = tr
    const n = tr.n || 1
    const groupby = tr.groupby || []
    const previousSamples = {}

    return samples.map(s => {
        const key = groupby.map(part => s[part]).join(";")
        if (!previousSamples[key])
            previousSamples[key] = []
        if (previousSamples[key].length >= n) {
            const len = previousSamples[key].length
            const memory = previousSamples[key].slice(len - n)
            s[as] = memory
        } else {
            s[as] = []
        }
        const value = s[field]
        previousSamples[key].push(value)
        return s
    })
}


async function applyJoinTransform(samples, tr, dataset: { [name: string]: DataTable }) {
    mustHaveProps(tr, ["id", "externalTable", "externalId", "externalProps"])
    const { id, externalTable, externalId, externalProps } = tr
    const externalSamples = await dataset[externalTable].getSamples()
    const perId = {}
    for (let s of externalSamples) {
        perId[s[externalId]] = s
    }
    return samples.map(s => {
        const localId = s[id]
        const externalSample = perId[localId]
        if (externalSample)
            for (let prop of externalProps)
                s[prop] = externalSample[prop]
        return s
    })
}


function applyExtractTransform(samples, tr, datasets) {
    mustHaveProps(tr, ["path", /*"keep"*/])
    const { path, keep, as } = tr
    let result = []
    for (let s of samples) {
        for (let newSample of get(s, path, [])) {
            if (keep) {
                newSample = { ...s, [as || (path).join('.')]: newSample }
            }
            result.push(newSample)
        }
    }
    return result
}

function applyProjectTransform(samples, tr, datasets) {
    mustHaveProps(tr, ['fields'])
    const { fields, as } = tr
    return samples.map(sample => {
        let result = {}
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i]
            const prop = (as || [])[i] || field
            result[prop] = sample[field]
        }
        return result
    })
}


function applyFilterTransform(samples, tr, datasets) {
    mustHaveProps(tr, ['expr'])
    const { expr } = tr
    const fun = buildFunction(expr)
    return samples.filter(fun)
}

let done = 0
function applyComputeTransform(samples, tr, datasets) {
    mustHaveProps(tr, ['expr', 'as'])
    const { expr, as } = tr
    const fun = buildFunction(expr)
    for (let s of samples) {
        s[as] = fun(s)
        // if (as === 'tmp' && done < 100) {
        //     console.log(s, s[as])
        //     done++
        // }
        if (s[as] === undefined)
            s[as] = null
    }


    return samples
}

const isnum = (v) => Number.isFinite(v)

function applyNormalizeTransform(samples, tr, datasets) {
    mustHaveProps(tr, ['field'])
    const { field, as, from, } = tr
    let min = Number.MAX_VALUE, max = -Number.MAX_VALUE
    for (let s of samples) {
        const value = s[field]
        if (!Number.isFinite(value))
            continue
        min = Math.min(min, value)
        max = Math.max(max, value)
    }
    if (!isnum(tr.min) || min < tr.min)
        tr.min = min
    if (!isnum(tr.max) || max > tr.max)
        tr.max = max
    if (Number.isFinite(from)) {
        for (let s of samples) {
            const value = s[field]
            if (!Number.isFinite(value))
                continue
            s[as || field] = value - tr.min + from
        }
    }
    return samples
}
