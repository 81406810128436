import { render, html } from "lit";
import CarteManager from "./carte-manager";
import createContextMenu from "web-ui-blocks/contextmenu";

function stringify(state) {
  return JSON.stringify(state, (k, v) => {
    if (v instanceof Error)
      return v.toString()
    return v
  }, 2)
}

export default function renderCarteWidget(container, carte: CarteManager, descartes) {
  if (!carte || (carte.getState().error && carte.config.optional)) {
    container.parentNode.removeChild(container);
  } else {
    const state = carte.getState();
    const { name } = state;
    function handleContextmenu(evt) {
      evt.preventDefault();
      const actions = [
        {
          name: 'toggle',
          text: 'toggle layer',
          perform() {
            descartes.updateCarte(name, { active: !state.active })
          }
        }
      ]
      createContextMenu(evt, state, actions, `actions on ${state.name}`);
    }
    render(
      html`
        <span class="btn name" title="${stringify(state)}" @contextmenu="${handleContextmenu}"
          @click="${(e) => { descartes.update({ carte: name, details: 'carte' }) }}">${state.name}</span>
        ${state.error
        ? html`<span class="btn widget error" title="${state.error}">
          <i class="fas fa-times"></i>
        </span>`
        : !state.loaded
          ? html`<span class="btn widget info">
          <i class="spinning fas fa-spinner"></i>
        </span>`
          : state.active
            ? html`<span class="btn widget" @click="${(e) => descartes.focus(name)}"><i class="fas fa-search"></i></span>`
            : ""}
        ${(descartes.config.vizuControls || []).map(
              ({ render, action }) => html`<span class="btn widget config" @click="${() => action(carte)}">${render(carte)}</span>`
            )}

        `,
      container
    );
  }
}