import { clear, createStore, entries, set } from "idb-keyval"
import { SimpleToolSettings } from "./settings"
import { getVariable } from "./variables"


export type ConfigPreset = {
    raw: Blob,
    name: string,
    source: string,
    current?: any,
    original?: any,
    normalized?: any,
    id?: string,
    // + others based on source
}

function isValidPreset(c) {
    return c.raw instanceof Blob && c.name
}
export function fixPreset(builtin, name = null, source = 'builtin',): ConfigPreset {
    let preset
    if (builtin instanceof Blob) {
        preset = {
            raw: builtin,
        }
    } else if (typeof builtin === 'object' && !(builtin.raw)) {
        preset = {
            raw: new Blob([JSON.stringify(builtin)], { type: 'application/json' }),
            name: builtin.name || builtin.title
        }
    } else {
        preset = builtin
        // throw new Error(`not a valid builtin : ${JSON.stringify(builtin)}`) 
    }


    return {
        ...preset,
        name: name || (preset.name),
        source
    }
}



// TODO : rendre visible le fait qu'un preset soit dans l'historique
export async function getPresetList(current: ConfigPreset | null, settings: SimpleToolSettings): Promise<ConfigPreset[]> {
    const history = getVariable('history') ? await getHistory() : []

    let presets = [
        current,
        ...(history),
        ...settings.builtins.map((b, i) => fixPreset(b))
    ].filter(Boolean).map(p => ({ ...p, id: getId(p) }))

    presets = presets
        .filter((p, i) => presets.map(p => p.id).indexOf(p.id) === i)
    return presets as ConfigPreset[]
}


const store = createStore('history', 'keyval')

async function getHistory(): Promise<ConfigPreset[]> {
    return await (await entries(store)).map(([key, blob]) => {
        let raw: Blob = blob;
        return { raw: blob as Blob, name: key.toString(), source: 'history' }
    })//.sort((a, b) => a.date < b.date ? -1 : 1)
}

export async function remember(item: ConfigPreset) {
    await set(item.name, item.raw, store)
    return { ...item, source: 'history' }
}
export const wipeHistory = async () => {
    await clear(store)
}

const getId = (p: ConfigPreset) => `${p.source}/${p.name}`
