import { get } from "object-path-immutable";
import { getChunkSamples } from "../";


function indexChunk(chunk, getId) {
    const perObject = {}
    const samples = getChunkSamples(chunk)
    const min = samples[0].t
    const max = samples[samples.length - 1].t
    const mid = samples[Math.round(samples.length / 2)].t
    for (let s of samples) {
        const id = getId(s)
        if (!perObject[id])
            perObject[id] = {
                cursor: 0,
                samples: []
            }
        perObject[id].samples.push(s)
    }
    const dt = (max - min)
    const timePerSize = dt / chunk.size
    if (dt < 1.0) {
        throw `chunk last less than 1s : you should increase chunkSize`
    }
    return { perObject, min, max, mid, dt: max - min, offset: chunk.offset, size: chunk.size, timePerSize }
}

function computeIndexScore(index, t) {
    const { min, max, mid } = index
    return -Math.abs(t - mid)
}

function moveCursor(obj, t, memory) {
    obj = obj || { cursor: 0, samples: [] }
    let { cursor, samples } = obj
    try {
        const n = samples.length
        let after, before, min, max
        if (n) {
            min = samples[0].t
            max = samples[samples.length - 1].t
            if (cursor < 0)
                cursor = 0
            while (cursor < n && samples[cursor].t < t) {
                cursor++
            }

            after = samples[cursor]
            if (cursor >= n)
                cursor = n - 1
            while (cursor >= 0 && samples[cursor].t > t) {
                cursor--
            }
            before = samples[cursor]
        }

        const motion = cursor - obj.cursor
        obj.cursor = cursor
        memory.before = before || memory.before
        memory.after = after || memory.after

        if (!before) {
            if (memory.after && memory.after.t < t)
                before = memory.after
            else if (memory.before && memory.before.t < t)
                before = memory.before
        }
        if (!after) {
            if (memory.before && memory.before.t > t)
                after = memory.before
            else if (memory.after && memory.after.t > t)
                after = memory.after
        }

        let dt, alpha
        if (before) {
            dt = t - before.t
            if (after)
                alpha = dt / (after.t - before.t)
        }
        return {
            cursor,
            before,
            after,
            dt,
            alpha,
            motion,
            n,
            t,
            min,
            max,
        }
    } catch (err) {
        console.log('failed to move cursor', cursor, samples)
        throw err
    }

}

export type TablePlayerSpec = {
    table: string;
    time: string;
    props: {
        name: string | string[];
        method: string;
        type: string;
        scale?: any;
        default?: any
    }[]
}

import DataSet from '../dataset'

const method = "linear"
const VIEWER_PROPS = [
    {
        name: "x",
        method,
        type: 'f32'
    },
    {
        name: "y",
        method,
        type: 'f32'
    },
    {
        name: "z",
        method,
        type: 'f32'
    },
    {
        name: "r",
        method,
        type: 'f32',
        default: 100
    },
    {
        name: "g",
        method,
        type: 'f32',
        default: 100
    },
    {
        name: "b",
        method,
        type: 'f32',
        default: 100
    },
    {
        name: "size",
        method,
        type: 'f32',
        default: 0.5
    },
    {
        name: "yaw",
        method,
        type: 'f32',
        default: 0
    }
]

export default async function createTableStreamer(dataset: DataSet, spec) {
    const { table, time, identifier, segmentSize } = spec
    const getId = (s) => get(s, identifier)
    let ticks, t0, allTicks = {}
    function tick(label) {
        allTicks[label] = (allTicks[label] || 0) + 1
        ticks.push({
            label,
            time: Date.now() - t0
        })
        // console.log(label)
    }
    const CHUNK_SIZE = segmentSize || 5 * 1024 * 1024
    const summary: any = await dataset.getTableStats(table, time)
    const { samples } = await dataset.previewSamples(table)
    let ids: string[] = []
    {
        let perObject = {}
        for (let s of samples) {
            const id = getId(s)
            perObject[id] = (perObject[id] || 0) + 1

        }
        ids = Object.keys(perObject)
    }

    const size = await dataset.getTableSize(table)

    const { min, max } = summary
    const sizeDensity = size / (summary.max - summary.min)
    const countDensity = summary.count / summary.ratio / ids.length / (summary.max - summary.min)


    console.log(size)
    console.log(summary)
    console.log((sizeDensity / (1024)).toFixed(0), "KB per second")
    console.log((CHUNK_SIZE / sizeDensity).toFixed(1), "s per chunk")
    console.log((countDensity).toFixed(0), "samples per object per second")
    console.log(ids.length, "objects", ids)
    const objectMemory = {}
    for (let id of ids)
        objectMemory[id] = {}


    // iobject index, x, y, z, r, g, b, (s, rot)
    const props = [
        {
            name: "index",
            method: 'step',
            type: 'u32',
            evaluate({ before, after }, objectIndex) {
                return objectIndex
            }
        },
        {
            name: "shape",
            method: "step",
            type: 'u8',
            evaluate({ before, after }, objectIndex) {
                return 1
            }
        },
        ...(spec.props || VIEWER_PROPS)

    ].map((prop) => {
        let s, buffer
        let typedBuffer
        if (prop.type === 'u32') {
            s = 4
            buffer = new ArrayBuffer(s * ids.length)
            typedBuffer = new Uint32Array(buffer)
        } else if (prop.type === 'u8') {
            s = 1
            buffer = new ArrayBuffer(s * ids.length)
            typedBuffer = new Uint8Array(buffer)
        } else if (prop.type === 'f64') {
            s = 8
            buffer = new ArrayBuffer(s * ids.length)
            typedBuffer = new Float64Array(buffer)
        } else if (prop.type === 'f32') {
            s = 4
            buffer = new ArrayBuffer(s * ids.length)
            typedBuffer = new Float32Array(buffer)
        } else if (prop.type === 'string') {
            typedBuffer = new Array(ids.length).fill('')
        } else {
            throw `unknown type ${prop.type}`
        }
        let evaluate = prop.evaluate
        if (!evaluate) {
            const { method } = prop
            if (method === 'step')
                evaluate = function evaluate({ before, after }, idx) {
                    const value = (before || after)[prop.name] || prop.default
                    if (prop.name === 'r') {
                        // console.log('evaluate red', before, after, prop, value )
                    }
                    return value
                }
            else if (method === 'linear') {
                evaluate = function evaluate(info, idx) { // TODO : add memory
                    const { before, after, alpha, dt } = info
                    const v0 = (before || {})[prop.name]
                    const v1 = (after || {})[prop.name]
                    let result

                    result = alpha * v1 + (1 - alpha) * v0
                    if (!Number.isFinite(result)) {
                        if (Number.isFinite(v0))
                            result = v0
                        else if (Number.isFinite(v1))
                            result = v1
                        else {
                            if (prop.name === 'x')
                                console.log('invalid x value', prop, info)
                            result = prop.default
                        }
                    }
                    return result
                }
            } else {
                throw `invalid interpolation method : ${method}`
            }
        }
        return ({
            ...prop,
            buffer,
            typedBuffer,
            evaluate
        })
    })

    function estimateOffset(t, indexBefore, indexAfter?) {
        if (!indexAfter)
            indexAfter = indexBefore
        const dt = t - indexBefore.mid
        const step = Math.round(CHUNK_SIZE / 2)
        const ratio = indexAfter.mid === indexBefore.mid ? 0.0 : (t - indexBefore.mid) / (indexAfter.mid - indexBefore.mid)
        const timePerSize = (1 - ratio) * indexBefore.timePerSize + (ratio) * indexAfter.timePerSize
        let offset = indexBefore.offset + dt / timePerSize
        offset = Math.max(0, offset)
        offset = Math.min(size - CHUNK_SIZE, offset)
        return step * Math.round(offset / step)
    }
    let lastTime
    const indexes = {}
    async function indexFromOffset(o) {
        try {
            tick('loading chunk ' + o)
            const chunk = await dataset.getTableChunk(table, o, CHUNK_SIZE)
            tick('chunk loaded ' + o)
            const indexed = indexChunk(chunk, getId)
            indexed.offset = o
            tick('chunk indexed ' + o)
            indexes[o] = indexed
            return indexed
        } catch (err) {
            console.log('failed to index from offset', o)
            throw err
        }

    }
    async function prepare(t) {
        lastTime = t


        let bestScore = -Number.MAX_VALUE, bestIndex = null
        for (let o in indexes) {
            const i = indexes[o]
            if (i.then)
                continue
            const score = computeIndexScore(i, t)
            if (score > bestScore) {
                bestScore = score
                bestIndex = i
            }
        }

        const step = CHUNK_SIZE / 2
        let preloadOffsets = [0, step * Math.round((size - CHUNK_SIZE) / step)]
        if (bestIndex) {
            const o = estimateOffset(t, bestIndex)
            preloadOffsets = [0, 1 / 2].map(f => step * Math.round((o + f * CHUNK_SIZE) / step))
        }
        preloadOffsets = preloadOffsets.filter(o => !indexes[o])
        for (let offset of preloadOffsets)
            indexes[offset] = indexFromOffset(offset)


        const { min, max, offset, perObject } = bestIndex || {}
        const tolerance = 1
        if (!Number.isFinite(min) || min > t + tolerance || max < t - tolerance) {
            const message = `preparation failed for t=${t} : score=${bestScore} , index=${[offset, min, max]}`
            throw message
        }
        return perObject || {}
    }
    function cleanup(currentTime) {
        for (let o in indexes) {
            if (!indexes[o].then)
                if (computeIndexScore(indexes[o], currentTime) < -60) {
                    const { min, max, offset } = indexes[o]
                    // console.log('cleaning index ', offset, min, max)
                    delete indexes[o]
                }
        }
    }

    let timeout
    return {
        async at(t) {
            ticks = []
            t0 = Date.now()

            const perObject = await prepare(t)
            if (!timeout)
                timeout = setTimeout(() => {
                    cleanup(lastTime)
                    timeout = null
                }, 1000)

            for (let i = 0; i < ids.length; i++) {
                const id = ids[i]
                const cursorInfo = moveCursor(perObject[id], t, objectMemory[id])
                for (let { typedBuffer, evaluate } of props) {
                    const value = evaluate(cursorInfo, i)
                    typedBuffer[i] = value
                }

            }
            tick('buffer filled')

            let str = ticks.map(({ label, time }) => ` ${time}ms : ${label}`).join('\n')
            if (0)
                console.log(str)

            const r = props.map(p => ({ name: p.name, buffer: p.typedBuffer }))
            // const idx = ids.indexOf("4b0051ab4281c8be")
            // if (idx >= 0) {
            //     const desc = ids[idx] + '  ' + r.map(({ name, buffer }) => `${name}=${(buffer || [])[idx]}`).join(',')
            //     console.log(desc)
            // }

            return r
        },
        async timespan() {
            return [min, max]
        },
        async objects() {
            return ids
        },
        async object_at(id, t) {
            const perObject = await prepare(t)
            const location = moveCursor(perObject[id], t, objectMemory[id])
            if (!location.before) {
                throw `no object ${id} at time ${t}`
            }
            return location.before
        }

    }
}
