import {
  MapController,
  OrbitController
} from "@deck.gl/core";

export default class DescartesController
  // extends OrbitController{
  extends MapController {
  constructor(options) {
    super(options)
  }
  handleEvent(event) {
    // if (event.type === 'keydown') {
    //   return
    // }
    // console.log(event)
    if (event.changedPointers) {
      const originalEvent = event.changedPointers[0];
      if (originalEvent.ctrlKey || originalEvent.altKey)
        return;
    }
    super.handleEvent(event)
  }
}
