
import { registerLoaders, setLoaderOptions } from "@loaders.gl/core";
import { getRegisteredLoaders } from "@loaders.gl/core/src/lib/api/register-loaders";
import { GLTFLoader } from "@loaders.gl/gltf";
import { getCurrentAccessToken, } from "web-ui-blocks/google";
registerLoaders([GLTFLoader]);
setLoaderOptions({ gltf: { loadBuffers: true, postProcess: true, normalize: true } })

export async function loadOptions(url) {
    if (url.startsWith('https://drive.google.com')) {
        const token = getCurrentAccessToken()
        console.log('injecting google drive token')
        return {
            fetch: {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        }
    }

}