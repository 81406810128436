import { ConfigPreset } from "./preset"
import { getRawVariables, specialize } from "./variables"

// url params : url, state(gdrive), preferred, base_url

export type ConfigSourceType = 'gdrive' | 'url' | 'channel' | 'builtins'
export type SimpleToolSettings = {
    name: string,
    setup: (config: any, update?: (newConfig: any) => void, source?: string) => Promise<void>,
    builtins: ConfigPreset[],
    normalize: (input: Blob) => any,
    sources: ConfigSourceType[],
    historyLength: number,
    widgetContainer?: string,
    schema?: any,
    skipLoadOnError?: boolean,
    widget?: boolean,
    widgets: any[],
    loader?: boolean,
    channel?: string,
    url?: string,
    declarations?: { [name: string]: any }
}

// TODO : use get params to configure
export const defaultToolSettings: SimpleToolSettings = {
    name: 'Ttest',
    async setup(config, update, sourceName) {
        config = specialize(config, config.variables)
        console.log(config)
        const app = document.querySelector("#app")
        if (app) {
            app.innerHTML = `
    ===
    ${sourceName}
    ===
    
    ${JSON.stringify(config, null, 2)}
    
    ===
    variables
    ===
    ${JSON.stringify(getRawVariables(), null, 2)}
    `
        }
    },
    builtins: [],
    async normalize(input) {// coerce to json
        if (input instanceof Blob) {
            try {
                const text = await input.text()
                const json = JSON.parse(text.split("\n").filter(l => !l.trim().startsWith('//')).join("\n"))
                return json
            } catch (err) {
                throw `failed to parse JSON from response : ${err.toString()}`
            }
        }
        return input
    },
    sources: ['url', "gdrive"],
    historyLength: 5,
    widgetContainer: '#simple-tool-controls',
    widget: true,
    widgets: [],
    loader: true,
    declarations: {
        nohistory: {
            default: true
        }
    }
}