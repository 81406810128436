import { init } from '../../notifications'
import { defaultToolSettings, SimpleToolSettings } from './settings'
import SOURCES from './sources'
import mountWidget from './widget'
import * as GDrive from '../../google/index'
import openControlPanel from './control-panel'

import "../../style/style.css"
import "./style.css"
import { ConfigPreset, getPresetList } from './preset'
import { createValidation, prettifyErrors } from '../../json/schema-validation'
import { html, render } from 'lit'
import './loader.css'
// or base_url in get params




import dronisosLogoUrl from '../assets/logow-dronisos.png'
import simpleToolLogoUrl from '../assets/logo.png'
import { declareVariables, getVariable, specialize } from './variables'
import { log, openJournal } from '../../journal'
function withLoader(fun, { description, logo, configure }: any = {}) {
    return async function (config) {
        let loader: any | null = document.querySelector('#loader')
        let descriptionElement
        let activityElement
        if (!loader) {
            const elt = document.createElement('div')
            elt.id = 'loader'
            document.body.appendChild(elt)
            render(html`
            <img class="logo" src="${logo || dronisosLogoUrl || simpleToolLogoUrl}">
            <h1 class="description"></h1>
            <h2 class="activity">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </h2>`, elt)
        }
        loader = document.querySelector('#loader')
        if (loader) {
            if (loader.done)
                clearTimeout(loader.done)
            loader.classList.remove('hidden')
            descriptionElement = loader.querySelector('.description')
            activityElement = loader.querySelector('.activity')
            if (descriptionElement)
                descriptionElement.innerHTML = description || 'Loading'
        }

        //setup loader
        let result, error
        try {
            // await new Promise((res, rej) => setTimeout(res, 5000))
            result = await fun(...arguments)
        } catch (err) {
            error = err
        }
        if (error) {
            loader.classList.add('error')
            descriptionElement.innerHTML = `Failed to load the application`
            activityElement.innerHTML = error.toString()
            activityElement.title = error.details || error.stack
            if (configure) {
                const btn = loader.querySelector('.logo')
                btn.classList.add('clickable')
                btn.addEventListener('click', () => {
                    configure(config)
                })
            }

            throw error

        }
        if (loader) {
            loader.done = setTimeout(() => loader.classList.add('hidden')
                , 0)
        }


        // 
    }
}

function validateSettings(settings) {
    const builtins = settings.builtins || []
    const duplicates = builtins.filter((b, i) => builtins.find(trial => b.name === trial.name) !== b)
    if (duplicates.length) {
        throw `multiple presets with name ${duplicates.map(b => b.name)}`
    }

}


let currentPreset: ConfigPreset | null = null
let appSettings: SimpleToolSettings | null = null
let validate
const getCurrentPreset = () => currentPreset

export function getAppSettings() {
    return appSettings
}

export async function getConfig(preset: ConfigPreset, hydrate = true) {
    console.log('getConfig from preset', preset)
    if (!preset)
        return null
    const { raw, source, name } = preset || {}
    const { normalize, schema } = getAppSettings() as SimpleToolSettings

    let normalized: any = raw
    if (normalized) {
        if (normalize) {
            normalized = await normalize(raw)
        }
        if (hydrate && normalized.variables) {
            normalized = specialize(normalized, normalized.variables)
        }
        console.log('normalized', normalized)
        if (hydrate && schema) {
            if (!validate)
                validate = await createValidation(schema)
            console.log('checking', normalized, 'against', schema)
            if (!validate(normalized)) {
                const errors = prettifyErrors(validate.errors)
                const err = `Schema is invalid: ${errors[errors.length - 1]}`
                console.log('invalid schema')
                console.log(validate.errors)
                log({ message: err, details: errors, level: 'error', theme: ['TOOL', 'SETUP', 'ERROR'] })
                const error = new Error(`Schema is invalid`)
                error.details = errors.join("\n")
                throw error
            }
        }
    } else {
        throw `No config found`
    }
    return normalized
}

async function computeDefaultPreset(settings, configure) {
    const { sources } = settings
    let preset: ConfigPreset | null = null
    const presets = await getPresetList(null, settings)
    const preferred = getVariable('preset')
    if (preferred) {
        preset = presets.filter(p => p.name === preferred)[0]
    }



    for (let source of sources as any[]) {
        log({ message: 'looking for config in ' + source, level: 'debug' })
        if (typeof source === 'string')
            source = { name: source, parameters: null }
        const { name, parameters } = source
        if (preset || !name)
            continue;

        async function useConfigFromSource(config: ConfigPreset, sourceName) {
            try {
                if (config) {
                    config.source = sourceName
                    await configure(config as ConfigPreset)
                }
            } catch (err) {
                log({ level: 'debug', message: "failed to use config from " + sourceName + ' : ' + err.toString(), details: { config, error: err } })
            }
        }
        let result
        try {
            const load = SOURCES[name]
            if (!load)
                throw `Unknown source`
            result = await load(parameters, settings, useConfigFromSource)
            preset = result
        } catch (err) {
            log({ level: 'debug', message: "failed to load config from " + name, details: err.toString() })
        }
    }
    if (!preset)
        preset = presets[0]
    return preset
}

export default async function createSimpleTool(overrides: Partial<SimpleToolSettings>) {
    try {
        const journalConfig = {}
        // notifications
        // 
        if (appSettings)
            throw `unable to create 2 apps`
        await openJournal(journalConfig)
        declareVariables({
            notif: { default: 1 },
            preset: { default: '' },
            history: { default: true },
            controls: { default: true },
            url: { default: '' },
            base_url: { default: "" }
        }, "tool")
        declareVariables(overrides.declarations || {}, "app")

        const settingsOverrides = {}

        const settings: SimpleToolSettings = { ...defaultToolSettings, ...(arguments[0] || {}), ...settingsOverrides }
        validateSettings(settings)
        appSettings = settings
        const notifConfig = {
            maxLevel: getVariable('notif')
        }
        init(document.body, {}, notifConfig)
        const toolName = settings.name
        const { setup, historyLength, widgetContainer, sources, schema, normalize, builtins } = settings


        let alive = true, validate
        const checkAlive = () => {
            if (!alive)
                throw `simple tool is not alive`
        }
        async function useConfig(preset: ConfigPreset) {
            console.log('useConfig', preset)
            const { raw, source, name } = preset || {}
            log({ level: 'debug', message: preset ? `trying to use ${name} from ` + source : `using empty config`, theme: ['TOOL', 'SETUP', 'LAUNCH'] })

            checkAlive()

            const normalized = await getConfig(preset)

            currentPreset = {
                raw: null,
                name: null,
                source: null,
                ...(preset || {}),
                original: normalized,
                current: normalized
            }
            const handleUpdate = async (newConfig) => {
                (currentPreset as ConfigPreset).current = newConfig
                await mountWidget((currentPreset || defaultPreset) as ConfigPreset, settings, configure)
            }


            await mountWidget(preset, settings, configure)
            await setup(normalized, handleUpdate, source)
            log({ message: `App ready with config ${name}`, theme: ['TOOL', 'SETUP', 'OK'], level: 'info' })
        }

        const initialization = (preset) => useConfig(preset || defaultPreset)
        const configure = settings.loader ? withLoader(initialization, { description: 'Preparing application', configure: () => openControlPanel(getCurrentPreset()) }) : initialization
        log({ message: 'launching simple tool ' + toolName, level: 'debug', details: settings })
        const defaultPreset = await computeDefaultPreset(settings, initialization)
        // await mountWidget(defaultPreset, settings, configure)
        if (sources.includes('gdrive'))
            await GDrive.init(render)
        if (defaultPreset) {
            await configure(defaultPreset)
        } else {
            throw `no default preset found`
        }
        // handle global drop ?


        return (config, name = 'default-name') => configure({ raw: config, source: 'custom', name })
    } catch (err) {
        console.log(err)
        throw `invalid app settings : ${err.toString()}`
    }
}