import { geo2Pos, pos2Geo } from ".";
import { defaultGpsOrigin } from "../../defaults";

const EARTH_RADIUS = 6378137.0;
const RAD_TO_DEG = 180 / Math.PI;
const DEG_TO_RAD = Math.PI / 180;
let DEFAULT_GPS_ORIGIN = [44.816574, -0.540578, 0];

function convertRecursively(input, converter) {
  if (!Array.isArray(input)) {
    throw `invalid coordinates: ${input}`;
  }
  if (Number.isFinite(input[0])) {
    const output = converter(input)
    return output;
  } else {
    return input.map((item) => convertRecursively(item, converter));
  }
}

function getConverter(from, to, gpsOrigin) {
  gpsOrigin = gpsOrigin || defaultGpsOrigin;
  if (from === to) return;
  if (from === "geoxy" && to === "xy") {
    return (coordinates) => {
      return geo2Pos(coordinates, { latitude: 0, longitude: 0, altitude: 0 })
    }
  } else if (from === "geoxy" && to === "epsg:4326") {
    if (!gpsOrigin) return c => c
    return (coordinates) => {
      const xyz: any = geo2Pos(coordinates, { latitude: 0, longitude: 0, altitude: 0 })
      return pos2Geo(xyz, gpsOrigin)
    }
  } else if (from === "xy" && to === "epsg:4326") {
    if (!gpsOrigin) {
      throw `conversion requires a gps origin`;
    }
    return (coordinates) => {
      let [positionX, positionY, positionZ] = coordinates;
      positionZ = positionZ || 0;
      const [longitude, latitude, altitude] = pos2Geo(
        [positionX, positionY, positionZ],
        gpsOrigin
      );
      return [longitude, latitude, altitude];
    };
  } else if (from === "epsg:4326" && to === "xy") {
    if (!gpsOrigin) {
      throw `conversion requires a gps origin`;
    }
    return (coordinates) => {
      let [longitude, latitude, altitude] = coordinates;
      altitude = altitude || 0;
      const [positionX, positionY, positionZ] = geo2Pos(
        [longitude, latitude, altitude],
        gpsOrigin
      );
      return [positionX, positionY, positionZ];
    };
  } else if (from === "epsg:4326" && to === "xyz") {
    if (!gpsOrigin) {
      throw `conversion requires a gps origin`;
    }
    return (coordinates) => {
      let [longitude, latitude, altitude] = coordinates;
      altitude = altitude || 0;
      const [positionX, positionY, positionZ] = geo2Pos(
        [longitude, latitude, altitude],
        gpsOrigin
      );
      return [positionX, positionY, positionZ];
    };
  } else {
    throw `unable to convert coordinates from ${from} to ${to}`;
  }
}

function convertGeoJsonCoordinates(geojson, converter = (a) => a) {
  if (Array.isArray(geojson)) {
    return geojson.map(feature => convertGeoJsonCoordinates(feature, converter))
  } else if (typeof geojson === "object") {
    const result = { ...geojson };
    for (let prop in geojson) {
      if (prop === "coordinates") {
        result[prop] = convertRecursively(geojson[prop], converter);
      } else {
        result[prop] = convertGeoJsonCoordinates(geojson[prop], converter);
      }
    }
    return result;
  } else {
    return geojson;
  }
}

export function convertGeoJson(geojson, params) {
  const { from, to, gpsOrigin } = params;
  const converter = getConverter(
    from || "epsg:4326",
    to || "epsg:4326",
    gpsOrigin
  );
  if (!converter) return geojson;
  const output = convertGeoJsonCoordinates(geojson, converter);

  return output;
}

// import * as toGeoJson from "@mapbox/togeojson";
// import { bindings } from "../bindings";
// export function kmlToGeojson(kmlString, options = {}) {
//   const parser = new bindings.DOMParser();
//   const kml = parser.parseFromString(kmlString, "application/xml");

//   const geojson = toGeoJson.kml(kml);

//   return geojson;
// }
