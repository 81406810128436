
const NULL_BIN_KEY = "-"
export default function computeDistribution(samples, config) {
    const bins = {}
    let n = 0, numbers = [], strings = [], nulls = []
    let min = Number.MAX_VALUE
    let max = - Number.MIN_VALUE
    for (let i = 0; i < samples.length; i++) {
        const s = samples[i]
        n++
        if (s === null || s === undefined) {
            nulls.push(i)
        } else if (Number.isFinite(s)) {
            numbers.push(i)
            min = Math.min(min, s)
            max = Math.max(max, s)
        } else if (typeof s === 'string') {
            strings.push(i)
        } else {
            throw `unable to compute domain with value ${typeof s === 'object' ? JSON.stringify(s) : s}`
        }
    }
    const nNulls = nulls.length
    const nStrings = strings.length
    const nNumbers = numbers.length
    if (nNulls) {
        const binKey = NULL_BIN_KEY
        bins[binKey] = {
            type: 'null',
            domain: null,
            key: binKey,
            indices: nulls
        }
    }
    if (nStrings)
        for (let i of strings) {
            const binKey = samples[i]
            if (!bins[binKey])
                bins[binKey] = {
                    type: 'string',
                    domain: binKey,
                    key: binKey,
                    indices: []
                }
            bins[binKey].indices.push(i)
        }
    if (nNumbers) {
        let roundedMin, step, roundedMax
        if (min === max) {
            roundedMin = min
            roundedMax = max
            step = 0
        } else {
            roundedMin = min
            roundedMax = max
            let nbins = (config.distribution || {}).nbins || 20
            if (config.domain) {
                [roundedMin, roundedMax] = config.domain
            }
            if (config.step) {
                nbins = Math.ceil((roundedMax - roundedMin) / config.step)
            }
            // todo round min and step
            step = Math.ceil((roundedMax - roundedMin) / nbins)
            // console.log('step', step)
            // console.log('roundedMin', roundedMin)
            // console.log('roundedMax', roundedMax)
            // console.log('nbins', Math.ceil((roundedMax - roundedMin) / step))
        }
        const computeBinKey = (v) => {
            const low = Math.floor((v - roundedMin) / step) * step + roundedMin
            return JSON.stringify([low, low + step])
        }
        for (let i of numbers) {
            let value = samples[i]
            if (value < roundedMin)
                value = roundedMin
            if (value > roundedMax)
                value = roundedMax
            const binKey = computeBinKey(value)
            if (!bins[binKey]) {
                bins[binKey] = {
                    type: 'number',
                    domain: JSON.parse(binKey),
                    key: binKey,
                    indices: []
                }
            }
            bins[binKey].indices.push(i)
        }
    }
    if (!n)
        return
    return Object.values(bins).map(bin => ({ ...bin, count: bin.indices.length }))
}

export function addDistributionToLegend(scaleState, container) {
    console.log('adding ', scaleState.distribution, 'to', container)

}