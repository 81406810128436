import { defaultGpsOrigin } from "../../defaults";
import { pos2Geo, bbox, GpsPoint } from "../../utils/geography";

export function getParticlesBbox(positions, gpsOrigin: GpsPoint|null = defaultGpsOrigin) {
  const filteredPositions = positions.filter(
    (input) =>
      Array.isArray(input) &&
      input.length >= 2 &&
      Number.isFinite(input[0]) &&
      Number.isFinite(input[1])
  );
  let bounds = bbox(filteredPositions);
  if (!bounds) return;
  const [xs, ys, zs] = bounds;

  const lowest = gpsOrigin ? pos2Geo([xs[0], ys[0], zs[0]], gpsOrigin) : [xs[0], ys[0], zs[0]];
  const greatest = gpsOrigin ? pos2Geo([xs[1], ys[1], zs[1]], gpsOrigin) : [xs[1], ys[1], zs[1]];
  const resultBbox = [
    [...lowest],
    [...greatest],
  ].map(point => [point[0], point[1], point[2] || 0])
  return resultBbox;
}
