import * as Comlink from "comlink"

let _workerBuilder = (url) => { throw 'not implemented' }

// Worker

export function bindWorkerBuilder(workerBuilder) {
    console.log('binding worker builder')
    _workerBuilder = workerBuilder
}
export function createWorker(workerUrl) {
    const worker = _workerBuilder(workerUrl)
    console.log('got worker', worker)
    return Comlink.wrap(worker) as any
}