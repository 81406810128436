export default [
    {
        name: 'minkowski',
        apply(samples, tr, datasets) {
            const { pattern } = tr
            let result = []
            for (let s of samples) {
                for (let other of pattern) {
                    let newSample = { ...s }
                    let ok = true
                    for (let prop in other) {
                        const value = other[prop]
                        if (typeof value === 'string') {
                            newSample[prop] = value
                        } else if (typeof value === 'number') {
                            if (!Number.isFinite(newSample[prop])) {
                                console.log('break', prop, newSample[prop])
                                ok = false
                                break
                            }
                            newSample[prop] += value
                        } else {
                            throw `unable to understand pattern item ${JSON.stringify(other)}`
                        }
                    }
                    if (ok) {
                        result.push(newSample)
                    }
                }
            }
            return result
        },
        schema: {
            type: 'object',
            properties: {
                'pattern': {
                    type: 'array',
                    items: {
                        type: 'object'
                    }
                }
            },
            required: ["pattern"]
        }
    }
] as any[]