import MapVizu from ".";
import { ScenegraphLayer } from "@deck.gl/mesh-layers/src/index";
import { COORDINATE_SYSTEM } from "@deck.gl/core";

import { getRegisteredLoaders } from '@loaders.gl/core/src/lib/api/register-loaders'
import { registerLoaders } from "@loaders.gl/core";
import { GLTFLoader } from "@loaders.gl/gltf";
import { OBJLoader } from '@loaders.gl/obj';

import { load } from "@loaders.gl/core";
// Register the proper loader for scenegraph.gltf
registerLoaders([GLTFLoader, OBJLoader]);

// import sampleSceneBin from "../../assets/models/UFC_FIGHT_ISLAND_STAGE_VENUE_GEO_11_01_2021.bin";
import Carte from ".";
import CarteManager, { carteBuilders } from "../carte-manager";

function mergeRanges(r1, r2) {
    if (!r1)
        return r2
    if (!r2)
        return r1
    return [Math.min(r1[0], r2[0]), Math.max(r1[1], r2[1]),]
}
const data = [{}];
export default class GroupCarte implements Carte {
    config: any;
    layers: any
    ranges: any
    cartes: any;

    async init() {
        const { cartes } = this.config
        this.cartes = [];
        for (let carteConfig of cartes) {
            const carte = new CarteManager({ ...this.config, ...carteConfig })
            await carte.initialized
            this.cartes.push(carte)
        }
    }


    getLayers(ctx) {
        let layers: any = []
        for (let c of this.cartes) {
            layers = [...layers, c.getLayers(ctx)]
        }
        return layers
    }
    getState() {
        return { children: this.cartes.map(cm => cm.getState()) }
    }
    getRanges() {
        const ranges = {}
        for (let cm of this.cartes) {
            const currentRanges = cm.getRanges()
            for (let key in currentRanges) {
                ranges[key] = mergeRanges(ranges[key], currentRanges[key])
            }
        }
        return ranges
    }
}
