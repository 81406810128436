import { render, html } from "lit";
import Carte from "./cartes";
import { getParticlesBbox } from "./cartes/common/bbox";
import HelpersCarte from "./cartes/helpers";
import StreamCarte from "./cartes/stream";
import TilesCarte from "./cartes/tiles";
import { GpsPoint } from "./utils/geography";

import * as d3 from 'd3'
import ImageCarte from "./cartes/image";
import GeojsonCarte from "./cartes/geojson";
import GltfCarte from "./cartes/gltf";
import GraphCarte from "./cartes/graph";
import DomainCarte from "./cartes/domain";
import EnvironmentCarte from "./cartes/environment";
import TrajectoryCarte from "./cartes/tajectory";
import TerrainCarte from "./cartes/terrain";
import GroupCarte from "./cartes/group";
import DataSet from "web-ui-blocks/data-pipe/dataset";
import LiveCarte from "./cartes/live";

export interface CarteConfig {
    name: string;
    type?: string;
    gpsOrigin?: GpsPoint;
    optional?: boolean;
}

export interface CarteState {
    name: string;
    h: number;
    error?: string;
    loaded: boolean;
    active: boolean;
    bbox?: [[number, number], [number, number]];
    timespan?: [number, number];
    custom: {
        [x: string]: any
    }
}

export type CarteSettings = {
    active: boolean;
}

export default class CarteManager {
    name: string;
    loaded: boolean;
    config: CarteConfig;
    carte: Carte;
    error?;

    lastContext: any = null;
    lastLayers: any = null;

    initialized: Promise<void>;

    settings: CarteSettings = {
        active: true
    }

    t0: number
    constructor(config: CarteConfig = { name: 'default' }, dataset: DataSet) {
        this.t0 = Date.now()
        this.loaded = false;
        this.config = config
        this.name = config.name;
        this.initialized = this.init(dataset).then(() => {
            this.loaded = true
            console.log(`carte ${this.name} (${this.config.type}) initialized in ${Date.now() - this.t0}ms`)
        }).catch((err) => {
            this.loaded = true
            this.error = err
            console.log(`carte ${this.name} (${this.config.type}) failed to initialize (${Date.now() - this.t0}ms)`)
            console.log(this.config)
            console.log(err)
        })
    }
    async init(dataset) {
        let { type } = this.config
        const builder = carteBuilders[type]
        if (!builder)
            throw `unknown carte ` + type
        const carte = builder(this.config);
        carte.config = this.config
        this.carte = carte;
        if (this.carte && this.carte.init)
            await this.carte.init(dataset, this.config);
    }
    getH() {
        const state = this.getState()

        return state.name + '-' + (state.h || 0)
    }
    getState(): CarteState {
        let internalState = {}
        if (this.carte.getState)
            internalState = this.carte.getState()

        const state: any = {
            name: this.name,
            error: this.error,
            loaded: this.loaded,
            h: this.carte.h || 0,
            ...this.settings,
            ...internalState
        }
        if (this.carte && this.carte.getRanges) {
            const ranges = this.getRanges()
            if (ranges && ranges.longitude && ranges.latitude) {
                const { longitude, latitude, } = ranges
                const altitude = ranges.altitude || [0, 0]
                state.bbox = [
                    [longitude[0], latitude[0]],
                    [longitude[1], latitude[1]],
                    altitude
                ]
            } else if (ranges && ranges.x && ranges.y) {
                let { x, y, z } = ranges;
                const points = [
                    [x[0], y[0], 0],
                    [x[0], y[1], 0],
                    [x[1], y[0], 0],
                    [x[1], y[1], 0],
                ]
                state.bbox = getParticlesBbox(points, this.config.coordinates === 'gps' ? null : this.config.gpsOrigin)
            }
            if (ranges && ranges.t) {
                state.timespan = ranges.t
            }
        }
        return state
    }
    // WARN : ne se supprime pas
    getTimedOverview(ti, tf) {
        const { timespan, loaded } = this.getState()
        if (loaded && timespan && this.carte.getTimeOverview) {
            return this.carte.getTimeOverview(ti, tf).filter(s => Number.isFinite(s.value) && s.value >= 0.0)
        }
        return []
    }
    async getIds(ctx) {
        if (this.carte && this.carte.getIds) {
            let ids = this.carte.getIds(ctx);
            return ids
        }
    }
    async getObject(id, ctx, robust = false) {
        try {
            if (this.carte && this.carte.getObject)
                return await this.carte.getObject(id, ctx);
        } catch (err) {
            if (!robust)
                throw err
        }

    }
    getRanges() {
        if (this.carte && this.carte.getRanges)
            try {
                return this.carte.getRanges()
            } catch (err) {
                return {}
            }
    }
    async getLayers(context) {
        if (false && context === this.lastContext)
            return this.lastLayers
        else if (this.carte) {
            let layers: any = this.carte.getLayers(context, this.lastContext, this.lastLayers)
            this.lastContext = context
            this.lastLayers = layers
            if (layers.then) {
                layers = await layers
            }
            layers = layers.map(l => {
                l.cm = this
                return l
            })
            return layers
        }
    }
    async release() {
        if (this.carte && this.carte.release)
            await this.carte.release();
    }
}

export const carteBuilders = {
    // shape should be configurable
    stream(config) {
        return new StreamCarte();
    },
    tiles(cfg) {
        return new TilesCarte();
    },
    helpers(c) {
        return new HelpersCarte()
    },
    image(c) {
        return new ImageCarte()
    },
    geojson(config) {
        return new GeojsonCarte();
    },
    gltf() {
        return new GltfCarte()
    },
    graph() {
        return new GraphCarte()
    },
    domain() {
        return new DomainCarte()
    },
    environment() {
        return new EnvironmentCarte()
    },
    trajectory() {
        return new TrajectoryCarte()
    },
    terrain() {
        return new TerrainCarte()
    },
    group() {
        return new GroupCarte()
    },
    live() {
        console.log('new live carte', ...arguments)
        return new LiveCarte()
    }



    // RealtimeCarte : websocket ou EventEmitter CREATE,UPDATE,DELETE
    // GroupCarte

    // kmz(config) {
    //     return new KmzVizu(config);
    // },
    // icons(cfg) {
    //     return new IconsVizu(cfg);
    // },
    // drones(config) {
    //     return new DronesVizu(config);
    // },
    // environment(config) {
    //     return new EnvironmentVizu(config);
    // },
    // geotiff(cfg) {
    //     return new GeotiffVizu(cfg);
    // },

};
