import './style.css'
import { render, html } from 'lit'
import { set } from 'object-path-immutable'
import { Notif, stringifyNotif } from './types'
let notifContainer
let refresher
let currentNotifications = []
const REFRESH_PERIOD = 1000;
const MAX_NOTIFS = 5;

let defaultNotification = {
    message: '',
    detail: '',
    theme: ['info'],
    duration: 5000,
    click: (e, id) => {
        console.log('click on notif', id);
        console.log(currentNotifications.filter(n => n.id === id))
        deleteNotification(id)
    }
}


let nextNotifId = 1;
function createNotif(args: any): Notif {
    if (typeof args === 'string')
        args = { message: arguments[0], details: arguments[1] }
    let {
        level,
        theme,
        message,
        details,
        group,
        action,
        id,
        timestamp,
    } = args

    if (!theme)
        theme = []
    if (theme) {
        if (typeof theme === 'string')
            theme = [theme]
    }
    level = level || 'info'
    timestamp = timestamp || Date.now()
    id = id || (nextNotifId++).toString()
    message = message || ''

    return {
        ...defaultNotification,
        ...args,
        level,
        message,
        theme,
        details,
        group,
        action,
        timestamp,
        id
    }
}

function numericLevel(level) {
    if (Number.isFinite(Number(level))) return Number(level)
    switch (level) {
        case 'critical':
            return 1
        case 'error':
            return 2
        case 'warning':
            return 3
        case 'info':
            return 4
        case 'debug':
        case 'trace':
            return 5
        default:
            return 3
    }
}

let MAX_LEVEL

export function init(parent, notifDefaults = {}, config: any = {}) {
    console.log('initializing notifications', parent, notifDefaults, config)
    let maxLevel = (config || {}).maxLevel

    MAX_LEVEL = numericLevel(maxLevel)
    if (notifDefaults)
        defaultNotification = { ...defaultNotification, ...notifDefaults }

    notifContainer = document.createElement('div')
    notifContainer.classList.add('notifications-container')
    parent.appendChild(notifContainer)
    refresher = setInterval(() => {
        refreshNotifications()
    }, REFRESH_PERIOD)
}

function addNotification(fullNotif) {
    currentNotifications.push(fullNotif)
    renderNotifications()
}

function refreshNotifications() {
    let now = Date.now()
    currentNotifications = currentNotifications.filter(n => (!n.duration) || ((n.timestamp + n.duration) > now))
    let n = currentNotifications.length
    if (n > MAX_NOTIFS)
        currentNotifications = currentNotifications.slice(n - 5)
    renderNotifications()
}

function renderNotifications() {
    render(html`<div class="notifications">
    ${currentNotifications.map(n => renderNotification(n))}
</div>
    `, notifContainer)
}


function renderNotification(notif) {

    let classes = notif.level
    if (notif.click)
        classes += " clickable"
    return html`
    <div class="notification ${classes}" title="${notif.hover || ''}"
        @click="${e => notif.click ? notif.click(e, notif.id) : ""}">
        <div class="content row">
            <div class="theme" title=${JSON.stringify(notif, null, 2)}>[${notif.theme.join('.')}]${notif.level}</div>
            <div class="message" title=${notif.details ? JSON.stringify(notif.details, null, 2) : ''}>${notif.message}</div>
            <!-- ${(notif.details && typeof notif.details === 'string' && notif.details.length < 200) ? html`<div class="notif-details">
                                            ${notif.details}
                                    </div>` : ''} -->
        </div>
        ${notif.actions && notif.actions.length ? html`<div class="actions"></div>` : ''}
    </div>`
}

export function deleteNotification(id) {
    currentNotifications = currentNotifications.filter(n => n.id !== id)
    renderNotifications()
}

let journal: any = {
    t0: Date.now(),
    entries: []
}

export function getJournal() {
    return journal
}
export default function notify(arg: Partial<Notif> | string) {
    if (!notifContainer)
        init(document.body)
    const notif = createNotif(arg)
    const level = numericLevel(notif.level)
    if (notif)
        journal.entries.push(notif)
    if (notif && (level <= MAX_LEVEL)) {
        addNotification(notif)
        console.log(stringifyNotif(notif), notif.details)
        return notif.id
    }
}