// in 

export function mulMM(m1, m2, dim) {
    const l1 = m1.length
    const l2 = m2.length
    if (!Number.isFinite(m1[0]) || !Number.isFinite(m2[0]))
        throw `matrices must be flattened`
    const dimIn = l2
    const dimOut = l2 / dimIn
    if (!Number.isInteger(dimOut))
        throw `invalid dimensions : m1:${l1} m2:${l2}`

    let out = new Array(dimOut)

}
export function mulMV(mat, vec) {
    if (!Number.isFinite(mat[0]) || !Number.isFinite(vec[0]))
        throw `matrices must be flattened`
    const dimIn = vec.length
    const dimOut = mat.length / dimIn
    if (!Number.isInteger(dimOut))
        throw `invalid dimensions : mat:${mat.length} vec:${vec.length}`

    let out = new Array(dimOut).fill(0)
    for (let i = 0; i < dimOut; i++) {
        for (let j = 0; j < dimIn; j++)
            out[i] += mat[i + j * dimOut] * vec[j]

    }
    return out
}
export function pitchToMat(roll) {
    return [
        Math.cos(roll), 0, -Math.sin(roll),
        0, 1, 0,
        Math.sin(roll), 0, Math.cos(roll)
    ]
}

export function rollToMat(roll) {
    return [
        1, 0, 0,
        0, Math.cos(roll), -Math.sin(roll),
        0, Math.sin(roll), Math.cos(roll)
    ]
}

export function yawToMat(roll) {
    return [
        Math.cos(roll), -Math.sin(roll), 0,
        Math.sin(roll), Math.cos(roll), 0,
        0, 0, 1
    ]
}


export function rpyToMat(r, p, y) {
    let mat = yawToMat(y)
    mat = mul(pitchToMat(p), mat)
    mat = mul(rollToMat(r), mat)
    return mat
}