import uploadFile from './file-upload-solid'
import downloadFile from './file-download-solid'
// import exportFile from './file-export-solid'
import layers from './layer-group-solid'
import question from './question-circle-regular'
import reset from './reply-solid'
import schema from './sitemap-solid'
import rocket from './rocket-solid'
import fit from './th-large-solid'
import thumbstack from './thumbstack'
import save from './save-solid'
import copy from './copy-solid'
import clean from './clean'
import cog from './cog'
import chart from './chart'

const importFile = uploadFile
const exportFile = downloadFile
export {
    importFile,
    exportFile,
    downloadFile,
    uploadFile,
    layers,
    question,
    reset,
    schema,
    rocket,
    fit,
    thumbstack,
    save,
    copy,
    clean,
    cog,
    chart
}