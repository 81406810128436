import { getChunkSamples } from './dataset/chunk';
import DataTable from './dataset/table';
import { createWorker } from './worker-utils'



import DataSet from "./dataset";
// import * as Comlink from "https://unpkg.com/comlink/dist/esm/comlink.mjs";

const workerUrl = new URL("./worker.js", import.meta.url)
// const workerUrl = new URL("http://localhost/neighbors-bench/build/worker.b7381a8b.js")
// const worker = new Worker(workerUrl, { type: 'module' });
// const loadSamplesInWorker: any = Comlink.wrap(worker)

export default async function createDataset(spec, inWorker = false): Promise<DataSet> {
    if (inWorker) {
        const datasetProxy = createWorker(workerUrl)
        console.log('dataset proxy', datasetProxy)
        try {
            console.log('calling dataset.init', spec)
            await datasetProxy.init(spec)
            console.log('dataset proxy initialized')
        } catch (err) {
            console.log('failed to initialize dataset')
            console.log(err)
            throw err
        }
        return datasetProxy
    } else {
        const dataset = new DataSet(spec)
        await dataset.ready
        return dataset
    }
}
export {
    DataTable,
    getChunkSamples,
    DataSet
}



// // WARNING : works only for ndjson
// export async function preloadSamples(input) {
//     input = await normalizeDataSpec(input)
//     return Load.preloadSamples(input)
// }


// export async function loadSamples(input) {
//     input = await normalizeDataSpec(input)
//     // return Load.loadSamples(input)
//     const samples = await loadSamplesInWorker(input)
//     return samples
// }
