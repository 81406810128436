const defaultConfig = {
  start: 0,
  end: 100 * 1000,
  rate: 1,
  showStart: null,
  showEnd: null
};

import { EventEmitter } from "events";

const clamp = (time, [min, max]) => {
  if (time < min) return min;
  if (time > max) return max;
  return time;
};
//emits : play, pause, frame,
export default class Player extends EventEmitter {
  constructor(config = {}) {
    super();
    this.config = Object.assign({}, defaultConfig, config);
    this.playing = false;
    this.sync = {
      real: 0,
      virtual: 0,
    };
    this.configure(config);
    this.reset()
  }
  configure(config) {
    this.synchronize()
    this.config = { ...this.config, ...config };
  }
  state() {
    const time = this.time();
    return {
      time,
      localTime: this.toLocalTime(time),
      status: this.playing ? "playing" : "paused",
    };
  }
  play() {
    this.synchronize();
    let old = this.playing;
    this.playing = true;
    this.tick();
    if (old !== this.playing) {
      this.emit("play");
    }
  }
  forward(dt = 1000) {
    dt = dt * this.config.rate;
    const newTime = Math.floor(this.time() / dt) * dt + dt
    this.synchronize(newTime);
  }
  backward(dt = 1000) {
    dt = dt * this.config.rate;
    const newTime = Math.ceil(this.time() / dt - 0.5) * dt - dt
    this.synchronize(newTime);
  }
  pause() {
    this.synchronize();
    let old = this.playing;
    this.playing = false;
    if (old !== this.playing) {
      this.emit("pause");
    }
  }
  reset() {
    const { start, end, showStart, showEnd, relative } = this.config;
    console.log('player config', this.config)
    let time = start
    if (Number.isFinite(showStart)) time = showStart
    this.range = [start, end];
    this.synchronize(time);
    this.pause();
  }

  tick() {
    if (this.raf) return;
    this.emit("tick", this.state());
    const { time, status } = this.state();
    if (time >= this.range[1] - 0.001) this.pause();
    if (this.playing)
      this.raf = requestAnimationFrame(() => {
        this.raf = null;
        this.tick();
      });
    else this.raf = null;
  }
  toLocalTime(t) {
    let localTime = t;
    if (this.config.showStart) localTime = t - this.config.showStart;
    else if (this.config.relative) localTime = t - (this.range || [0])[0]
    return localTime
  }
  time() {
    let time = this.playing
      ? this.config.rate * (Date.now() - this.sync.real) + this.sync.virtual
      : this.sync.virtual;
    if (this.range) time = clamp(time, this.range);
    return time;
  }
  synchronize(t) {
    let virtual = t;
    if (virtual === undefined) virtual = this.time();
    if (this.range) virtual = clamp(virtual, this.range);
    const real = Date.now();
    this.sync = {
      real,
      virtual,
    };
    this.emit("tick", this.state());
  }
}
