import { getDriveApi } from "../../google"
import { ConfigPreset } from "./preset"
import { SimpleToolSettings } from "./settings"
import { getVariable, resolveUrl } from "./variables"

const { searchParams } = new URL(document.URL)


const blobFromB64 = (b64Data, sliceSize = 512) => {
    let contentType = 'application/octet-stream'
    let [header, data] = b64Data.split(',')
    if (data) {
        console.log('header', header)
        b64Data = data
    }
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

//   const contentType = 'image/png';
//   const b64Data = 'iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==';

//   const blob = b64toBlob(b64Data, contentType);
//   const blobUrl = URL.createObjectURL(blob);

//   const img = document.createElement('img');
//   img.src = blobUrl;
//   document.body.appendChild(img);

//?state=%7B%22ids%22:%5B%221Uu2C6yuqbFcbSvB6GXacSaVAFIdAEAGs%22%5D,%22action%22:%22open%22,%22userId%22:%22111416900778206777946%22,%22resourceKeys%22:%7B%7D%7D

export default {
    async gdrive(parameters, settings) {
        let state = searchParams.get('state')
        if (state) {
            console.log('got state param', state)
            const json = JSON.parse(state)
            let { ids, folderId, userId, action } = json
            switch (action) {
                case 'open':
                    {
                        const drive = await getDriveApi()
                        if (!Array.isArray(ids))
                            ids = [ids].filter(Boolean)
                        const fileId = ids[0]
                        if (fileId) {
                            const fileResult = await drive.files.get({ fileId, fields: '*' })
                            const fileContent = await drive.files.get({ fileId: fileId, 'alt': 'media' }, { responseType: 'blob' })
                            const body = fileContent.body
                            const name = fileResult.result.name.split('.')[0]
                            console.log(name)
                            return { raw: new Blob([body], { type: fileContent.headers['Content-Type'] }), name, }
                        }

                    }
                case 'create':
                    throw `Create handler not implemented`
                default: throw `Invalid action : ${action}`
            }
        }
    },
    async channel(params = {}, settings) {
        const { channel } = settings || {}
        if (channel) {
            console.log('waiting for message from channel', channel)
            return new Promise((res, rej) => {
                window.onmessage = (msg) => {
                    console.log('received message', msg)
                }
            })
        }
        return false
    },
    async url(params = {}, settings) {
        let url = getVariable("url")
        if (url) {
            url = resolveUrl(url)
            const blob = await fetch(url).then(r => r.blob())
            return { raw: blob, name: url.split('/').filter(Boolean).pop() }
        }
        return false
    },
    async raw(params = {}, settings) {
        const key = (params || {}).key || 'raw'
        if (searchParams.has(key)) {
            let raw = searchParams.get(key) || ""
            console.log('found raw config in url')
            console.log(raw)
            raw = decodeURIComponent(raw)
            console.log(raw)

            const blob = blobFromB64(raw)
            console.log(await blob.text())
            const final = { raw: blob, name: searchParams.get("name") || 'raw-url-encoded' }
            console.log(final)
            return final
        }
        return false
    },
    async drop(params = {}, settings, useConfig = () => true) {
        const target = document.body
        const DROP_HANDLER_KEY = "_st_drop_handler"
        if (target[DROP_HANDLER_KEY]) {
            console.log('drop handler already registered')
            return
        }

        target.ondragover = (ev) => {
            ev.preventDefault()
            console.log('dragover', ev)
        }
        target.ondrop = (ev: any) => {
            ev.preventDefault()
            console.log('dropped', ev.dataTransfer)
            for (let f of ev.dataTransfer?.files || []) {
                console.log(f)

                const result = { raw: f, name: f.name, source: 'drop' }
                console.log('returning', result)

                useConfig(result, 'drop')
                return result
            }
        }
        target[DROP_HANDLER_KEY] = true
        return false
    }



} as { [name: string]: (params?: any, settings?: SimpleToolSettings, handler?: (a, b) => any) => Promise<Partial<ConfigPreset> | any> }






