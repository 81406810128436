export type DataChunk = {
    table?: string,
    offset: number,
    size: number,
    nrows: number,
    series?: () => { [name: string]: any[] }
    samples?: () => any[]
    t?: number,
    tFirstByte?: number, // filled by the instance
    tLoaded?: number, // filled by the instance // time when base chunk data was received
    tParsed?: number, // time when base chunk was ready
    tEnd?: number, // transformed+schema+cleaning
    error?: string
}

export function getChunkSamples(chunk: DataChunk): any[] {
    if (chunk.samples) {
        if (Array.isArray(chunk.samples))
            return chunk.samples
        return chunk.samples()
    }
    throw `unable to get samples from chunk`
}

export function getChunkSeries(chunk: DataChunk): { [name: string]: any[] } {
    if (chunk.series)
        return chunk.series()
    throw `unable to get series from chunk`
}


export function mergeChunks(chunks) {
    let mergedSize = 0, mergedError, mergedNRows = 0
    for (let { size, error, nrows } of chunks) {
        mergedSize += size
        mergedNRows += nrows
        if (error)
            mergedError = error
    }
    return {
        // offset,
        size: mergedSize,
        samples: () => {
            let samples = []
            for (let chunk of chunks)
                samples = [...samples, ...getChunkSamples(chunk)]
            return samples
        },
        tFirstByte: Math.min(...chunks.map(c => c.tFirstByte)),
        tLoaded: Math.max(...chunks.map(c => c.tLoaded)),
        error: mergedError,
        nrows: mergedNRows
    }
}


export const DEFAULT_CHUNK_SIZE = 1024 * 1024 * 10