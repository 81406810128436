import { divide, dot, modulus, multiply, norm, phase, rotate } from "./complex"
import { forwardFft } from "./fft"
import { LONG_SYNC_FREQUENCY_DOMAIN } from "./wifi"

const PILOT_BASE_SEQUENCE = [
    { i: 1, q: 0 },
    { i: 1, q: 0 },
    { i: 1, q: 0 },
    { i: -1, q: 0 }
]
const PILOT_POLARITY = [
    1, 1, 1, 1,
    -1, -1, -1, 1,
    -1, -1, -1, -1,
    1, 1, -1, 1,
    -1, -1, 1, 1,
    -1, 1, 1, -1,
    1, 1, 1, 1,
    1, 1, -1, 1,
    1, 1, -1, 1,
    1, -1, -1, 1,
    1, 1, -1, 1,
    -1, -1, -1, 1,
    -1, 1, -1, -1,
    1, -1, -1, 1,
    1, 1, 1, 1,
    -1, -1, 1, 1,
    -1, -1, 1, -1, 1, -1, 1, 1, -1, -1, -1, 1, 1, -1, -1, -1, -1, 1, -1, -1, 1, -1, 1, 1, 1, 1, -1, 1, -1, 1, -1, 1,
    -1, -1, -1, -1, -1, 1, -1, 1, 1, -1, 1, -1, 1, 1, 1, -1, -1, 1, -1, -1, -1, 1, 1, 1, -1, -1, -1, -1, -1, -1, -1
]
function equalize(samples, reference, polarity = 1) {
    const pilotOnes = samples.filter(s => s.carrierType === 'pilot').map((s, i) => multiply(s, multiply(PILOT_BASE_SEQUENCE[i], polarity)))
    const pilotReference = pilotOnes.map(s => reference.find(r => r.carrier === s.carrier))
    const pilotRatios = pilotOnes.map((s, idx) => {
        const ratio = divide(s, pilotReference[idx])
        console.log('ratio for pilot ', s.carrier, ' : ', ratio)
        console.log(s, pilotReference[idx])
        return ratio
    })
    return samples.map((s, idx) => {
        const { carrier } = s
        let ratio = null
        if (carrier < -14) {
            ratio = pilotRatios[0]
        } else if (carrier < 0) {
            ratio = pilotRatios[1]
        } else if (carrier <= 14) {
            ratio = pilotRatios[2]
        } else {
            ratio = pilotRatios[3]
        }
        const equalized = divide(s, multiply(reference.find(r => r.carrier === carrier), ratio))
        return equalized
    })
}
function interpolateAngle(a1, a2, alpha) {
    let z1 = {
        i: Math.cos(a1),
        q: Math.sin(a1)
    }
    let z2 = {
        i: Math.cos(a2),
        q: Math.sin(a2)
    }
    let z = {
        i: alpha * z2.i + (1 - alpha) * z1.i,
        q: alpha * z2.q + (1 - alpha) * z1.q,
    }

    return phase(z)
    const p = 2 * Math.PI
    while (a1 < 0)
        a1 += p
    while (a2 < 0)
        a2 += p
    if (a1 > a2)
        a2 += p
    if (a2 - a1 > p / 2)
        a2 -= p
    const f = alpha //* alpha * alpha * alpha
    let result = f * a2 + (1 - f) * a1
    return result
}



function fitPhaseShift(inputs, outputs) {
    console.log('fit phase shift', inputs, outputs)
    return carrier => {
        let lowIdx = -1, highIdx = -1
        for (let idx = 0; idx < inputs.length; idx++) {
            const delta = carrier - inputs[idx]
            if (delta >= 0) {
                if (lowIdx < 0 || Math.abs(delta) < Math.abs(carrier - inputs[lowIdx]))
                    lowIdx = idx
            } else {
                if (!highIdx || Math.abs(delta) < Math.abs(carrier - inputs[highIdx]))
                    highIdx = idx
            }
        }
        // lowIdx = 1
        // highIdx = 2
        if (lowIdx < 0 && highIdx < 0) {
            return 0
        } else if (lowIdx < 0) {
            return outputs[highIdx]
        } else if (highIdx < 0) {
            return outputs[lowIdx]
        } else {
            let alpha, angle
            // const alpha = Math.round((carrier - lowCorrector.carrier) / (highCorrector.carrier - lowCorrector.carrier))
            alpha = ((carrier - inputs[lowIdx]) / (inputs[highIdx] - inputs[lowIdx]))
            let lowAngle = outputs[lowIdx]
            let highAngle = outputs[highIdx]
            angle = alpha * highAngle + (1 - alpha) * lowAngle
            angle = interpolateAngle(lowAngle, highAngle, alpha)
            return angle
        }
    }

}

export default {
    name: 'ofdm3',
    apply(samples, tr, datasets) {
        const defaultConfig = {
            header: 0,
            size: 64,
            gi: 16,
            pilots: [
                -21,
                -7,
                7,
                21
            ],

            data: [
                -26, -25, -24, -23, -22, // -21 : pilot
                -20, -19, -18, -17, -16, -15, -14, -13, -12, -11, -10, -9, -8, // -7 : pilot
                -6, -5, -4, -3, -2, -1,
                // 0,
                1, 2, 3, 4, 5, 6, // 7 : pilot
                8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, // 21 : pilot
                22, 23, 24, 25, 26
            ]
        }
        let { size, gi, pilots, data } = { ...defaultConfig, ...tr } as any
        const symbolLength = gi + size
        if (!this.samples) {
            this.samples = []
        }
        function symbolDetector(samples, offset) {
            const v1 = samples.slice(offset, offset + gi)
            const v2 = samples.slice(offset + size, offset + gi + size)
            const n1 = norm(v1)
            const n2 = norm(v2)
            const n1n2 = n1 * n2
            let { i, q } = dot(v1, v2)
            i /= n1n2
            q /= n1n2
            const n = Math.sqrt(i * i + q * q)
            return n
        }
        let result = []
        samples = [...this.samples, ...samples]

        let o = 0
        let symbolIndex = 0
        let references = [], reference
        while (o + symbolLength <= samples.length) {
            console.log(symbolIndex, 'ofdm symbol at', o + '/' + samples.length)
            let currentGi = symbolIndex < 2 ? 0 : gi
            let currentSymbolLength = currentGi + size

            console.log("currentGi", currentGi)
            console.log("currentSymbolLength", currentSymbolLength)
            const symbol = samples.slice(o, o + currentSymbolLength)
            const guardInterval = symbol.slice(0, currentGi)
            const cursor = 0
            const dataSamples = symbol.slice(currentGi - cursor, currentSymbolLength - cursor)

            const spectrum = forwardFft(dataSamples)

            const pilotSpectrum = pilots.map(idx => ({ carrier: idx, carrierType: 'pilot', ...spectrum[(idx + size) % size] }))
            const dataSpectrum = data.map(idx => ({ carrier: idx, carrierType: 'data', ...spectrum[(idx + size) % size] }))

            let localResult = [
                ...pilotSpectrum,
                ...dataSpectrum
            ]
            localResult.sort((a, b) => a.carrier - b.carrier)

            console.log("localResult", localResult)




            if (symbolIndex < 2) {
                const polarities = LONG_SYNC_FREQUENCY_DOMAIN.filter(Boolean)
                localResult = localResult.map((r, i) => multiply(r, polarities[i]))
                references = [...references, ...localResult]
            } else {
                console.log('data symbol', symbolIndex)
                localResult = equalize(localResult, reference, PILOT_POLARITY[symbolIndex - 1])
                result = [...result, ...localResult]
            }
            o += currentSymbolLength
            symbolIndex++
            if (symbolIndex === 2) {
                reference = []
                for (let carrierIndex of [...pilots, ...data]) {
                    const values = references.filter(c => c.carrier === carrierIndex)
                    const value = values.reduce((prev, curr) => ({ i: prev.i + curr.i / values.length, q: prev.q + curr.q / values.length }))
                    reference.push({
                        carrier: carrierIndex,
                        ...value
                    })
                }
            }

        }

        // return reference


        this.samples = samples.slice(o)
        return result//.filter(s=>s.db > -300)
    },
    schema: {
        header: {
            type: 'number'
        },
        size: {
            type: 'number'
        },
        gi: {
            type: 'number'
        },
        pilots: { type: 'array', items: 'number' },
        data: { type: 'array', items: 'number' }
    }
}