export function norm(vec) {
    let acc = 0
    for (let { i, q } of vec) {
        acc += i * i + q * q
    }
    return Math.sqrt(acc)
}
export function dot(v1, v2) {
    const n = v1.length

    let accu_i = 0, accu_q = 0
    for (let idx = 0; idx < n; idx++) {
        const s1 = v1[idx]
        const s2 = v2[idx]
        accu_i += s2.i * s1.i + s2.q * s1.q
        accu_q += -s1.i * s2.q + s1.q * s2.i
    }
    return { i: accu_i, q: accu_q }
}

export function phase({ i, q }) {
    return Math.atan2(q, i)
}

export function modulus({ i, q }) {

    return Math.sqrt(i * i + q * q)
}

export function rotate(input, rads) {
    const { i, q } = input
    const c = Math.cos(rads)
    const s = Math.sin(rads)
    return {
        ...input,
        i: i * c - q * s,
        q: q * c + i * s
    }
}

export function multiply(a, b) {
    if (typeof b === 'number')
        b = { i: b, q: 0 }
    const i = a.i * b.i - a.q * b.q
    const q = a.i * b.q + a.q * b.i
    return {
        ...a,
        i,
        q
    }
}

export function conjugate(a) {
    return {
        ...a,
        i: a.i,
        q: -a.q
    }
}

export function divide(a, b) {
    if (typeof b === 'number')
        b = { i: b, q: 0 }
    const n = modulus(b)
    const n2 = n * n
    let result = multiply(a, conjugate(b))
    result = multiply(result, 1 / n2)
    return {
        ...a,
        ...result
    }
}

