const TAG_TELEMETRY_URL = "http://localhost:8080/taguwb.ndjson"
const DRONE_TELEMETRY_URL = "http://localhost:8080/DRONE.ndjson"
const ANCHORS_URL = "http://localhost:8080/anchors.json"
const ANCHORS_TELEMETRY_URL = "http://localhost:8080/anchoruwb.ndjson"
const BLACKBOX_URL = "http://localhost:8081/all-blackboxes.ndjson"
export default {
    name: "uwb-flight-bboxes",
    canvas: '.descartes-canvas',
    defaults: {
        gpsOrigin: {
            latitude: 51.470,
            longitude: 0.450,
        }
    },
    controls: {
        cartes: true,
        hover: true,
        selection: true,
        global: true,
        player: true
    },
    details: 'tabs',
    "variables": {
        "blackbox_url": {
            "type": "string",
            "default": "http://localhost:8080/18-12-2022/all-blackboxes.ndjson"
        }
    },
    scales: [
        {
            name: 'tag-color-scale',
            format: 'simple',
            legend: false
            // domain: [-2.0, 0.0]
        },
        {
            name: 'anchor-color-scale',
            format: 'simple',
            domain: [0, 750],
            title: 'blinks received'
            // domain: [-2.0, 0.0]
        }
    ],
    dataset: [
        {
            name: 'blackbox',
            url: {
                __var: "blackbox_url"
            },
            transforms: [

                {
                    type: "compute",
                    expr: "d=>[255,0,0]",
                    as: "color"
                },
                {
                    type: "compute",
                    expr: "d=>d.id",
                    as: "id"
                },
                {
                    type: "compute",
                    expr: "d=>d.time_us/1000000",
                    as: "time"
                }
            ]
        }
    ],
    cartes: [
        {
            type: 'helpers'
        },
        {
            name: 'estimation',
            type: 'stream',
            samples: "blackbox",
            position: 'd=>[d.position_EST_x, d.position_EST_y, d.height_EST_m]',

            color: [0, 0, 200],
            solid: true
        },
        {
            name: 'uwb',
            type: 'stream',
            samples: "blackbox",
            position: 'd=>[d.uwb_position_x, d.uwb_position_y, -d.uwb_position_z]',
            color: [0, 200, 0],
            solid: true
        },
        {
            name: 'reference',
            type: 'stream',
            samples: "blackbox",
            position: 'd=>[d.position_REF_x, d.position_REF_y, d.height_REF_m]',
            color: [200, 0, 0],
            solid: true
        }
    ]
}