import { decodeConstellationPoint } from "../dsp/modulation"

function bitsFrom(input) {
    if (Array.isArray(input)) {
        return input
    } else if (typeof input === 'number') {
        throw `conversion from number to bits not implemented : ${input}`
    } else if (typeof input === "string") {
        const chars = input.split("").filter(c => ["1", "0"].includes(c))
        return chars.map(c => {
            const num = Number(c)
            return { bit: num }
        })
    } else {
        throw `unable to convert ${input} into bits`
    }
}
function renderBits(bits) {
    bits = bitsFrom(bits)
    let str = ""
    let wordLength = 4
    let i = 0
    for (let { bit } of bits) {
        str += Math.round(bit || 0).toString()
        i++
        if (wordLength && !(i % wordLength))
            str += " "
    }
    console.log(str)
}


const permutation1 = []
const permutation2 = []
function composePermutations(p1, p2) {
    const result = []
    for (let i = 0; i < p1.length; i++) {
        const pair1 = p1.find(p => p[0] === i)
        const pair2 = p2.find(p => p[0] === pair1[1])
        result.push([pair1[0], pair2[0]])
    }
    return result
}
function invertPermutation(p) {
    return p.map(([a, b]) => ([b, a]))
}
function permute(bits, permutation) {
    const result = []
    for (let i = 0; i < bits.length; i++) {
        const pair = permutation.find(p => p[1] === i)
        const iniitalIndex = pair[0]
        result.push(bits[iniitalIndex])
    }
    return result
}
const N = 48
for (let k = 0; k < N; k++) {
    const i = (N / 16) * (k % 16) + Math.floor(k / 16)
    const j = k + ((k + N - Math.floor(16 * k / N)) % 1)
    permutation1.push([k, i])
    permutation2.push([k, j])
}

const permutation = composePermutations(permutation1, permutation2)


function convolutionalEncoding(bits) {
    const n = bits.length
    const K = 7
    let state = new Array(K).fill({ bit: 0 })
    let output = []
    for (let i = 0; i < n; i++) {
        state = [bits[i], ...state.slice(0, 6)]
        let g0 = 0, g1 = 0
        for (let idx of [0, 2, 3, 5, 6])
            g0 += state[idx].bit
        for (let idx of [0, 1, 2, 3, 6])
            g1 += state[idx].bit
        g0 = (g0) % 2
        g1 = (g1) % 2
        output.push({ bit: g0 })
        output.push({ bit: g1 })
    }
    return output
}

// simplest decoding, ignoring g1
function convolutionalDecoding(bits, state = null) {
    console.log('convolutional decoding', bits)
    const n = bits.length
    const K = 7
    if (!state)
        state = new Array(K).fill({ bit: 0 })
    let output = []
    for (let i = 0; i < n; i += 2) {
        let currentBit = { bit: 0 }
        state = [currentBit, ...state.slice(0, 6)]
        let g0 = 0, g1 = 0
        let expectedG0 = bits[i].bit, expectedG1 = bits[i + 1].bit
        for (let idx of [0, 2, 3, 5, 6])
            g0 += state[idx].bit
        for (let idx of [0, 1, 2, 3, 6])
            g1 += state[idx].bit
        g0 = (g0) % 2
        g1 = (g1) % 2

        let bitIs1 = 0
        if (g0 !== expectedG0) {
            const { confidence } = bits[i]
            console.log('bit', i / 2, 'is not 0 based on g0', confidence)
            if (confidence > 0.6) {
                bitIs1 = 1
            }
        }
        if (g1 !== expectedG1) {
            const { confidence } = bits[i + 1]
            console.log('bit', i / 2, 'is not 0 based on g1', confidence)
            if (confidence > 0.6) {
                bitIs1 = 1
            }
        }
        if (bitIs1)
            currentBit.bit = 1
        output.push(currentBit)
    }
    return output
}

function createChunker(size) {
    let buffer = []
    return {
        push(s) {
            buffer.push(s)
        },
        pump() {
            if (buffer.length >= size) {
                let chunk = buffer.slice(0, size)
                buffer = buffer.slice(size)
                return chunk
            }
        }
    }
}


function encodeWifiBits(input) {
    let current = bitsFrom(input)
    // renderBits(current)
    current = convolutionalEncoding(current)
    // renderBits(current)
    current = permute(current, permutation)
    // renderBits(current)
    return current
}

function decodeWifiBits(input) {
    let current = bitsFrom(input)
    const p = invertPermutation(permutation)
    current = permute(current, p)
    // renderBits(current)
    current = convolutionalDecoding(current)
    // renderBits(current)
    return current
}

const CODED_BITS_PER_SYMBOL = 48 // BPSK 1/2
const DATA_BITS_PER_SYMBOL = 24 // BPSK 1/2


export const encode = {
    name: 'encode',
    apply(samples, tr, datasets) {
        if (!this.chunker)
            this.chunker = createChunker(DATA_BITS_PER_SYMBOL)
        for (let s of samples)
            this.chunker.push(s)
        let bits
        let result = []
        while (bits = this.chunker.pump()) {
            result = [...result, ...encodeWifiBits(bits)]
        }

        return result
    },
    schema: {
        type: 'object',
        description: "encode user bits into wifi coded bits, applying convolutional encoding and interleaving",
        properties: {
        }
    }
}

export const decode = {
    name: 'decode',
    apply(samples, tr, datasets) {
        if (!this.chunker)
            this.chunker = createChunker(CODED_BITS_PER_SYMBOL)
        samples = samples.filter(s => s.carrierType === 'data')
        for (let s of samples) {
            console.log('decoding ', s)
            this.chunker.push(s)
        }
        let iqSamples
        let result = []
        while (iqSamples = this.chunker.pump()) {
            const bits = iqSamples.map(s => decodeConstellationPoint(s))
            let decodedBits= decodeWifiBits(bits)
            result = [...result, ...decodedBits]
        }

        return result
    },
    schema: {
        type: 'object',
        description: "decode wifi coded bits into data bits, applying deinterleaving and convolutional decoding",
        properties: {
        }
    }
}
