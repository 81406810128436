import "@fortawesome/fontawesome-free/css/all.min.css";
import { LitElement, css, html } from "lit";
class PlayerComponent extends LitElement {
  constructor() {
    super();
  }
  static get properties() {
    return {};
  }
}
// customElements.define('player-view', PlayerComponent)

import "./player-style.css";

function createElement(container, cls, type = "div") {
  let child
  switch (type) {
    case 'svg': child = document.createElementNS("http://www.w3.org/2000/svg", "svg"); break;
    default: child = document.createElement(type); break;
  }
  child.classList.add(cls);
  container.appendChild(child);
  return child;
}
export default function createPlayerView(player, elt) {
  elt.innerHTML = ''
  elt.classList.add("player");

  //<div class="controls">
  //     <div class="backward"></div>
  //     <div class="playpause"></div>
  //     <div class="forward"></div>
  //     <select name="" class="rate">
  //         <option value="0.1">0.1x</option>
  //         <option value="0.25">0.25x</option>
  //         <option value="0.5">0.5x</option>
  //         <option value="1" selected>1x</option>
  //         <option value="2">2x</option>
  //         <option value="4">4x</option>
  //         <option value="10">10x</option>
  //     </select>
  // </div>

  // <div class="timeline"></div>
  // <div class="chrono"></div>

  const controls = createElement(elt, "controls");
  const timeline = createElement(elt, "timeline");
  const overview = createElement(timeline, "overview", "svg");
  overview.setAttribute("preserveAspectRatio", "none");
  overview.setAttribute("width", "100%");
  overview.setAttribute("height", "100%");
  const range = createElement(timeline, "range", "input");
  range.type = "range";
  range.min = player.range[0];
  range.max = player.range[1];

  const backward = createElement(controls, "backward");
  const playpause = createElement(controls, "playpause");
  const forward = createElement(controls, "forward");
  const rate = createElement(controls, "rate", "select");
  const chrono = createElement(controls, "chrono");
  const options = [
    ["0.1x", 0.1],
    ["0.25x", 0.25],
    ["0.5x", 0.5],
    ["1x", 1],
    ["2x", 2],
    ["4x", 4],
    ["10x", 10],
  ];
  for (let [label, value] of options) {
    const o = document.createElement("option");
    o.innerHTML = label;
    o.value = value;
    rate.appendChild(o);
  }
  rate.value = 1;

  const playIcon = '<i class="far fa-play-circle"></i>';
  const pauseIcon = '<i class="far fa-pause-circle"></i>';
  const forwardIcon = '<i class="fas fa-step-forward"></i>';
  const backwardIcon = '<i class="fas fa-step-backward"></i>';

  playpause.innerHTML = playIcon;
  playpause.classList.add("paused");

  forward.innerHTML = forwardIcon;
  backward.innerHTML = backwardIcon;

  function handleStatus(status) {
    for (let cls of ["playing", "paused"]) {
      if (status === cls) {
        playpause.classList.add(cls);
      } else {
        playpause.classList.remove(cls);
      }
    }
  }

  function togglePlayPause() {
    const { status } = player.state();
    if (status === "paused") {
      player.play();
    } else if (status === "playing") {
      player.pause();
    }
  }

  elt.focus();
  window.addEventListener("keydown", (evt) => {
    if (evt.target.nodeName === 'INPUT' && evt.target.type !== 'range')
      return
    switch (evt.key) {
      case " ":
        togglePlayPause();
        break;
      case 'ArrowRight':
      case "l":
        player.forward();
        break;
      case 'ArrowLeft':
      case "j":
        player.backward();
        break;
      default:
        return;
    }
    evt.stopPropagation();
  }, true);

  const ptime = (num) => {
    const h = Math.floor(num / 3600)
    const m = Math.floor((num % 3600) / 60)
    const s = (num % 60)
    let str = ''
    if (h)
      str += h.toString().padStart(2, "0") + ":"
    if (m)
      str += m.toString().padStart(2, "0") + ":"
    str += pnum(s)
    return str
  };
  const pnum = (num) => (num || 0).toFixed(2).padStart(5, "0");
  function update(state) {
    const { time, localTime, status } = state;
    const [min, max] = player.range;
    const chronoMax = player.toLocalTime(player.config.showEnd || max);
    chrono.innerHTML = `${ptime(localTime / 1000)} / ${ptime(chronoMax / 1000)}`;
    range.value = time;
  }

  player.on("play", () => {
    playpause.innerHTML = pauseIcon;
    handleStatus("playing");
  });
  player.on("pause", () => {
    playpause.innerHTML = playIcon;
    handleStatus("paused");
  });
  player.on("tick", update);
  playpause.addEventListener("click", () => {
    togglePlayPause();
  });

  forward.addEventListener("click", () => player.forward());
  backward.addEventListener("click", () => player.backward());
  rate.addEventListener("change", (evt) => {
    const value = Number(evt.target.value);
    player.configure({ rate: value });
  });
  range.oninput = (ev) => {
    const newValue = Number(ev.target.value);
    player.synchronize(newValue);
  };

  update(player.state());

  return update;
}
