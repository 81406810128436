import {until} from 'lit/directives/until';
import { html, render } from "lit"
import { set } from "object-path-immutable"
import './control-panel.css'
import { getCurrentURL, getRawVariables, getVariable, getVariableSchemas, setupVariables, setVariable, specialize } from './variables';
import { getJournal } from '../../notifications';
import { getConfig } from '.';
import renderJournal from '../../journal/render';
const cls = (list)=>list.filter(Boolean).join(" ")

declare const navigation  : any;

const TABS = [
    {
        name: 'config',
        render(state, update){
            const {computedConfig, preset} = state
            const jsonPromise = getConfig(preset, computedConfig).catch(err=>{
                return {error: err.toString()}
            })

            async function handleCopyJSON(){
                const json = JSON.stringify(await jsonPromise, null, 2)
                await navigator.clipboard.writeText(json)
            }

            return html`<div class="column">
                <div class="row">
                    <div>
                    <input type="checkbox" .checked="${computedConfig}" @click="${()=>update(['computedConfig'], !computedConfig)}"/><label>expand variables</label>
                    <button @click="${handleCopyJSON}">copy JSON</button>
    
                </div>
                </div>
                <div>${until(jsonPromise.then((json)=>html`<pre>${JSON.stringify(json, null, 2)}</pre>`) , html`<div>Loading ...</div>`)}</div>
            </div>`  
        }
    },
    {
        name: 'variables',
        render(state, update){
            let pendingChanges = {}
            function handleInputChange( evt){
                pendingChanges[this.name] = evt.target.value
                for(let key in pendingChanges){
                    setVariable(key, pendingChanges[key])
                }
                pendingChanges = {}
            }
            function handleReset(){
                setupVariables()
                update([], state)
            }
            function handleReload(){
                const url = getCurrentURL(state.preset)
                console.log('current url', url)
                navigation.navigate(url)
            }
            async function handleCopyURL(){
                const url = getCurrentURL(state.preset)
                console.log('current url', url)
                await navigator.clipboard.writeText(url)
                console.log('copied')
            }
            async function handleCopyJSON(){
                const variables = getRawVariables()
                const json = JSON.stringify(variables, null, 2)
                console.log('current variables', json)
                await navigator.clipboard.writeText(json)
                console.log('copied')
            }
            const {computedConfig, preset} = state
            const {raw, name, source, id, } = preset
            const variables = getRawVariables()
            const vars =  getConfig(state.preset)
            .then(json=>{
                const schemas = getVariableSchemas()
                const names = Object.keys(schemas)
                for(let name in variables)
                    if(!names.includes(name))
                        names.push(name)
                names.sort()
                
                const vars = names.map(name=>({name, schema: schemas[name], raw: variables[name], value: getVariable(name,false )}))
                return vars
            })
            return html`
            <div class="row">
                <button @click="${handleReset}">reset</button>
                <button @click="${handleReload}">reload</button>
                <button @click="${handleCopyURL}">copy URL</button>
                <button @click="${handleCopyJSON}">copy JSON</button>
            </div>
            <div class="column variables">
                ${until(vars.then((v)=>v.map(({name, schema, value, raw}, i)=>html`<div class="row variable">
                <div class="source" >${(schema || "").source}</div>
                <div class="name" title="${JSON.stringify(schema)}">${name}</div>
                <input type="text" @input="${handleInputChange.bind(v[i])}" class="raw" value="${raw}">
                <div  class="value" title="${JSON.stringify(value, null, 2)}">${JSON.stringify(value)}</div>
                </div>`)) , html`<div>Loading ...</div>`)}
            </div>
            `  
        }
    },
    {
        name: 'journal',
        render(state, update){
            return renderJournal(state,update)
        }
    }
]


function renderControlPanel(state, container, update) {
    function goto(tab){
        update(['tabState'], null) 
        update(['tab'], tab) 
    }
    render(html`<div>
        <div class="row tabs">${
            TABS.map(({name})=>html`<div @click="${()=>goto(name)}" class="${cls(['tab',name === state.tab ? 'active' : ''])}">${name}</div>`)
        } 
        <div class="btns row">
            <div class="btn" @click="${e=>document.querySelector('#control-panel')?.remove()}">X</div></div>
        </div>
        <hr>
        <div>
            ${TABS.find(t=>t.name === state.tab)?.render(state, update)}
        </div>
    </div>`, container)
}

function renderConfigTab() {
    return html`<div>CONFIG</div>`
}

export default function openControlPanel(preset) {
    if(document.querySelector('.modal-content')){
        console.log('modal already open')
        return 
    }
    const container = document.createElement('div')
    container.id = "control-panel"
    container.classList.add('modal')
    render(html`<div class="modal-background"></div>
<div class="modal-content"></div>`, container)
    document.body.prepend(container)
    const contentElement = container.querySelector('.modal-content')
    console.log('opening control panel')
    let initialState = {
        preset,
        variables: [],
        tab: 'config',
        computedConfig: true
    }
    let currentState = {}

    function update(path, value) {
        currentState = set(currentState, path, value)
        renderControlPanel(currentState, contentElement, update)
    }
    update([], initialState)

}