type NotifLevel = 'error' | 'warning' | 'info' | 'success' | 'debug'

export type Notif = {
    timestamp: number,
    id: string,
    level: NotifLevel,
    theme: string[],
    message: string,
    details?: any,
    group?: string,
    action?: (e: any, n: Notif) => Promise<void>,
    duration?: number

}

export function stringifyNotif(n: Notif) {
    const detailString = n.details ? JSON.stringify(n.details, null, 2) : 0
    return `${new Date(n.timestamp).toISOString().padEnd(15)} : ${n.theme.join(".").padEnd(15)} ${n.message} ${detailString ? `\n${detailString}` : ''}`
}