import { render } from "lit";
import Descartes from ".";
import { TimedOverview } from "./cartes";
import * as d3 from 'd3'

function renderTimedOverview(overview: TimedOverview, start: number, end: number, container) {
    const { timespan, loaded } = this.getState()
    if (loaded && timespan && this.carte.getTimeOverview) {
        if (this.overview)
            return true;
        const overview = this.carte.getTimeOverview()
        const ymax = overview.map(o => o.value).reduce((curr, prev) => Math.max(prev, curr), 0);

        const height = 100, width = 500
        const xscale = d3.scaleLinear(timespan, [0, width]);
        const yscale = d3.scaleLinear([-ymax, ymax], [-height / 2, height / 2]);

        const svg = d3.select(container)
        svg
            .attr("viewBox", `0 ${-height / 2} ${width} ${height}`)
        // .attr("width", "100%").attr("height", "100%")
        {
            const join = svg.selectAll("rect.intensity").data(overview)
            join.enter().append('rect').attr('class', 'intensity')
                .attr("x", (d: any) => xscale(d.t))
                .attr("y", (d: any) => -yscale(d.value))
                .attr("value", (d: any) => d.value)
                .attr("width", (d: any) => 1)
                .attr("height", (d: any) => 2 * yscale(d.value))
        }

        {

            const join = svg.selectAll('line.timespan').data([timespan])
            join.enter().append('line')
                .attr('line', 'timespan')
                .attr("x1", d => xscale(d[0]))
                .attr("x2", d => xscale(d[1]))
                .attr("y1", yscale(0))
                .attr("y2", yscale(0))
                .attr('stroke', "green")
                .attr('strokeWidth', "20")
        }
        this.overview = overview
        return true
    }
}

export default function renderTimeline(descartes: Descartes, config: any) {
    let previousTimeline = descartes.timeline
    // if (previousTimeline)
    //     return
    // intensity
    // periods
    const container = descartes.container.querySelector('.timeline .overview') as any
    if (container) {
        const lastH = container.h
        let hParts: string[] = []
        const [start, end] = descartes.player.range
        const limit = 1000 * 1000 * 10
        const duration = end - start
        if (duration > limit)
            throw `time span is too large (${duration} > ${limit})`
        for (let cm of descartes.carteManagers) {
            if (cm.loaded)
                hParts.push(cm.getH())
            // TODO : fix it
            // cm.renderTimedOverview(overviewContainer)
        }
        const h = hParts.join('+')
        if (h !== lastH) {
            console.log('rendering timeline from ', start, 'to', end, 'h=' + h)
            const t0 = Date.now()
            let intensity: any[] = []
            const dt = 5.0
            for (let t = Math.round(start / 1000); t <= Math.round(end / 1000); t += dt)
                for (let cm of descartes.carteManagers) {
                    for (let s of cm.getTimedOverview(t, t + dt).map(s => ({ ...s, carte: cm.name })))
                        intensity.push(s)
                    // TODO : fix it
                    // cm.renderTimedOverview(overviewContainer)
                }

            const ymax = intensity.map(o => o.value).reduce((curr, prev) => Math.max(prev, curr), 0);

            const height = 100, width = 500
            const xscale = d3.scaleLinear([start / 1000, end / 1000], [0, width]);
            const yscale = d3.scaleLinear([-ymax, ymax], [-height / 2, height / 2]);


            const svg = d3.select(container)
            svg
                .attr("viewBox", `0 ${-height / 2} ${width} ${height}`)
            // .attr("width", "100%").attr("height", "100%")
            if (1) {

                const tr = (j) => j.attr("x", (d: any) => xscale(d.t) - 1)
                    .attr("y", (d: any) => -yscale(d.value))
                    .attr("value", (d: any) => d.value)
                    .attr("width", (d: any) => xscale(d.t + dt) - xscale(d.t) + 2)
                    .attr("height", (d: any) => 2 * yscale(d.value))

                const join = svg.selectAll("rect.intensity").data(intensity)
                tr(join.enter().append('rect').attr('class', 'intensity'))
                tr(join)
                // join

            }

            {
                const tr = (j) => j.attr('line', 'timespan')
                    .attr("x1", d => xscale(d[0]))
                    .attr("x2", d => xscale(d[1]))
                    .attr("y1", yscale(0))
                    .attr("y2", yscale(0))
                    .attr('stroke', "green")
                    .attr('strokeWidth', "20")

                const join = svg.selectAll('line.timespan').data([[start, end]])
                tr(join.enter().append('line'))
                tr(join)
                // join

            }
            container.h = h
        }


    }

}