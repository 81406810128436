import * as d3 from 'd3'

export const distance = (x1, y1, x2, y2) => {
  const dx = x1 - x2,
    dy = y1 - y2;
  return Math.sqrt(dx * dx + dy * dy);
};

export const trunc = (str, len) =>
  str.length > len ? str.substr(0, len - 1) + "..." : str;

export const hashCode = s =>
  s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);


export const iterateElements = (selector, fn) =>
  [].forEach.call(document.querySelectorAll(selector), fn);

export function buildFunction(str) {
  try {
    const testFunction = (new Function("", `return (${str})`))();
    testFunction.string = str
    return testFunction
  } catch (err) {
    throw `invalid function : "${str}"`
  }
}
export function mustHaveProps(obj, props) {
  for (let p of props)
    if (obj[p] === undefined) {
      throw `${p} must be present : ${JSON.stringify(obj)}`
    }
}

export function inWorker() {
  return window.document === undefined
}

export function timer(tag = '') {
  const t0 = Date.now()
  let lastTime = t0
  const time = (...args) => {
    const now = Date.now()
    const dt0 = now - t0
    const dt = now - lastTime
    console.log((inWorker() ? 'worker' : '') + ' ' + (tag ? ` (${tag})` : '') + dt0 + ' : ', ...args)
    lastTime = now
    return dt
  }
  return time
}



export function infereBuilder(value, name) {
  let num = Number(value)
  if (Number.isFinite(num))
    return Number
  return (value) => value
}

const SEPARATOR = '.'
export function flattenSample(s, output = {}, prefix = '') {

  for (let key in s) {
    const value = s[key]
    if (typeof value !== 'object') {
      output[prefix + key] = value
    } else {
      flattenSample(value, output, prefix + `${key}${SEPARATOR}`)
    }
  }
  return output
}



export const buildGetter = (prop) => s => s[prop]

export function computeGetter(spec: any = { value: null }, samples = []) {
  let { field, value, normalize } = spec
  if (value !== undefined)
    return () => value
  if (field) {
    if (!normalize)
      return (s) => s[field]
    if (!Array.isArray(normalize))
      normalize = [normalize]
    const [low, high] = normalize
    if (Number.isFinite(low) && !Number.isFinite(high)) {
      throw `normalize not implemented with ${normalize}`
    }
  }
  throw `invalid getter : ${spec}`
}
