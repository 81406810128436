import { DataChunk, getChunkSamples, mergeChunks } from '../chunk'
import samples from './samples'
import * as csv from './csv'
import * as json from './json'
import * as ndjson from './ndjson'
import * as arrow from './arrow'
import * as iq from './iq'
import url from './url'
import urls from './urls'
import api from './api'
import influx1 from './influx1'
import grid from './grid'
export type BaseTableType = {
    getChunkAt: (spec, offset, size) => Promise<DataChunk>
    getSize: (spec) => Promise<number>
    getSchema?: () => any
    name?: string
    getCount?: () => Promise<number>,
    schema?: any
}








const from = function (spec, dataset) {
    let { from } = spec
    if (!Array.isArray(from))
        from = [from]
    const tables = from.map(name => {
        const table = dataset[name]
        if (!table)
            throw `table ${name} not found`
        return table
    })

    async function computeInfos() {
        const infos = []
        let sizes = await Promise.all(tables.map(t => t.getSize()))
        let offset = 0
        for (let i = 0; i < sizes.length; i++)
            infos.push({ start: offset, end: offset + Number(sizes[i]), table: tables[i] })
        return infos
    }
    let infosComputation = null
    async function getInfos() {
        if (!infosComputation)
            infosComputation = computeInfos()
        return await infosComputation
    }



    return {
        async getChunkAt(offset, size) {
            const infos = await getInfos()
            const chunks = (await Promise.all(infos.map(({ start, end, table }) => {
                let localStart = Math.min(Math.max(offset - start, 0), end - start)
                let localEnd = Math.min(Math.max(offset + size - start, 0), end - start)
                if (localStart !== localEnd)
                    return table.getChunkAt(localStart, localEnd - localStart)
            }))).filter(Boolean)
            return { offset, ...mergeChunks(chunks) }
        },
        async getSize() {
            const infos = await getInfos()
            return infos[infos.length - 1].end
        }
    }
}






export function loadPluginBaseTable(plugin) {
    for (let bt of plugin.baseTables || []) {
        const { name } = bt
        console.log('adding base table', name)
        BASE_TABLES[name] = bt
    }
}

const BASE_TABLES = {

}

export default {
    samples,
    api,
    url,
    urls,
    from,
    influx1,
    grid
} as { [key: string]: (spec, dataset) => BaseTableType }